import axiosSingleton from '../components/axios';
import config from '../config';
import { InitInfoResult, InitInfo } from '../models/InitInfoResult.model';

export const initInfoProvider = {
  async getInitInfo(): Promise<InitInfo> {
    const response = await axiosSingleton.get<InitInfoResult>(`${config.API_URL}/v1/init`, {
      headers: {
        accept: 'application/json',
      },
    });

    return response.data.data;
  },
};
