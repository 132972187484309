import { IPublicClientApplication } from '@azure/msal-browser';
import { useEffect } from 'react';
import { InactivityCheckAsync } from '../utils/sessionTimeout';

export interface UseIdleTimerProps {
  timeoutIntervalCheckInSeconds: number;
  timeoutInSeconds: number;
  instance?: IPublicClientApplication;
}

export const useIdleTimer = ({ instance, timeoutInSeconds, timeoutIntervalCheckInSeconds }: UseIdleTimerProps) => {
  useEffect(() => {
    const timeoutIntervalCheckInMilliseconds = timeoutIntervalCheckInSeconds * 1000;
    const interval = setInterval(async () => {
      await InactivityCheckAsync(timeoutInSeconds, instance);
    }, timeoutIntervalCheckInMilliseconds);

    return () => clearInterval(interval);
  }, [instance, timeoutInSeconds, timeoutIntervalCheckInSeconds]);
};
