import { intervalToDuration } from 'date-fns';

interface Category {
  category: string;
  start: number;
  end: number;
}
const AgeCategories: Category[] = [
  { category: '0-11', start: 0, end: 11 },
  { category: '12-18', start: 12, end: 18 },
  { category: '19-29', start: 19, end: 29 },
  { category: '30-39', start: 30, end: 39 },
  { category: '40-49', start: 40, end: 49 },
  { category: '50-59', start: 50, end: 59 },
  { category: '60-64', start: 60, end: 64 },
  { category: '65-69', start: 65, end: 69 },
  { category: '70-79', start: 70, end: 79 },
  { category: '80-89', start: 80, end: 89 },
  { category: '90+', start: 90, end: Infinity },
];

export const determineAge = (dateOfBirth: string | Date) => {
  const birthDate = new Date(dateOfBirth);
  const { years, months, days } = intervalToDuration({ start: birthDate, end: new Date() });
  return { years, months, days };
};

export const determineAgeCategory = (age: string | number) => {
  const years = Number(age);
  let output: string = '';
  for (const cat of AgeCategories) {
    if (years >= cat.start && years <= cat.end) {
      output = cat.category;
    }
  }
  return output;
};
