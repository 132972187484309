const ProfileAndSettingsText = {
  pageTitle: 'Profile and Settings',
  savedSuccessfulMessage: 'Success! Your changes were saved.',
  sectionTitles: {
    anchorTabsheader: 'Contents',
    contactInfo: 'Contact Information',
    accountSettings: 'Account Settings',
    commPref: 'Communication Preferences',
    profileSection: 'Profile',
  },
  contactInformation: {
    title: 'Contact Information',
    errorMessage: 'Unable to update your Contact Information.',
    subtitle: 'this is your contact information for your MVP member account.',
    cancelText: 'Cancel',
    updateText: 'Update',
    saveText: 'Save Changes',
    submittingText: 'Submitting...',
    updateAddress: {
      homeAddressText: 'Home Address',
      mailingAddressText: 'Mailing Address',
      sameAsHomeText: 'Update mailing address to match',
      form: {
        line1: 'Street address*',
        line2: 'Apt, suite, etc.',
        city: 'City*',
        state: 'State*',
        zip: 'Zip*',
      },
    },
    updatePhone: {
      title: 'Phone Number',
      primaryPhoneTitle: 'Primary Phone Number',
      mailingPhoneTitle: 'Mailing Address Phone Number (optional)',
      form: {
        phone: 'New Phone Number',
        confirmPhone: 'Confirm New Phone Number',
      },
    },
    updateEmail: {
      title: 'Communication Email',
      form: {
        email: 'New Email',
        confirmEmail: 'Confirm New Email',
      },
    },
  },
  paperlessPreferences: {
    errorMessage: 'Unable to update your Communication Preference choice.',
  },
};

export default ProfileAndSettingsText;
