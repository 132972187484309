import { SnackbarCloseReason, makeStyles } from '@material-ui/core';
import { DsSnackbar, DsSnackbarProps } from '@mvp/design-system';
import React, { FC, createContext, useContext, useState } from 'react';
import { defaultTheme } from '@mvp/design-system';
import { useHeap } from '../hooks/useHeap';

const errorProps = {
  snackbarBackgroundColor: '#F2C44D',
  messageColor: defaultTheme.palette.black.main,
  faIconName: 'fal fa-exclamation-circle',
};

const useStyles = makeStyles((theme) => ({
  errorIcon: {
    marginRight: '1.5rem',
    fontSize: '2rem',
    color: theme.palette.black.main,
  },
  errorIconColor: {
    color: theme.palette.black.main,
  },
}));

interface SnackbarProps extends Omit<DsSnackbarProps, 'open'> {
  isError?: boolean;
  heapId?: string;
}

interface SnackbarContextProps {
  openSnackbar: (props: SnackbarProps) => void;
  closeSnackbar: VoidFunction;
}

const SnackbarContext = createContext<SnackbarContextProps>({
  openSnackbar: (props: SnackbarProps) => {},
  closeSnackbar: () => {},
});

export const useSnackbarContext = () => useContext(SnackbarContext);

export const SnackbarContextProvider: FC = ({ children }) => {
  const classes = useStyles();
  const { sendCustomEvent } = useHeap();

  const [snackBarProps, setSnackBarProps] = useState<DsSnackbarProps>({
    open: false,
  });

  const onClose = (event: React.SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
    closeSnackbar();
    snackBarProps?.onClose?.(event, reason);
  };

  const closeSnackbar = () => {
    setSnackBarProps((oldProps) => ({ ...oldProps, open: false }));
    setTimeout(() => {
      setSnackBarProps((oldProps) => {
        if (oldProps.open) {
          return oldProps;
        }

        return { open: false };
      });
    }, 300);
  };

  const openSnackbar = ({ isError, heapId, ...snackbarProps }: SnackbarProps) => {
    const props: DsSnackbarProps = { open: true, ...(isError && errorProps), ...snackbarProps };

    if (isError) {
      props.iconStyleOverride = `${classes.errorIcon} ${classes.errorIconColor} ${snackbarProps.iconStyleOverride}`;
      props.closeIconStyleOverride = `${classes.errorIconColor} ${snackbarProps.closeIconStyleOverride}`;
    }
    if (heapId) {
      sendCustomEvent(heapId);
    }

    setSnackBarProps(props);
  };

  return (
    <>
      <DsSnackbar {...snackBarProps} onClose={onClose} />
      <SnackbarContext.Provider value={{ openSnackbar, closeSnackbar }}>{children}</SnackbarContext.Provider>
    </>
  );
};
