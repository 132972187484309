import React, { FC, createContext, useContext, useState } from 'react';
interface MemberFilterContextProps {
  isMemberFilterActive: boolean;
  setIsMemberFilterActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const MemberFilterContext = createContext<MemberFilterContextProps>({
  isMemberFilterActive: false,
  setIsMemberFilterActive: () => {},
});

export const useMemberFilterContext = () => useContext(MemberFilterContext);

export const MemberFilterContextProvider: FC = ({ children }) => {
  const [isMemberFilterActive, setIsMemberFilterActive] = useState(false);

  return <MemberFilterContext.Provider value={{ setIsMemberFilterActive, isMemberFilterActive }}>{children}</MemberFilterContext.Provider>;
};
