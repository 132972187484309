import { flatten } from 'lodash';
import { useEffect, useState } from 'react';
import { GuardFunction } from 'react-router-guards';
import { routes } from '../routesConfig';
import { useNavigationItems } from './useNavigationItems';

export const useAuthGuard = (): { requireAuth: GuardFunction; isLoading: boolean; isErrorState: boolean } => {
  const [internalLinks, setInternalLinks] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isErrorState, setIsErrorState] = useState(false);
  const { categorizedLinks, errorState } = useNavigationItems();

  useEffect(() => {
    if (categorizedLinks.length) {
      const links = flatten(categorizedLinks.map((e) => e.links));
      const newInternalLinks = links.filter((e) => !e.isExternal).map((e) => e.url);

      setInternalLinks(newInternalLinks);
      setIsLoading(false);
    }
    setIsErrorState(errorState);
  }, [categorizedLinks, errorState]);

  const requireAuth: GuardFunction = (to, _, next) => {
    const toPath = to.location.pathname;
    const toPathIsAuthorized = internalLinks.some((e) => {
      const rootOfToPath = toPath
        .split('/')
        .filter((entry) => !!entry)
        .shift();

      if (rootOfToPath) {
        return e.includes(rootOfToPath);
      }

      return false;
    });

    if (toPathIsAuthorized) {
      next();
    } else {
      next.redirect(routes.unauthorized);
    }
  };

  return { requireAuth, isLoading, isErrorState };
};
