export const isZipRegex = /^[0-9]{5}(?: - [0-9]{4})?$/;

export const standardUSPhoneRegex = /^[1-9]\d{2} - \d{3} - \d{4}$/g;
export const standardUSPhoneRegexWithoutSpaces = /((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/g;

export const isZipcodeEmpty = (zipcode: string) => {
  const emptyZipcdoes = ['_____', '_____ - ____'];
  return emptyZipcdoes.includes(zipcode);
};

export const isPhoneNumberEmpty = (phoneNumber: string) => {
  return phoneNumber === '___ - ___ - ____';
};

export const formatMoney = (amount: string | number): string => {
  const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

  return formatter.format(Number(amount));
};

export const formatZipCode = (zipCode: string | undefined): string | undefined => {
  let formattedZipCode: string | undefined = zipCode;

  if (zipCode?.length === 9) {
    const str1 = zipCode.slice(0, 5);
    const str2 = zipCode.slice(5);
    formattedZipCode = `${str1}-${str2}`;
  }

  return formattedZipCode;
};

export const valueToDollars = (value: number): string => {
  return formatMoney(value) || '$0.00';
};

export const formatMemberName = (memberName: string): string => {
  const NameSeparators = ['-', ' ', "'"];
  return transformToUpperCase(memberName, NameSeparators);
};

export const toTitleCase = (data: string): string => {
  return transformToUpperCase(data, [' ']);
};

const transformToUpperCase = (name: string, separators: string[]): string => {
  const regex = new RegExp('(^|[' + separators.join('') + '])(\\w)', 'g');
  return name.toLowerCase().replace(regex, (x) => x.toUpperCase());
};

export const formatPhone = (phoneNumber?: string, withSpaces: boolean = false) => {
  if (!phoneNumber || isCorrectPhoneFormat(phoneNumber, withSpaces)) {
    return phoneNumber;
  }
  const formattedPhoneNumber = withSpaces
    ? `${phoneNumber.slice(0, 3)} - ${phoneNumber.slice(3, 6)} - ${phoneNumber.slice(6, 10)}`
    : `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  return formattedPhoneNumber;
};

export const isCorrectPhoneFormat = (phoneNumber: string, withSpaces: boolean) => {
  return !!phoneNumber.match(withSpaces ? standardUSPhoneRegex : standardUSPhoneRegexWithoutSpaces);
};

export const splitFilename = (filename: string, part1MaxLength: number) => {
  let part1 = '';
  let part2 = '';
  let part3 = '';

  if (!!filename && filename.includes('.')) {
    const extensionIndex = filename.lastIndexOf('.');

    // If the filename starts with a "." and has no other ".", just return the full filename in part1
    if (extensionIndex === 0) {
      part1 = filename;
    } else {
      // Try to include the previous 3 characters in front of the file extension in the 3rd part, everything else in 1st part
      for (let i = 3; i > 0 && part1 === ''; i--) {
        if (extensionIndex >= i) {
          part1 = filename.substring(0, extensionIndex - i);
          part3 = filename.substring(extensionIndex - i);
        }
      }
    }
  } else {
    part1 = filename;
  }

  if (!!part1 && part1.length > part1MaxLength) {
    part2 = part1.substring(part1MaxLength);
    part1 = part1.substring(0, part1MaxLength);
  }

  return { part1: part1, part2: part2, part3: part3 };
};

export const convertUrlsToLinks = (text: string, openInNewTab: boolean = true) => {
  const urlRegex = /(?:http[s]?:\/\/.)?(?:www\.)?[-a-zA-Z0-9@%._\+~#=]{2,256}\.[a-z]{2,6}\b(?:[-a-zA-Z0-9@:%_\+.~#?&\/\/=]*)/g;
  return text.replace(
    urlRegex,
    (url: string) =>
      `<a style="overflow-wrap: break-word;" href="${url.match(/(?:http[s]?:\/\/)/g) ? '' : '//'}${url}" target="${
        openInNewTab ? '_blank' : '_parent'
      }" rel="noopener noreferrer">${url}</a>`
  );
};
