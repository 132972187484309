import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionSummaryProps,
  Grid,
  Icon,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import { ModifyAddressForm } from './ModifyAddressForm';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CommunicationPreferencesAddress } from '../../../../models/communicationPreferences.model';
import { DsCheckbox, size } from '@mvp/design-system';
import ProfileAndSettingsText from '../../ProfileAndSettingsText';
import { useHeap } from '../../../../hooks/useHeap';
import { ModifyPhone } from '../phone/ModifyPhone';
import { ErrorMessage } from '../../../../models/errorMessage.model';
import { FormPropsGrouped } from './ModifyAddressAndPhone';

interface Ids {
  accordion: string;
  checkbox?: string;
}

interface UserAddressAccordionProps extends AccordionSummaryProps {
  expandMoreIconId?: string;
}

interface UserInfo {
  address: CommunicationPreferencesAddress;
  phone: string;
}

interface UserAddressAccordionProps {
  title: string;
  userInfo: UserInfo;
  address: CommunicationPreferencesAddress;
  formProps: FormPropsGrouped;
  phoneErrorMessage: ErrorMessage | null;
  isDisabled?: boolean;
  showCheckBox?: boolean;
  checkBoxValue?: boolean;
  onCheckBoxChange?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, value: boolean) => void;
  manuallyExpand?: boolean;
  setManuallyExpand?: React.Dispatch<React.SetStateAction<boolean>>;
  onStateSelectChange: () => void;
  ids?: Ids;
}

interface StyleProps {
  showCheckBox: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  icon: {
    marginRight: size.xxxs(theme),
    color: theme.palette.primary.main,
  },
  accordionRoot: {
    '&:before': {
      backgroundColor: 'initial',
    },
  },
  accordion: {
    width: '100%',
    border: `0.0625rem solid ${theme.palette.grey[100]}`,
    boxShadow: 'none',
    [theme.breakpoints.down('xs')]: {
      borderLeft: '0',
      borderRight: '0',
    },
  },
  accordionRounded: {
    [theme.breakpoints.down('xs')]: {
      borderBottomLeftRadius: '0',
      borderRadius: '0',
    },
  },
  accordionSummaryRoot: {
    padding: '0',
    [theme.breakpoints.down('xs')]: {
      alignItems: (props) => (props.showCheckBox ? 'flex-start' : 'center'),
    },
  },
  accordionTitle: {
    marginLeft: size.s(theme),
    marginTop: size.s(theme),
    marginBottom: size.s(theme),
    fontWeight: theme.typography.fontWeightMedium,
  },
  accordionSummaryExpanded: {
    margin: '0 !important',
  },
  accordionSummaryContent: {
    margin: '0',
  },
  addressAreSameCheckbox: {
    marginLeft: '2.6875rem',
    [theme.breakpoints.up('sm')]: {
      marginBottom: size.xxs(theme),
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '1.9375rem',
      marginBottom: '1.9063rem',
    },
  },
  addressSummary: {
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  addressHeader: {
    marginLeft: size.s(theme),
    marginTop: size.s(theme),
    marginBottom: size.s(theme),
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down('xs')]: {
      marginBottom: (props) => (props.showCheckBox ? size.xxs(theme) : size.m(theme)),
      marginTop: size.m(theme),
    },
  },
  modifyAddressContainer: {
    margin: `0 ${size.xxs(theme)} ${size.xs(theme)}`,
    width: '100%',
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    width: '100%',
    padding: `0.375rem 0 1.125rem 0 `,
    [theme.breakpoints.down('sm')]: {
      paddingTop: size.m(theme),
    },
  },
  iconColor: {
    color: theme.palette.primary.main,
  },
  iconFontSize: {
    textAlign: 'center',
    fontSize: '2.25rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },
  iconContainer: {
    padding: `${size.s(theme)} ${size.m(theme)} 1.5rem 0 `,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: size.m(theme),
    },
  },
  phoneInfoContainer: {
    display: 'flex',
  },
}));

export const UserAddressAccordion: React.FC<UserAddressAccordionProps> = (props) => {
  const {
    title,
    userInfo,
    formProps,
    isDisabled,
    showCheckBox = false,
    checkBoxValue = true,
    onCheckBoxChange,
    manuallyExpand,
    setManuallyExpand,
    onStateSelectChange,
    phoneErrorMessage,
    ids,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles({ showCheckBox });
  const { toggleIsChecked } = useHeap();

  const onCheckboxClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, checkBoxValue: boolean) => {
    toggleIsChecked(checkBoxValue);
    onCheckBoxChange && onCheckBoxChange(e, checkBoxValue);
  };

  const phoneSectionTitle = !showCheckBox
    ? ProfileAndSettingsText.contactInformation.updatePhone.primaryPhoneTitle
    : ProfileAndSettingsText.contactInformation.updatePhone.mailingPhoneTitle;

  return (
    <Accordion
      expanded={manuallyExpand}
      className={classes.accordion}
      square={isMobile}
      classes={{
        root: classes.accordionRoot,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon id={props.expandMoreIconId} />}
        classes={{
          expandIcon: classes.icon,
          root: classes.accordionSummaryRoot,
          expanded: classes.accordionSummaryExpanded,
          content: classes.accordionSummaryContent,
        }}
        id={ids?.accordion}
        onClick={() => setManuallyExpand && setManuallyExpand(!manuallyExpand)}
      >
        <Grid container className={classes.addressSummary}>
          <Typography className={classes.addressHeader}>{title}</Typography>
          {showCheckBox && (
            <Grid className={classes.addressAreSameCheckbox}>
              <DsCheckbox
                id={ids?.checkbox}
                label={ProfileAndSettingsText.contactInformation.updateAddress.sameAsHomeText}
                initialchecked={checkBoxValue}
                onClick={(e) => onCheckboxClick(e, !checkBoxValue)}
              ></DsCheckbox>
            </Grid>
          )}
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid className={classes.modifyAddressContainer}>
            <ModifyAddressForm
              address={userInfo.address}
              control={formProps.address.control}
              disabled={isDisabled}
              isHomeAddress={!showCheckBox}
              onStateSelectChange={onStateSelectChange}
            />
          </Grid>
          <Grid className={classes.modifyAddressContainer}>
            <Grid className={classes.phoneInfoContainer}>
              <Grid item className={classes.iconContainer}>
                <Icon className={`fal fa-mobile ${classes.iconColor} ${classes.iconFontSize}`} />
              </Grid>
              <Grid item className={classes.infoContainer}>
                <Grid item>
                  <Typography variant="button">{phoneSectionTitle}</Typography>
                  <Typography variant="body2">{userInfo.phone}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <ModifyPhone formProps={formProps.phone} errorMessage={phoneErrorMessage} isHomeInfo={!showCheckBox} isDisabled={isDisabled} />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
