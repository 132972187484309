import { useMsal } from '@azure/msal-react';
import React, { useEffect } from 'react';
import { sharedUrls } from '../sharedUrls';
import { selfServiceSessionProvider } from '../api/selfServiceSessionProvider';
import { routes } from '../routesConfig';

const GhostSignout: React.FC = () => {
  const { instance } = useMsal();
  useEffect(() => {
    // React advises to declare the async function directly inside useEffect
    const GhostSignoutFunc = async () => {
      await selfServiceSessionProvider.clearSession();
      instance.logoutRedirect({ postLogoutRedirectUri: sharedUrls.portalUrl + routes.ghost });
    };

    GhostSignoutFunc();
  });

  return <></>;
};

export default GhostSignout;
