import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface ScrollProps {
  children: JSX.Element;
}
export const ScrollToTop = ({ children }: ScrollProps) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  return children;
};
