import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { EmailForm, FormProps } from '../../models/updateUserInfo.model';
import { modifyEmailSchema } from '../../pages/CommunicationPreferences/components/email/ModifyEmailSchema';
import { useForm, useFormState, useWatch } from 'react-hook-form';
import { communicationPreferencesProvider } from '../../api/communicationPreferencesProvider';
import { invalidateQuery, QueryKey } from '../../ReactQueryClientProvider';
import { ErrorMessage } from '../../models/errorMessage.model';

enum errorMessages {
  EMAILS_DONT_MATCH = 'The provided emails do not match. Please update and try again.',
  SAME_AS_OLD = 'That email address matches the one we already have on file.',
  PROVIDE_VALID_EMAIL = 'Please provide a valid email.',
  API_ERROR = 'Unable to update your Email. Please try again or contact',
}

export const useUpdateEmail = (email: string) => {
  const [errorMessage, setErrorMessage] = useState<ErrorMessage | null>(null);
  const [disableButton, setDisableButton] = useState<boolean>(true);

  const { control, setValue, watch, reset, getValues } = useForm<EmailForm>({
    resolver: yupResolver(modifyEmailSchema),
    mode: 'onChange',
    defaultValues: {
      email: '',
      confirmEmail: '',
    },
  });

  const { isValid, errors } = useFormState({ control });
  const formProps: FormProps<EmailForm> = { control, watch, setValue };
  const watcher = useWatch({ control });

  useEffect(() => {
    const timer = setTimeout(() => {
      setErrorMessage(null);
      if (isValid && watcher.email && watcher.confirmEmail) {
        const emailsAreEqual = watcher.email === watcher.confirmEmail;
        const newEmailIsSameAsOld = watcher.email === email;

        if (newEmailIsSameAsOld) {
          setErrorMessage({
            text: errorMessages.SAME_AS_OLD,
          });
        }
        if (!emailsAreEqual) {
          setErrorMessage({
            text: errorMessages.EMAILS_DONT_MATCH,
          });
        }

        setDisableButton(!emailsAreEqual || newEmailIsSameAsOld);
      } else {
        setDisableButton(true);
        if (!watcher.confirmEmail && !watcher.email) {
          reset();
        } else if (Object.keys(errors).length) {
          setErrorMessage({
            text: errorMessages.PROVIDE_VALID_EMAIL,
          });
        }
      }
    }, 300);
    return () => {
      clearTimeout(timer);
    };
  }, [watcher.email, watcher.confirmEmail, isValid]);

  const onSave = async () => {
    setErrorMessage(null);

    const newEmail = getValues('email');
    const response = await communicationPreferencesProvider.updateEmail(newEmail).catch(() => {
      return false;
    });

    if (response) {
      invalidateQuery(QueryKey.NewCommunicationPreference);
    } else {
      setErrorMessage({
        text: errorMessages.API_ERROR,
        linkText: 'eSupport',
        endText: 'at 1-888-656-5695.',
      });
    }

    return !!response;
  };

  return { reset, disableButton, errorMessage, setErrorMessage, onSave, formProps };
};
