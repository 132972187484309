import { CommunicationPreferencesAddress } from '../../../models/communicationPreferences.model';

const baseAddressState = {
  line1: '',
  line2: '',
  city: '',
  state: '',
  zipCode: '',
};

export const convertToSchema = (address: CommunicationPreferencesAddress) => {
  if (!address) {
    return baseAddressState;
  }
  const { line2, county, ...resetAddress } = address;
  return { ...resetAddress, line2: line2 || '' };
};
