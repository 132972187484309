import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { FormProps, PhoneForm } from '../../models/updateUserInfo.model';
import { FieldError, useForm, useFormState, useWatch } from 'react-hook-form';
import { modifyPhoneSchema } from '../../pages/CommunicationPreferences/components/phone/ModifyPhoneSchema';
import { ErrorMessage } from '../../models/errorMessage.model';
import { isPhoneNumberEmpty } from '../../utils/string';

enum phoneErrorMessages {
  PHONE_NUMBERS_DONT_MATCH = 'The provided phone numbers do not match. Please update and try again.',
  SAME_AS_OLD = 'That phone number matches the one we already have on file.',
  PROVIDE_VALID_PHONE_NUMBER = 'Please provide a valid phone number.',
  API_ERROR = 'Unable to update your phone number. Please try again or contact',
}

interface GlobalError {
  phone?: FieldError | undefined;
  confirmPhone?: FieldError | undefined;
}

interface ErrorState {
  globalError: GlobalError | undefined;
  errorState: FieldError | undefined;
}

export const useUpdatePhone = (originalHomePhoneNumber: string, originalMailingPhoneNumber: string) => {
  const [errorMessage, setErrorMessage] = useState<ErrorMessage | null>(null);
  const [disableButton, setDisableButton] = useState(true);

  const [homePhoneErrorMessage, setHomePhoneErrorMessage] = useState<ErrorMessage | null>(null);
  const [mailingPhoneErrorMessage, setMailingPhoneErrorMessage] = useState<ErrorMessage | null>(null);

  const { control, setValue, watch, reset, getValues, getFieldState } = useForm<PhoneForm>({
    resolver: yupResolver(modifyPhoneSchema),
    mode: 'onChange',
    defaultValues: {
      homePhoneNumber: {
        phone: '',
        confirmPhone: '',
      },
      mailingPhoneNumber: {
        phone: '',
        confirmPhone: '',
      },
    },
  });

  const { errors } = useFormState({ control });
  const watcher = useWatch({ control });
  const formProps: FormProps<PhoneForm> = { control, watch, setValue };

  const mailingPhoneNumber = getFieldState('mailingPhoneNumber');
  const homePhoneNumber = getFieldState('homePhoneNumber');

  const comparePhoneNumbers = (inputPhoneNumber: string | undefined, confirmPhoneNumber: string | undefined, basePhoneNumber: string) => {
    if ((inputPhoneNumber && !confirmPhoneNumber) || (!inputPhoneNumber && confirmPhoneNumber)) {
      setDisableButton(true);
      return null;
    }

    if (inputPhoneNumber && confirmPhoneNumber) {
      const emailsAreEqual = inputPhoneNumber === confirmPhoneNumber;
      const formattedPhoneNumber = inputPhoneNumber.replace(/ +/g, '') || inputPhoneNumber;
      const newEmailIsSameAsOld = formattedPhoneNumber === basePhoneNumber;

      if (!emailsAreEqual) {
        setDisableButton(true);
        return {
          text: phoneErrorMessages.PHONE_NUMBERS_DONT_MATCH,
        };
      }

      if (newEmailIsSameAsOld) {
        setDisableButton(true);
        return {
          text: phoneErrorMessages.SAME_AS_OLD,
        };
      }

      setDisableButton(!emailsAreEqual || newEmailIsSameAsOld);
      return null;
    } else {
      setDisableButton(true);
      return {
        text: phoneErrorMessages.PROVIDE_VALID_PHONE_NUMBER,
      };
    }
  };

  const checkIfPhoneIsEmpty = (inputPhoneNumber: string | undefined, confirmPhoneNumber: string | undefined) => {
    const phoneIsEmpty = isPhoneNumberEmpty(inputPhoneNumber || '') || !inputPhoneNumber;
    const confirmPhoneIsEmpty = isPhoneNumberEmpty(confirmPhoneNumber || '') || !confirmPhoneNumber;

    return { phoneIsEmpty, confirmPhoneIsEmpty };
  };

  const checkIfInputsAreEmpty = () => {
    const homePhonesAreEmptyStatus = checkIfPhoneIsEmpty(watcher.homePhoneNumber?.phone, watcher.homePhoneNumber?.confirmPhone);
    const mailingPhoneAreEmptyStatus = checkIfPhoneIsEmpty(watcher.mailingPhoneNumber?.phone, watcher.mailingPhoneNumber?.confirmPhone);

    const inputsAreEmpty =
      homePhonesAreEmptyStatus.phoneIsEmpty &&
      homePhonesAreEmptyStatus.confirmPhoneIsEmpty &&
      mailingPhoneAreEmptyStatus.phoneIsEmpty &&
      mailingPhoneAreEmptyStatus.confirmPhoneIsEmpty;

    return inputsAreEmpty;
  };

  const validatePhoneNumbers = (
    inputPhoneNumber: string | undefined,
    confirmPhoneNumber: string | undefined,
    basePhoneNumber: string,
    errors: ErrorState
  ) => {
    const phoneInputsAreEmpty = checkIfPhoneIsEmpty(inputPhoneNumber, confirmPhoneNumber);

    if (phoneInputsAreEmpty.phoneIsEmpty && phoneInputsAreEmpty.confirmPhoneIsEmpty) {
      setDisableButton(false);
      return null;
    } else {
      if (errors.errorState || errors.globalError) {
        setDisableButton(true);
        return {
          text: phoneErrorMessages.PROVIDE_VALID_PHONE_NUMBER,
        };
      } else {
        return comparePhoneNumbers(inputPhoneNumber, confirmPhoneNumber, basePhoneNumber);
      }
    }
  };

  useEffect(() => {
    const errorMessage = validatePhoneNumbers(
      watcher.homePhoneNumber?.phone || '',
      watcher.homePhoneNumber?.confirmPhone || '',
      originalHomePhoneNumber,
      {
        globalError: errors.homePhoneNumber,
        errorState: homePhoneNumber.error,
      }
    );
    setHomePhoneErrorMessage(errorMessage);
  }, [homePhoneNumber.error, watcher.homePhoneNumber?.phone, watcher.homePhoneNumber?.confirmPhone, errors]);

  useEffect(() => {
    const errorMessage = validatePhoneNumbers(
      watcher.mailingPhoneNumber?.phone || '',
      watcher.mailingPhoneNumber?.confirmPhone || '',
      originalMailingPhoneNumber,
      {
        globalError: errors.mailingPhoneNumber,
        errorState: mailingPhoneNumber.error,
      }
    );
    setMailingPhoneErrorMessage(errorMessage);
  }, [mailingPhoneNumber.error, watcher.mailingPhoneNumber?.phone, watcher.mailingPhoneNumber?.confirmPhone, errors]);

  const getUpdateValues = () => {
    return getValues();
  };

  const getPhoneNumbers = () => {
    const phoneNumber = getValues();
    const homePhoneNumber = phoneNumber.homePhoneNumber.phone
      ? phoneNumber.homePhoneNumber.phone.replace(/ +/g, '')
      : originalHomePhoneNumber;
    const mailingPhoneNumber = phoneNumber.mailingPhoneNumber.phone
      ? phoneNumber.mailingPhoneNumber.phone.replace(/ +/g, '')
      : originalMailingPhoneNumber;

    return {
      homePhoneNumber,
      mailingPhoneNumber,
    };
  };

  return {
    reset,
    disableButton,
    errorMessage,
    setErrorMessage,
    getPhoneNumbers,
    formProps,
    homePhoneErrorMessage,
    mailingPhoneErrorMessage,
    getUpdateValues,
    checkIfPhoneIsEmpty,
    checkIfInputsAreEmpty,
  };
};
