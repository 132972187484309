/**
 * @deprecated The method should not be used, use @EligibilityStatus instead
 */
export enum MemberEnrollmentStatus {
  Active = 'Active',
  Termed = 'Termed',
  Future = 'Future',
  PendingActivation = 'PendingActivation',
  Pending_Activation = 'Pending Activation',
  PendingTermination = 'PendingTermination',
  Pending_Termination = 'Pending Termination',
  PendingRenewal = 'PendingRenewal',
  Pending_Renewal = 'Pending Renewal',
  Inactive = 'Inactive',
}

export enum EligibilityStatus {
  Active = 'Active',
  PendingActivation = 'PendingActivation',
  Inactive = 'Inactive',
}
