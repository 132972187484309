import axiosSingleton from '../components/axios';
import config from '../config';
import { MessagesByThread } from '../models/secureMessaging/messagesByThread.model';
import { NewThreadResponse } from '../models/secureMessaging/newThreadResponse.model';
import {
  GetThreadResult,
  NewThreadData,
  SecureMessagingTableData,
  SendMessageData,
  ThreadCount,
  ThreadType,
  UpdateThreads,
} from '../models/secureMessaging/secureMessaging.model';
import { GetMessagesByThreadResult, GetThreadCountResult } from '../models/secureMessaging/secureMessagingProvider.model';
import { FilterOptions } from '../pages/SecureMessaging/hooks/useTableFilter';

export const secureMessagingProvider = {
  async getThreads(threadType: ThreadType, orderByQuery: string): Promise<SecureMessagingTableData> {
    let endpoint = `${config.API_URL}/v1/messaging/threads/${threadType}`;

    if (orderByQuery) {
      endpoint += `?orderBy=${orderByQuery}`;
    }

    const response = await axiosSingleton.get<GetThreadResult>(endpoint);
    return response.data.data.threads;
  },

  async getThreadsV2(threadType: ThreadType, orderByQuery: string, filterValues: FilterOptions): Promise<SecureMessagingTableData> {
    let endpoint = `${config.API_URL}/v2/messaging/threads/get/${threadType}`;

    if (orderByQuery) {
      endpoint += `?orderBy=${orderByQuery}`;
    }

    const response = await axiosSingleton.post<FilterOptions, { data: GetThreadResult }>(endpoint, filterValues);
    return response.data.data.threads;
  },

  async getInbox(): Promise<SecureMessagingTableData> {
    const response = await axiosSingleton.get<GetThreadResult>(`${config.API_URL}/v1/messaging/threads/inbox`);
    return response.data.data.threads;
  },

  async getArchive(): Promise<SecureMessagingTableData> {
    const response = await axiosSingleton.get<GetThreadResult>(`${config.API_URL}/v1/messaging/threads/archive`);
    return response.data.data.threads;
  },

  async getDrafts(): Promise<SecureMessagingTableData> {
    const response = await axiosSingleton.get<GetThreadResult>(`${config.API_URL}/v1/messaging/threads/drafts`);
    return response.data.data.threads;
  },

  async getMessageThread(threadId: string): Promise<MessagesByThread> {
    const response = await axiosSingleton.get<GetMessagesByThreadResult>(`${config.API_URL}/v1/messaging/thread/${threadId}`);
    return response.data.data.messageThread;
  },

  async updateThreads({ updateValue, threadIds, type }: UpdateThreads): Promise<void> {
    return await axiosSingleton.patch(`${config.API_URL}/v1/messaging/threads/${type}`, {
      updateValue,
      threadIds,
    });
  },

  async sendMessage(messageInfo: SendMessageData): Promise<void> {
    const formData = new FormData();
    formData.append('regardingMpi', messageInfo.regardingMpi);
    formData.append('threadId', messageInfo.threadId);
    formData.append('body', messageInfo.body);
    messageInfo.attachments.forEach((attachment) => {
      formData.append(attachment.name, attachment.file!);
    });
    return await axiosSingleton.post(`${config.API_URL}/v2/messaging/send`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  async createNewThread(messageInfo: NewThreadData): Promise<string> {
    const formData = new FormData();
    formData.append('regardingMemberId', messageInfo.regardingMemberId);
    formData.append('topic', messageInfo.topic);
    formData.append('body', messageInfo.body);
    messageInfo.attachments.forEach((attachment) => {
      formData.append(attachment.name, attachment.file!);
    });

    const response = await axiosSingleton.post<FormData, NewThreadResponse>(`${config.API_URL}/v2/messaging/thread`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data.data.createdThreadId;
  },

  async saveDraft(messageInfo: NewThreadData): Promise<void> {
    return await axiosSingleton.post(`${config.API_URL}/v1/messaging/save-draft`, messageInfo);
  },

  async getThreadCount(): Promise<ThreadCount> {
    const response = await axiosSingleton.get<GetThreadCountResult>(`${config.API_URL}/v1/messaging/threads/count`);
    return response.data.data;
  },
};
