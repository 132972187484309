import { AccountInfo, Configuration, RedirectRequest, BrowserCacheLocation } from '@azure/msal-browser';
import config from './config';
import { sharedUrls } from './sharedUrls';

const authoritybase = `${sharedUrls.authorityBaseUrl}/${config.B2C_TENANT}.onmicrosoft.com`;
const authority = `${authoritybase}/${config.B2C_POLICY}`;
const ghostUserAuthority = `${authoritybase}/${config.B2C_GHOST_USER_POLICY}`;

export const msalConfig: Configuration = {
  auth: {
    clientId: config.API_APP_ID,
    authority: authority,
    knownAuthorities: [`${config.B2C_TENANT}.b2clogin.com`, `${sharedUrls.authorityBaseUrl}`],
    redirectUri: config.AUTH_REDIRECT_URI,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage, // "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11
  },
  system: {
    allowRedirectInIframe: true,
    iframeHashTimeout: 10000, //see https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/2213
  },
};

export const msalFrontInstanceLogoutConfig: Configuration = {
  auth: {
    clientId: config.API_APP_ID,
  },
};

export const msalGhostUserConfig: Configuration = {
  auth: {
    ...msalConfig.auth,
    authority: ghostUserAuthority,
  },
  cache: {
    ...msalConfig.cache,
  },
};

export const loginRequest: RedirectRequest = {
  scopes: [config.API_APP_ID],
};
export const createTokenRequest = (account: AccountInfo) => {
  return {
    scopes: [config.API_APP_ID],
    forceRefresh: false,
    account: account,
    redirectUri: config.AUTH_REDIRECT_URI,
  };
};
export const logoutRequest = { postLogoutRedirectUri: sharedUrls.wwwRoot };

export const appInitialLoginSuccessFlag = '_mvp_s_init';
export const userNameKey = '_mvp_name';

export const sessionTimeoutInSeconds = config.SESSION_TIMEOUT;
export const sessionTimeoutIntervalCheckInSeconds = config.SESSION_TIMEOUT_CHECK;
