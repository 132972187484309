import { DsCard, DsExternalTextLink, DsText, size } from '@mvp/design-system';
import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { RecertCategoryGroupEnum, RecertDisplayText, RecertModel } from '../../models/InitInfoResult.model';
import { PhoneLine } from './../PhoneLine';
import { GetRecertCategoryGroup, GetRecertMessageForBanner } from './RecertUtils';
import { formatDate } from '../../utils/dates';
import { CommonSsoTooltip } from '../CommonSsoTooltip';
import { HeapLocation, Target, getHeapIdent } from '../../models/heapIdentifiers.model';
import { useHeap } from '../../hooks/useHeap';

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: '#EFB726',
  },
  link: {
    fontWeight: 600,
    textDecoration: 'underline',
  },
  leftGridItem: {
    padding: `${size.xs(theme)} ${size.l(theme)}`,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: size.m(theme),
      paddingRight: size.m(theme),
    },
  },
  rightGridItem: {
    backgroundColor: '#F2D398',
    [theme.breakpoints.down('xs')]: {
      padding: `${size.xs(theme)} 0`,
    },
    display: 'grid',
    alignItems: 'center',
    textAlign: 'center',
  },
  toolTipPopper: {
    zIndex: 10000,
  },
}));

const renewViewHeapId = getHeapIdent(HeapLocation.recert, Target.recertBannerRenewView);
const renewContactNumberHeapId = getHeapIdent(HeapLocation.recert, Target.recertBannerRenewContactNumber);
const renewRenewalWebLinkHeapId = getHeapIdent(HeapLocation.recert, Target.recertBannerRenewRenewalWebLink);
const deadlineMissedViewHeapId = getHeapIdent(HeapLocation.recert, Target.recertBannerDeadlineMissedView);
const deadlineMissedContactNumberHeapId = getHeapIdent(HeapLocation.recert, Target.recertBannerDeadlineMissedContactNumber);
const deadlineMissedRenewalWebLinkId = getHeapIdent(HeapLocation.recert, Target.recertBannerDeadlineMissedRenewalWebLink);

const RecertGlobalBanner: React.FC<{ recertData: RecertModel | undefined | null }> = ({ recertData }) => {
  const classes = useStyles();

  const theme = useTheme();
  const phoneLineAltDisplayText = useMediaQuery(theme.breakpoints.down('xs')) ? 'Call to Renew Coverage' : undefined;

  const [showBanner, setShowBanner] = useState<boolean>(false);

  const [displayText, setDisplayText] = useState<RecertDisplayText | null>(null);

  const [currentCatGroup, setCurrentCategoryGroup] = useState<RecertCategoryGroupEnum | null>(null);

  const { sendCustomEvent } = useHeap();

  useEffect(() => {
    if (recertData) {
      setDisplayText(GetRecertMessageForBanner(recertData.recertCategory, formatDate(recertData.hireDate)));
      setCurrentCategoryGroup(GetRecertCategoryGroup(recertData.recertCategory));
    }
  }, [recertData]);

  useEffect(() => {
    if (recertData && displayText) {
      setShowBanner(recertData.visible);

      if (recertData.visible) {
        sendCustomEvent(currentCatGroup === RecertCategoryGroupEnum.Group60 ? renewViewHeapId : deadlineMissedViewHeapId);
      }
    }
  }, [recertData, displayText]);

  if (!showBanner || !displayText) return <></>;

  var healthNyGovLink = displayText.healthNyGovLink ? (
    <CommonSsoTooltip classes={{ popper: classes.toolTipPopper }} placement="bottom">
      <DsExternalTextLink href="https://health.ny.gov" text={displayText.healthNyGovLink} openInNewTab />
    </CommonSsoTooltip>
  ) : (
    <></>
  );

  return (
    <DsCard className={classes.card}>
      <Grid container>
        <Grid item xs={12} sm={9} md={10} className={classes.leftGridItem}>
          <DsText variant="body2">
            {displayText.Title}
            {displayText.PrependBody}
            {healthNyGovLink}
            {displayText.healthNyGovLinkAppendText}
            <PhoneLine
              phoneNumber={displayText.PhoneNumber}
              id={currentCatGroup === RecertCategoryGroupEnum.Group60 ? renewContactNumberHeapId : deadlineMissedContactNumberHeapId}
              variant="body2"
            />
            {displayText.MidBody}
            <DsExternalTextLink
              className={classes.link}
              href="https://www.mvphealthcare.com/plans/renew-plan"
              text="renewal web page"
              openInNewTab
              id={currentCatGroup === RecertCategoryGroupEnum.Group60 ? renewRenewalWebLinkHeapId : deadlineMissedRenewalWebLinkId}
            />
            {displayText.AppendBody}
          </DsText>
        </Grid>
        <Grid item xs={12} sm={3} md={2} className={classes.rightGridItem}>
          <PhoneLine
            phoneNumber="1-844-687-1235"
            id={currentCatGroup === RecertCategoryGroupEnum.Group60 ? renewContactNumberHeapId : deadlineMissedContactNumberHeapId}
            variant="body2"
            altDisplayText={phoneLineAltDisplayText}
          />
        </Grid>
      </Grid>
    </DsCard>
  );
};

export default RecertGlobalBanner;
