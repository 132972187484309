import React, { Component, ReactChild } from 'react';
import { ContentError } from './ContentError';

interface ErrorBoundaryProps {
  children?: ReactChild;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = {
    hasError: false,
  };

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <ContentError />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
