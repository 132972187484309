import { Grid, makeStyles, Typography } from '@material-ui/core';
import { size } from '@mvp/design-system';
import React from 'react';
import { ControlledPhoneNumberField } from '../../../../formControl/ControlledTextField';
import { ErrorMessage } from '../../../../models/errorMessage.model';
import { FormProps, PhoneForm } from '../../../../models/updateUserInfo.model';
import ProfileAndSettingsText from '../../ProfileAndSettingsText';

interface ModifyPhoneProps {
  formProps: FormProps<PhoneForm>;
  errorMessage: ErrorMessage | null;
  isHomeInfo: boolean;
  isDisabled?: boolean;
}

const useStyles = makeStyles((theme) => ({
  modifyPhoneContainer: {
    padding: `0.375rem 0`,
  },
  errorMessage: {
    color: theme.palette.primary.main,
    marginTop: `-${size.xxs(theme)}`,
    fontSize: '0.875rem',
  },
  submitAndErrorContainer: {
    marginLeft: '0.375rem',
  },
  divider: {
    marginTop: size.m(theme),
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      marginLeft: `-${size.m(theme)}`,
    },
  },
}));

export const ModifyPhone: React.FC<ModifyPhoneProps> = (props) => {
  const { formProps, errorMessage, isHomeInfo, isDisabled } = props;
  const classes = useStyles();

  const schemaObject = isHomeInfo ? 'homePhoneNumber' : 'mailingPhoneNumber';

  return (
    <>
      <Grid container spacing={3} className={classes.modifyPhoneContainer}>
        <Grid item lg={5} md={5} sm={12} xs={12}>
          <ControlledPhoneNumberField<PhoneForm>
            inputProps={{
              label: ProfileAndSettingsText.contactInformation.updatePhone.form.phone,
              id: `${isHomeInfo ? 'homePhone' : 'mailingPhone'}.phone`,
            }}
            name={`${schemaObject}.phone`}
            control={formProps.control}
            variant="none"
          />
        </Grid>
        <Grid item lg={5} md={5} sm={12} xs={12}>
          <ControlledPhoneNumberField<PhoneForm>
            inputProps={{
              label: ProfileAndSettingsText.contactInformation.updatePhone.form.confirmPhone,
              id: `${isHomeInfo ? 'homePhome' : 'mailingPhone'}.confirmphone`,
              disabled: isDisabled,
            }}
            name={`${schemaObject}.confirmPhone`}
            control={formProps.control}
            variant="none"
          />
        </Grid>
      </Grid>
      <Grid item direction="column" className={classes.submitAndErrorContainer}>
        {errorMessage && <Typography className={classes.errorMessage}>{errorMessage.text}</Typography>}
      </Grid>
    </>
  );
};
