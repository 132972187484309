import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { useOnboardingContext } from '../../../../hooks/useOnboardingContext';
import { useUpdateAccountStatus } from '../../../../hooks/useUpdateAccountStatus';
import { TaskStep } from '../../../../models';
import { CommPrefAddressAndPhone } from '../../../../pages/CommunicationPreferences/components/CommPrefAddressAndPhone';
import { CommPrefEmail } from '../../../../pages/CommunicationPreferences/components/CommPrefEmail';
import { ContentError } from '../../../ContentError';
import { LoadingSkeletonWrapper } from '../../../LoadingSkeletonWrapper';
import { OnboardingText } from '../../OnboardingText';

interface CommPrefContentProps {
  onUpdate: (arg0: TaskStep) => void;
}

const CommPefSkeletonLoader = () => <Skeleton variant="rect" width="100%" height="18rem" />;

const CommPrefContent: React.FC<CommPrefContentProps> = ({ onUpdate }) => {
  const { commPref } = useOnboardingContext();
  const { updateMessage } = useUpdateAccountStatus(commPref.data?.updateAccountStatus);

  if (!commPref.data || !commPref.data?.updateAccountStatus) {
    return <ContentError linkText={OnboardingText.esupportText} />;
  } else {
    return (
      <>
        <CommPrefAddressAndPhone
          homeAddressInfo={{
            address: commPref.data.homeAddress,
            phoneNumber: commPref.data.phoneNumber,
          }}
          mailingAddressInfo={{
            address: commPref.data.mailingAddress,
            phoneNumber: commPref.data.phoneNumber,
          }}
          updateMessage={updateMessage}
          canUpdate={commPref.data.updateAccountStatus.updateAddress}
          statusCode={commPref.data.updateAccountStatus.addressMessage}
          onBoardingUpdate={onUpdate}
        />
        <CommPrefEmail email={commPref.data.email} onBoardingUpdate={onUpdate} />
      </>
    );
  }
};

export default LoadingSkeletonWrapper(CommPrefContent, CommPefSkeletonLoader);
