export const characterLimits = {
  totalCharges: 12,
  providerName: 55,
  address: 40,
  city: 19,
  line2: 19,
  npi: 10,
  employerName: 50,
  insuranceCompany: 50,
  policyId: 20,
  firstName: 20,
  lastName: 35,
  newMessageTopic: 99,
  newMessageContent: 3500,
};
