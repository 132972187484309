import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { GuardedRoute } from 'react-router-guards';
import { RouteGuardContainer } from './components/RouteGuardContainer';
import Page from './pages/Page';
import ErrorBoundary from './components/ErrorBoundary';
import { RouteConfig } from './routesConfig';
import { FeedbackWidget } from './pages/Dashboard/FeedbackWidget';
import { isGhostUser } from './components/AuthProvider';

const AppRoutes: React.FC<{ routes: RouteConfig[] }> = ({ routes }) => {
  const createTitle = (pageTitle: string) => `${pageTitle} | MVP`;
  const getRouteType = (authGuard: boolean) => (authGuard ? GuardedRoute : Route);
  const isGhosting = isGhostUser();

  return (
    <RouteGuardContainer>
      <Switch>
        {routes.map((config, index) => {
          const { PageToRender, authGuard, routeProps, title } = config;
          const { render, ...otherRouteProps } = routeProps;
          const Routing = getRouteType(authGuard);

          return (
            <Routing
              key={`routing-container-${index}`}
              render={() => (
                <Page key={`page-route-${index}`} title={createTitle(title)}>
                  {!config.hideFeedbackWidget && !isGhosting && <FeedbackWidget />}
                  <ErrorBoundary>
                    <Suspense fallback={<div></div>}>
                      <PageToRender />
                    </Suspense>
                  </ErrorBoundary>
                </Page>
              )}
              {...otherRouteProps}
            />
          );
        })}
      </Switch>
    </RouteGuardContainer>
  );
};

export default AppRoutes;
