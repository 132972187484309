import axiosSingleton from '../components/axios';
import config from '../config';
import { AnalyticsResponse } from '../models/api/models/AnalyticsResponse';

export const analyticsProvider = {
  async getAnalyticsData(): Promise<AnalyticsResponse> {
    const response = await axiosSingleton.get<AnalyticsResponse>(`${config.API_URL}/v1/analytics`, {
      headers: {
        accept: 'application/json',
      },
    });
    return response.data;
  },
};
