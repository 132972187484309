import { makeStyles, Theme, Typography } from '@material-ui/core';
import { size } from '@mvp/design-system';
import React from 'react';
import { AddressType, CommunicationPreferencesAddress } from '../../../models/communicationPreferences.model';
import { toTitleCase } from '../../../utils/string';

interface AddressBlockProps {
  addressType: AddressType;
  className?: string;
  address: CommunicationPreferencesAddress;
  disableCounty?: boolean;
}

const mapAddressToTitleCase = (address: CommunicationPreferencesAddress): CommunicationPreferencesAddress => {
  return {
    ...address,
    line1: !!address.line1 ? toTitleCase(address.line1) : '',
    line2: !!address.line2 ? toTitleCase(address.line2) : undefined,
    line3: !!address.line3 ? toTitleCase(address.line3) : undefined,
    city: !!address.city ? toTitleCase(address.city) : '',
    county: !!address.county ? toTitleCase(address.county) : '',
  };
};

interface AddressBlockStyleProps {
  disableCounty: boolean;
}

const useStyles = makeStyles<Theme, AddressBlockStyleProps>((theme) => ({
  addressContainer: {
    width: '50%',
    paddingRight: '5rem',
    paddingBottom: size.s(theme),
    [theme.breakpoints.down('xs')]: {
      paddingRight: '0',
      width: '100%',
    },
  },
  addressBlockLine: {
    lineHeight: '1.25rem',
  },
  county: {
    color: (props) => (props.disableCounty ? theme.palette.text.disabled : 'initial'),
  },
}));

export const AddressBlock: React.FC<AddressBlockProps> = (props) => {
  const { addressType, className, address, disableCounty = false } = props;
  const classes = useStyles({ disableCounty });
  const displayAddress = mapAddressToTitleCase(address);
  const homeOrMail = addressType === AddressType.Home ? 'home' : 'mailing';

  return (
    <span className={`${classes.addressContainer} ${className}`}>
      <Typography className={classes.addressBlockLine} variant="button">
        {addressType}
      </Typography>
      <Typography id={`${homeOrMail}.addressLine1`} className={classes.addressBlockLine} variant="body2">
        {displayAddress.line1}
      </Typography>
      {displayAddress.line2?.length !== 0 && (
        <Typography id={`${homeOrMail}.addressLine2`} className={classes.addressBlockLine} variant="body2">
          {displayAddress.line2}
        </Typography>
      )}
      {displayAddress.line3?.length !== 0 && (
        <Typography id={`${homeOrMail}.addressLine3`} className={classes.addressBlockLine} variant="body2">
          {displayAddress.line3}
        </Typography>
      )}
      <Typography id={`${homeOrMail}.ctstzp`} className={classes.addressBlockLine} variant="body2">
        {`${displayAddress.city}, ${displayAddress.state} ${displayAddress.zipCode ? displayAddress.zipCode : ''}`}{' '}
      </Typography>
      <Typography
        id={`${homeOrMail}.county`}
        className={`${classes.addressBlockLine} ${classes.county}`}
        variant="body2"
      >{`${displayAddress.county} County`}</Typography>
    </span>
  );
};
