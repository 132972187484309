import lgFortImg from '../assets/img/onboarding/OnboardingImgs-Welcome-3-LG.png';
import smFortImg from '../assets/img/onboarding/OnboardingImgs-Welcome-3-SM.png';
import lgProviderImg from '../assets/img/onboarding/OnboardingImgs-Find-Provider-3-LG.png';
import smProviderImg from '../assets/img/onboarding/OnboardingImgs-Find-Provider-3-SM.png';
import lgWelcomeImg from '../assets/img/onboarding/OnboardingImgs-Welcome-2-LG.png';
import smWelcomeImg from '../assets/img/onboarding/OnboardingImgs-Welcome-2-SM.png';

export interface OnboardingTask {
  isComplete: boolean;
  hasDependentTask: boolean;
  onboardingTask: string;
  initialCompletionDate: string;
  modifiedDate: string;
}

export interface OnboardingTaskWidget {
  onboardingTasks: OnboardingTask[];
  isOnboardingFetching: boolean;
}

export interface OnboardingData {
  shouldViewOnboarding: boolean;
  needsPcP: boolean;
  planRequiresPcP: boolean;
  tasks: OnboardingTask[];
}

export interface BaseOnboardingStepProps {
  advanceStep: () => void;
}

export interface OnboardingStepProps extends BaseOnboardingStepProps {
  goBack: () => void;
  goToStep?: (step: TaskStep) => void;
}

export enum TaskKeys {
  Select_PCP = 'Pcp',
  CommPref = 'CommPref',
  Onboarding = 'Onboarding',
}

export enum TaskStep {
  Welcome,
  Update_Comm_Pref,
  Select_PCP,
  Update_Comm_Pref_Email,
}

interface ShowStepForTask {
  [key: string]: boolean;
}

export const showStepForTask: ShowStepForTask = {
  [TaskStep.Welcome]: true,
  [TaskStep.Update_Comm_Pref]: true,
  [TaskStep.Select_PCP]: true,
  [TaskStep.Update_Comm_Pref_Email]: false,
};

export enum ImageSizes {
  SM = 'SM',
  MD = 'MD',
  LG = 'LG',
}

export interface ImageForScreenSizes {
  [key: string]: string;
}

export interface ImagesForEachStep {
  [key: string]: ImageForScreenSizes;
}

export const onboardingImages: ImagesForEachStep = {
  [TaskStep.Welcome]: { [ImageSizes.SM]: smFortImg, [ImageSizes.LG]: lgFortImg },
  [TaskStep.Update_Comm_Pref]: { [ImageSizes.SM]: smWelcomeImg, [ImageSizes.LG]: lgWelcomeImg },
  [TaskStep.Update_Comm_Pref_Email]: { [ImageSizes.SM]: smWelcomeImg, [ImageSizes.LG]: lgWelcomeImg },
  [TaskStep.Select_PCP]: { [ImageSizes.SM]: smProviderImg, [ImageSizes.LG]: lgProviderImg },
};
