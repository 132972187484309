import { HubConnection } from '@microsoft/signalr';
import React, { FC, createContext, useCallback, useContext, useRef, useState } from 'react';
import { Configure } from '../signalR';
import { v4 as uuidv4 } from 'uuid';
import { Channels } from '../models/payments/signalR.model';
import { useIdTokenClaims } from '../hooks/useIdTokenClaims';

const SignalRContext = createContext<{
  listen: (channel: Channels, callbackFn: (response: any) => void, errorCallbackFn?: (error: any) => void) => void;
  getClientId: () => string;
}>({
  listen: (channel: Channels, callbackFn: (response: any) => void, errorCallbackFn?: (error: any) => void) => {},
  getClientId: () => '',
});

export const useSignalRContext = () => useContext(SignalRContext);

export const SignalRContextProvider: FC = ({ children }) => {
  const { claims } = useIdTokenClaims();
  const [hub, setHub] = useState<HubConnection>();
  const clientId = useRef(claims?.b2cObjectId ?? uuidv4());

  const getHub = () => {
    if (hub) {
      return hub;
    }

    const newHub = Configure(clientId.current);
    setHub(newHub);
    return newHub;
  };

  const listen = useCallback((channel: Channels, callbackFn: (response: any) => void, errorCallbackFn?: (error: any) => void) => {
    const hub = getHub();
    hub?.on(`${channel}`, (response: any) => {
      callbackFn(response);
    });

    hub?.start().catch((error: any) => {
      errorCallbackFn?.(error);
    });
  }, []);

  const getClientId = () => {
    return clientId.current;
  };

  return (
    <SignalRContext.Provider
      value={{
        listen,
        getClientId,
      }}
    >
      {children}
    </SignalRContext.Provider>
  );
};
