import { Grid, makeStyles } from '@material-ui/core';
import { DsIcon, DsPrimaryButton, DsTooltip, size } from '@mvp/design-system';
import React from 'react';
import { ComponentToggleDisplayWrapper } from '../../../../components/ComponentToggleDisplayWrapper';
import { getHeapIdent, HeapLocation, Target } from '../../../../models/heapIdentifiers.model';
import ProfileAndSettingsText from '../../ProfileAndSettingsText';
import { PhraseDetails } from '../../utils/updateInfoText';

const useStyles = makeStyles((theme) => ({
  cantUpdateContainer: {
    textAlign: 'end',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  updateButton: {
    marginRight: size.l(theme),
    [theme.breakpoints.up('sm')]: {
      height: '3rem',
    },
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      marginLeft: '2.8125rem',
      marginBottom: size.m(theme),
    },
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: '1.125rem',
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: '1.75rem',
    textAlign: 'center',
    maxWidth: '25.375rem',
  },
  toolTipComponent: {
    marginRight: size.s(theme),
  },
  cantUpdateIcon: {
    color: 'black',
    fontSize: '1.5rem',
  },
}));

const updateAddressHeapId = getHeapIdent(HeapLocation.acctprefs, Target.updateAddressButton);
const cancelAddressHeapId = getHeapIdent(HeapLocation.acctprefs, Target.cancelAddressButton);

interface ToggleEditButton {
  updateAddress: boolean;
  handleModifyAddress: () => void;
  disableUpdates?: boolean;
}

const EditButton: React.FC<ToggleEditButton> = (props) => {
  const { updateAddress, handleModifyAddress, disableUpdates } = props;

  const classes = useStyles();

  return (
    <DsPrimaryButton
      text={updateAddress ? ProfileAndSettingsText.contactInformation.cancelText : ProfileAndSettingsText.contactInformation.updateText}
      variant="text"
      onClick={handleModifyAddress}
      className={classes.updateButton}
      id={updateAddress ? cancelAddressHeapId : updateAddressHeapId}
      disabled={disableUpdates}
    />
  );
};

interface CannotUpdateIconProps {
  updateMessage: PhraseDetails | undefined;
}

const CannotUpdateIcon: React.FC<CannotUpdateIconProps> = (props) => {
  const { updateMessage } = props;

  const classes = useStyles();

  return (
    <Grid className={classes.cantUpdateContainer}>
      <DsTooltip
        arrow
        title={`${updateMessage?.displayText} ${updateMessage?.linkText}`}
        classes={{
          tooltip: classes.tooltip,
        }}
        className={classes.toolTipComponent}
      >
        <div>
          <DsIcon title="" icon="fal fa-light fa-info-circle" className={classes.cantUpdateIcon} />
        </div>
      </DsTooltip>
    </Grid>
  );
};

export default ComponentToggleDisplayWrapper(EditButton, CannotUpdateIcon);
