import { BillingHistory, PaymentHistory, History, AutoPay, AutoPayStatus } from '../../../models/payments/payments.model';

export const isPaymentHistoryType = (a: PaymentHistory | BillingHistory): a is PaymentHistory => 'paymentDate' in a;

export const combinePaymentAndHistoryArrays = (paymentHistory: PaymentHistory[], billingHistory: BillingHistory[]): History[] => {
  const history = [...paymentHistory, ...billingHistory];
  return history.sort((a, b) => {
    let firstDate = '';
    let secondDate = '';
    if (isPaymentHistoryType(a)) {
      firstDate = a.paymentDate;
    } else {
      firstDate = a.dueDate;
    }
    if (isPaymentHistoryType(b)) {
      secondDate = b.paymentDate;
    } else {
      secondDate = b.dueDate;
    }
    return new Date(firstDate) < new Date(secondDate) ? 1 : -1;
  });
};

export const snackBarMessageQueryParamKey = 'snackbar-message';

export const getAutoPayBooleans = (autoPay?: AutoPay | null | undefined) => {
  let isEnrolledInAutoPay = false;
  let isEnabledAutoPay = false;
  let isDisabledAutoPay = false;

  if (!(autoPay == null)) {
    if (autoPay?.status === AutoPayStatus.enrolled) {
      isEnrolledInAutoPay = true;
    } else if (autoPay?.status === AutoPayStatus.disabled) {
      isDisabledAutoPay = true;
    } else if (autoPay?.status === AutoPayStatus.enabled) {
      isEnabledAutoPay = true;
    }
  }

  return { isEnrolledInAutoPay, isEnabledAutoPay, isDisabledAutoPay };
};
