import { Grid } from '@material-ui/core';
import React from 'react';
import { usStates } from '../../../../constants/usStates';
import { ControlledSelect } from '../../../../formControl/ControlledSelect';
import { ControlledBaseZipCodeField, ControlledTextField } from '../../../../formControl/ControlledTextField';
import { CommunicationPreferencesAddress } from '../../../../models/communicationPreferences.model';
import { AddressForm } from '../../../../models/updateUserInfo.model';
import { characterLimits } from '../../../../utils/characterLimits';
import ProfileAndSettingsText from '../../ProfileAndSettingsText';

interface ModifyAddressFormProps {
  address: CommunicationPreferencesAddress;
  disabled?: boolean;
  control: any;
  isHomeAddress?: boolean;
  onStateSelectChange: () => void;
}

export const ModifyAddressForm: React.FC<ModifyAddressFormProps> = (props) => {
  const { disabled, control, isHomeAddress = true, onStateSelectChange } = props;

  return (
    <>
      <Grid container spacing={3}>
        <Grid item lg={9} md={9} sm={9} xs={12}>
          <ControlledTextField<AddressForm>
            name={`${isHomeAddress ? 'homeAddress' : 'mailingAddress'}.line1`}
            control={control}
            helperText="Please enter a valid street address"
            inputProps={{
              id: `${isHomeAddress ? 'homeAddress' : 'mailingAddress'}.line1`,
              label: ProfileAndSettingsText.contactInformation.updateAddress.form.line1,
              inputProps: { maxlength: characterLimits.address, displayCharacterCounterAtPercentage: 0.8 },
              disabled,
            }}
            variant="none"
            addMarginBottomOnError
          />
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={12}>
          <ControlledTextField<AddressForm>
            name={`${isHomeAddress ? 'homeAddress' : 'mailingAddress'}.line2`}
            control={control}
            inputProps={{
              id: `${isHomeAddress ? 'homeAddress' : 'mailingAddress'}.line2`,
              label: ProfileAndSettingsText.contactInformation.updateAddress.form.line2,
              inputProps: { maxlength: characterLimits.line2, displayCharacterCounterAtPercentage: 0.8 },
              disabled,
            }}
            variant="none"
            addMarginBottomOnError
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item lg={4} md={4} sm={4} xs={12}>
          <ControlledTextField<AddressForm>
            inputProps={{
              label: ProfileAndSettingsText.contactInformation.updateAddress.form.city,
              id: `${isHomeAddress ? 'homeAddress' : 'mailingAddress'}.city`,
              inputProps: { maxlength: characterLimits.city, displayCharacterCounterAtPercentage: 0.8 },
              disabled,
            }}
            name={`${isHomeAddress ? 'homeAddress' : 'mailingAddress'}.city`}
            helperText="Please enter a valid city"
            control={control}
            variant="none"
          />
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={6}>
          <ControlledSelect<AddressForm>
            name={`${isHomeAddress ? 'homeAddress' : 'mailingAddress'}.state`}
            control={control}
            selectProps={{
              onClick: onStateSelectChange,
              id: `${isHomeAddress ? 'homeAddress' : 'mailingAddress'}.state`,
              parentFullWidth: true,
              label: ProfileAndSettingsText.contactInformation.updateAddress.form.state,
              options: usStates.map((state) => state.abbreviation),
              disabled,
            }}
          />
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={6}>
          <ControlledBaseZipCodeField<AddressForm>
            inputProps={{
              label: ProfileAndSettingsText.contactInformation.updateAddress.form.zip,
              id: `${isHomeAddress ? 'homeAddress' : 'mailingAddress'}.zipCode`,
              disabled,
            }}
            name={`${isHomeAddress ? 'homeAddress' : 'mailingAddress'}.zipCode`}
            helperText="Please enter a valid zip code"
            control={control}
            variant="none"
          />
        </Grid>
      </Grid>
    </>
  );
};

/** This is the first code I pushed at my first real programming job. I hope it works, here's to a million more! Thank you MVP. Nate Anderson, Intern, Summer 2022 */
