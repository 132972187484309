import React, { useEffect, useState } from 'react';
import { DsSideNavigation } from '@mvp/design-system';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LoadingContainer } from './LoadingContainer';
import { ErrorCard } from './ErrorCard';
import { size } from '@mvp/design-system';
import { CustomMuiTheme } from './CustomMuiTheme';
import { filterLinks, mapLinksToProps, setSelected } from '../hooks/useNavigationItems';
import { CategorizedNavigationLinks, LocationId } from '../models';
import { useStoreon } from 'storeon/react';
import { NavLinksEvents, NavLinksState } from '../storeProviders/reducers';
import { useHistory } from 'react-router-dom';
//IMPORTANT: opt out from adding additional imports to <Header> & <PortalSidebar> if an alternative is possible...
//Test out integration with dotnet pages when not

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.grey[50],
    marginRight: 0,
    width: '21.875rem',
    minWidth: '21.875rem',
    [`@media print`]: {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      width: '20rem',
      minWidth: '20rem',
    },
  },
  errorCard: {
    margin: size.l(theme),
  },
}));

const PortalSidebar = ({
  categorizedLinks,
  isWithinReactApp = true,
  errorState = false,
  isLoading = false,
}: {
  categorizedLinks: CategorizedNavigationLinks[];
  isWithinReactApp: boolean;
  errorState?: boolean;
  isLoading?: boolean;
}) => {
  const classes = useStyles();
  const { navLinksStore } = useStoreon<NavLinksState, NavLinksEvents>('navLinksStore');
  const { data: navLinks } = navLinksStore;
  const filteredLinks = filterLinks(isWithinReactApp ? categorizedLinks : navLinks, [LocationId.navigation]);
  const history = useHistory();
  const [links, setLinks] = useState(mapLinksToProps(filteredLinks, isWithinReactApp, history));

  useEffect(() => {
    setLinks(mapLinksToProps(setSelected(filteredLinks), isWithinReactApp, history));
  }, [categorizedLinks]);

  useEffect(() => {
    if (isWithinReactApp) {
      const unlisten = history.listen(() => {
        setLinks(mapLinksToProps(setSelected(filteredLinks), isWithinReactApp, history));
      });
      return () => {
        unlisten();
      };
    }
  }, [history, categorizedLinks]);

  return (
    <CustomMuiTheme>
      <Box className={classes.root}>
        <LoadingContainer isLoading={isLoading}>
          {!errorState ? (
            <>
              {!errorState ? (
                <DsSideNavigation items={links} />
              ) : (
                <ErrorCard className={classes.errorCard} text1="Unable to load navigation" backgroundContrast />
              )}
            </>
          ) : (
            <ErrorCard className={classes.errorCard} text1="Unable to load navigation" backgroundContrast />
          )}
        </LoadingContainer>
      </Box>
    </CustomMuiTheme>
  );
};

export default PortalSidebar;
