export const printPage = (printOrientation?: 'portrait' | 'landscape') => {
  const css = `@page {size: A3 ${printOrientation || ''}}`;
  const head = document.head || document.getElementsByTagName('head')[0];
  const style = document.createElement('style');

  style.media = 'print';
  style.appendChild(document.createTextNode(css));

  head.appendChild(style);
  setTimeout(() => {
    window.print();
    head.removeChild(style);
  }, 1);
};
