import {
  CommercialMemberPreferenceSelection,
  CommunicationPreferences,
  TransactionalPreferences,
  UpdateAddressAndPhone,
} from '../models/communicationPreferences.model';
import axiosSingleton from '../components/axios';
import config from '../config';

export const communicationPreferencesProvider = {
  async getCommunicationPreferences(): Promise<CommunicationPreferences | null> {
    const response = await axiosSingleton.get<CommunicationPreferences>(`${config.API_URL}/v1/communication-preferences`);
    return response.data;
  },
  async getNewCommunicationPreferences(): Promise<CommunicationPreferences | null> {
    const response = await axiosSingleton.get<CommunicationPreferences>(`${config.API_URL}/v2/communication-preferences`);
    return response.data;
  },
  async updateLastConfirmationDate(): Promise<boolean | null> {
    const response = await axiosSingleton.put<boolean>(`${config.API_URL}/v1/communication-preferences`);
    return response.data;
  },
  async updateCommercialPreferences(commercialMemberPreferenceSelection: CommercialMemberPreferenceSelection): Promise<boolean | null> {
    const response = await axiosSingleton
      .patch<boolean>(`${config.API_URL}/v1/communication-preferences/commercial-preference`, {
        commercialMemberPreferenceSelection,
      })
      .catch(() => {
        return { data: false };
      });
    return response.data;
  },
  async updateTransactionalPreferences(transactionalMemberPreferenceSelection: TransactionalPreferences): Promise<boolean | null> {
    const response = await axiosSingleton
      .patch<boolean>(`${config.API_URL}/v1/communication-preferences/transactional-preference`, {
        transactionalMemberPreferenceSelection,
      })
      .catch(() => {
        return { data: false };
      });
    return response.data;
  },
  async updateEmail(email: string): Promise<boolean | null> {
    const response = await axiosSingleton.put<boolean>(`${config.API_URL}/v1/communication-preferences/email`, {
      email,
    });
    return response.data;
  },
  async updateAddressAndPhone(addressInfo: UpdateAddressAndPhone): Promise<boolean | null> {
    const response = await axiosSingleton.put<boolean>(`${config.API_URL}/v1/communication-preferences/address`, addressInfo).catch(() => {
      return { data: false };
    });
    return response.data;
  },
};
