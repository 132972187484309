import React from 'react';
import { DsIcon, size } from '@mvp/design-system';
import { makeStyles, Paper, Typography } from '@material-ui/core';
import { ESupportTextLink } from './ESuppportTextLink';
import { Variant } from '@material-ui/core/styles/createTypography';

interface ContentErrorProps {
  errorTitle?: string;
  icon?: string;
  linkText?: string;
  customMessageFontVariant?: Variant;
}

interface GeneralErrorMessageProps extends Omit<ContentErrorProps, 'errorTitle' | 'icon' | 'customMessageFontVariant'> {}

const useStyles = makeStyles((theme) => ({
  errorCard: {
    display: 'flex',
    marginTop: size.s(theme),
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.grey[50],
    alignItems: 'center',
  },
  icon: {
    fontSize: '2.5rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: size.l(theme),
    },
    color: theme.palette.grey[700],
    padding: size.m(theme),
  },
  messageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
    padding: size.s(theme),
  },
  largeMessage: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  smallMessage: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  phoneNumber: {
    fontWeight: 600,
  },
}));

const GeneralErrorMessage: React.FC<GeneralErrorMessageProps> = (props) => {
  const classes = useStyles();
  const MessageOne = () => <>Hmm, we can’t access that information right now. Please refresh or try again later.</>;
  const MessageTwo = () => (
    <>
      If you still have trouble, contact <ESupportTextLink openInNewTab variant="body2" linkText={props.linkText} /> at{' '}
      <span className={classes.phoneNumber}>1-888-656-5695</span>.
    </>
  );

  return (
    <>
      <div className={classes.largeMessage}>
        <Typography variant="body2">
          <MessageOne />
        </Typography>
        <Typography variant="body2">
          <MessageTwo />
        </Typography>
      </div>
      <Typography variant="body2" className={classes.smallMessage}>
        <MessageOne /> <MessageTwo />
      </Typography>
    </>
  );
};

export const ContentError: React.FC<ContentErrorProps> = ({
  errorTitle,
  children,
  icon = 'telescope',
  customMessageFontVariant: messageFontVariant = 'body2',
  linkText,
}) => {
  const classes = useStyles();

  const customErrorMessage = (
    <>
      {errorTitle && <Typography variant="h5">{errorTitle}</Typography>}
      <Typography variant={messageFontVariant}>{children}</Typography>
    </>
  );

  return (
    <Paper className={classes.errorCard} elevation={0} variant="outlined">
      <div className={classes.iconWrapper}>
        <div className={classes.icon}>
          <DsIcon title="Error Icon" icon={`fal fa-${icon}`} />
        </div>
      </div>
      <div className={classes.messageWrapper}>
        {errorTitle || children ? customErrorMessage : <GeneralErrorMessage linkText={linkText} />}
      </div>
    </Paper>
  );
};
