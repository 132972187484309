import { Grid, makeStyles, Typography } from '@material-ui/core';
import { size } from '@mvp/design-system';
import React from 'react';
import { PhoneType } from '../../../../models/communicationPreferences.model';

interface PhoneBlockProps {
  phoneNumber: string;
  title: string;
  phoneType: PhoneType;
}

const useStyles = makeStyles((theme) => ({
  phoneInfoContainer: {
    width: '50%',
    paddingBottom: size.s(theme),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

export const PhoneBlock: React.FC<PhoneBlockProps> = (props) => {
  const { phoneNumber, title } = props;
  const classes = useStyles();
  const homeOrMail = props.phoneType === PhoneType.Home ? 'home' : 'mailing';

  return (
    <Grid item className={classes.phoneInfoContainer}>
      <Typography id={`${homeOrMail}.phone`} variant="button">
        {title}
      </Typography>
      <Typography id={`${homeOrMail}.phone`} variant="body2">
        {phoneNumber}
      </Typography>
    </Grid>
  );
};
