import React, { FC } from 'react';
import { DsFooter, DsLink } from '@mvp/design-system';
import { makeStyles } from '@material-ui/core';
import { getGroupLinks, getDividedLinks } from '@mvp/design-system';
import { sharedUrls } from '../sharedUrls';
import { CustomMuiTheme } from './CustomMuiTheme';
import { InMomentFeedbackWidget } from './InMomentFeedbackWidget';
import isFeatureEnabled, { FeatureToggleKeys } from '../hooks/useFeature';
import { hasLoggedIn, isGhostUser } from './AuthProvider';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[50],
    borderTop: '0.063rem solid',
    borderTopColor: theme.palette.grey[300],
    [`@media print`]: {
      display: 'none',
    },
  },
}));

interface FooterProps {
  removedTitles?: string[];
}

const Footer: FC<FooterProps> = ({ removedTitles }) => {
  const isGhosting = isGhostUser();
  const classes = useStyles();
  const wwwRoot = sharedUrls.wwwRoot;
  const isLoggedIn = hasLoggedIn();
  const showInmomentSurvey = isFeatureEnabled(FeatureToggleKeys.InMomentSurvey) && !isGhosting;

  const baseGroupLinks = getGroupLinks(wwwRoot, removedTitles);

  const groupLinks = baseGroupLinks.map((group) => {
    if (group.id === 'footer_group_members' && isLoggedIn) {
      group.links = group.links.map((link) => {
        if (link.id === 'mportal_quicklinks_finddoc') {
          link.url = sharedUrls.providerSearchUrl;
        }
        return link;
      });
    }
    return group;
  });

  const dividedLinks = [...getDividedLinks(wwwRoot)];

  return (
    <CustomMuiTheme>
      {showInmomentSurvey && <InMomentFeedbackWidget />}
      <footer id="footerContents" className={classes.root}>
        <DsFooter groupLinks={groupLinks} dividedLinks={dividedLinks} />
      </footer>
    </CustomMuiTheme>
  );
};

export default Footer;
