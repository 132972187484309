import { IPublicClientApplication } from '@azure/msal-browser';
import { logoutRequest } from '../authConfig';
import { hasLoggedIn } from '../components/AuthProvider';
import { sharedUrls } from '../sharedUrls';
import { StorageLocation, StorageProvider } from '../utils/storage';

export const lastInteractionTimerKey = '_mvp_u_last_interaction';

export const InactivityCheckAsync = async (timeoutInSeconds: number, instance?: IPublicClientApplication) => {
  const lastInteractionInMillisecondsString = StorageProvider.getItem(StorageLocation.localStorage, lastInteractionTimerKey);

  const sessionIsTimedOut = async () => {
    //session timeout callback. logging out logged in account
    if (!!instance) {
      instance.logoutRedirect(logoutRequest).catch(async (e) => {
        window.location.replace(sharedUrls.sharedComponentsLogoutUrl);
      });
    } else {
      window.location.replace(sharedUrls.sharedComponentsLogoutUrl);
    }
    return true;
  };

  if (!!lastInteractionInMillisecondsString && hasLoggedIn()) {
    //loggedin and last intaraction time is set
    const timeoutInMilliseconds = timeoutInSeconds * 1000;
    const lastInteractionInUnixTime = parseInt(lastInteractionInMillisecondsString);
    const millisecondsLapsedSinceLastInteraction = Date.now() - lastInteractionInUnixTime;

    if (millisecondsLapsedSinceLastInteraction > timeoutInMilliseconds) {
      //inactivity threshold triggered
      return await sessionIsTimedOut();
    } else {
      //session still active
      return false;
    }
  }

  //active logout initiated&completed from another tab
  return await sessionIsTimedOut();
};
