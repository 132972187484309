import i18n, { InitOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'en',
    supportedLngs: ['en', 'es'],
    load: 'currentOnly',
    backend: {
      loadPath: '/static/locales/{{lng}}/{{ns}}.json',
      requestOptions: {
        cache: 'no-cache',
      },
    },
  });

const mockTranslation = (translationText: { [key: string]: any } = {}) => {
  const initTranslationOptions: InitOptions = {
    lng: 'en',
    resources: {
      en: {
        payments: translationText,
        common: translationText,
        claims: translationText,
        wellbeing: translationText,
      },
    },
  };

  i18n.use(initReactI18next).init(initTranslationOptions);
};

export { i18n as default, mockTranslation };
