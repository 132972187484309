import axios from 'axios';
import config from '../config';

const axiosSingleton = axios.create();

axiosSingleton.defaults.headers['Ocp-Apim-Subscription-Key'] = `${config.API_SUBSCRIPTION_KEY}`;

export const addBearerTokenToAxios = (accessToken: string) => {
  axiosSingleton.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
};

export default axiosSingleton;
