import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { analyticsProvider } from '../api/analyticsProviders';
import { QueryKey } from '../ReactQueryClientProvider';
import { useIdTokenClaims } from './useIdTokenClaims';

export const useHeap = (shouldGetAnalyticsData: boolean = false) => {
  const { data: analyticsData } = useQuery(QueryKey.Analytics, analyticsProvider.getAnalyticsData, {
    enabled: shouldGetAnalyticsData,
    refetchOnMount: shouldGetAnalyticsData,
  });
  const claims = useIdTokenClaims();

  const addUserProperties = useCallback(() => {
    if (analyticsData && claims.claims) {
      (window as any).heap?.addUserProperties({
        ghosted: claims.claims?.isGhostAdmin,
        missingPCP: analyticsData?.onboardingData?.needsPcP,
        planRequiresPcP: analyticsData?.onboardingData?.planRequiresPcP,
        numberOfCareReminders: analyticsData?.reminders,
        isPaperless: analyticsData?.isPaperless,
      });
    }
  }, [analyticsData, claims]);

  const trackOnboardingWelcome = () => {
    (window as any).heap?.track('Onboarding (MPortal) - View - Onboarding Welcome Modal');
  };

  const sendCustomEvent = (id: string, body?: any) => {
    (window as any).heap?.track(id, body);
  };

  const addEventProperties = (body: any) => {
    (window as any).heap?.addEventProperties(body);
  };

  const toggleIsChecked = (isChecked: boolean) => {
    (window as any).heap?.addEventProperties({
      isChecked,
    });
  };
  const trackPreventiveCare = (action: string, type: string) => {
    (window as any).heap?.track('Preventive Care Reminders (MPortal) - Click - Care Action Item', {
      action,
      type,
    });
  };
  return { addUserProperties, trackOnboardingWelcome, toggleIsChecked, trackPreventiveCare, sendCustomEvent, addEventProperties };
};
