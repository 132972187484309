import { Grid, makeStyles } from '@material-ui/core';
import { DsIcon, size } from '@mvp/design-system';
import React from 'react';
import { PhoneType } from '../../../../models/communicationPreferences.model';
import ProfileAndSettingsText from '../../ProfileAndSettingsText';
import { PhoneBlock } from './PhoneBlock';

interface PhonesDisplayProps {
  homePhone: string;
  mailingPhone: string;
}

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    padding: `1.1rem 1.8rem ${size.s(theme)} ${size.xxs(theme)}`,
    display: 'flex',
  },
  iconColor: {
    color: theme.palette.primary.main,
  },
  iconFontSize: {
    fontSize: '2.25rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: size.l(theme),
    },
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    width: '100%',
    padding: `${size.xxs(theme)} 0 ${size.xxxs(theme)} 0`,
    [theme.breakpoints.down('xs')]: {
      paddingBottom: size.s(theme),
      flexDirection: 'column',
    },
  },
  container: {
    display: 'flex',
  },
}));

export const PhonesDisplay: React.FC<PhonesDisplayProps> = (props) => {
  const { homePhone, mailingPhone } = props;
  const classes = useStyles();

  return (
    <Grid className={classes.container}>
      <Grid>
        <Grid item className={classes.iconContainer}>
          <DsIcon title="Mobile Icon" icon="mobile" className={`${classes.iconColor} ${classes.iconFontSize}`} />
        </Grid>
      </Grid>
      <Grid item className={classes.infoContainer}>
        <PhoneBlock
          phoneType={PhoneType.Home}
          title={ProfileAndSettingsText.contactInformation.updatePhone.primaryPhoneTitle}
          phoneNumber={homePhone}
        />
        <PhoneBlock
          phoneType={PhoneType.Mailing}
          title={ProfileAndSettingsText.contactInformation.updatePhone.mailingPhoneTitle}
          phoneNumber={mailingPhone}
        />
      </Grid>
    </Grid>
  );
};
