import { makeStyles } from '@material-ui/core';
import { DsExternalTextLink } from '@mvp/design-system';
import React from 'react';
import { routes } from '../../routesConfig';
import { ErrorPageTemplate } from './ErrorPageTemplate';
import { PageErrorInstructions } from './PageErrorInstructions';

const useStyles = makeStyles(() => ({
  linkFont: {
    fontWeight: 800,
  },
}));

const PageNotFound: React.FC = () => {
  const classes = useStyles();

  const instructions = [
    [
      "Sorry, we can't seem to find that page. It may have moved or be temporarily be unavailable. If you reached this page by clicking a link, please ",
      <DsExternalTextLink className={classes.linkFont} text="Contact Us" href={routes.contactUsAuth} />,
      '.',
    ],
  ];

  return (
    <ErrorPageTemplate errorCode={404} errorMessage={'Oops!'}>
      <PageErrorInstructions instructions={instructions} />
    </ErrorPageTemplate>
  );
};

export default PageNotFound;
