import axiosSingleton from '../components/axios';
import config from '../config';
import { FamilyMemberInformation } from '../models';

export const memberInformationProvider = {
  async getMemberInformation(): Promise<FamilyMemberInformation[]> {
    const response = await axiosSingleton.get<FamilyMemberInformation[]>(`${config.API_URL}/v1/member-information`, {
      headers: {
        accept: 'application/json',
      },
    });
    return response.data;
  },

  async getActiveMemberInformation(): Promise<FamilyMemberInformation[]> {
    const url = new URL(`${config.API_URL}/v1/member-information`);
    url.searchParams.append('active', 'true');

    const response = await axiosSingleton.get<FamilyMemberInformation[]>(url.toString(), {
      headers: {
        accept: 'application/json',
      },
    });

    return response.data;
  },
};
