import React, { useEffect, useState } from 'react';

interface WithLoadingProps {
  isLoading: boolean;
}

export const LoadingSkeletonWrapper = <T extends object>(
  Component: React.FC<T>,
  ComponentSkeleton: React.FC
): React.FC<T & WithLoadingProps> => {
  const Wrapper = ({ isLoading, ...props }: T & WithLoadingProps) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      setLoading(isLoading);
    }, [isLoading]);

    return <>{loading ? <ComponentSkeleton /> : <Component {...(props as T)} />}</>;
  };

  return Wrapper;
};
