import config from '../config';

export enum FeatureToggleKeys {
  Onboarding = 'ONBOARDING',
  OnboardingV2 = 'ONBOARDING_V2',
  WelcomePcpOnboarding = 'WELCOME_PCP_ONBOARDING',
  ClaimsCSVExport = 'CLAIMS_CSV_EXPORT',
  IdCardsPage = 'ID_CARDS_PAGE',
  Benefits = 'BENEFITS_V2',
  PreventiveCareReminder = 'PREVENTIVE_CARE_REMINDER',
  DisableProfileAndSetttings = 'DISABLE_PROFILE_SETTINGS',
  HidePreferredContactMethod = 'HIDE_PREFERRED_CONTACT_METHOD',
  InMomentSurvey = 'SHOW_INMOMENT_SURVEY',
  WellBeingReimbursement = 'WELLBEING_REIMBURSEMENT',
  Payments = 'PAYMENTS',
  SecureMessaging = 'SECURE_MESSAGING',
  DocumentCenter = 'DOCUMENT_CENTER',
  BeWellRewards = 'BE_WELL_REWARDS',
  EnableSecureMessagingDraft = 'ENABLE_SECURE_MESSAGING_DRAFT',
  WellBeingReimbursementBanner = 'FEATURE_ENABLE_BE_WELL_REWARDS_BANNER',
  ContactUsV2 = 'CONTACT_US_V2',
  PaymentsBanner = 'PAYMENTS_BANNER',
  WellBeingReimbursementV2 = 'WELLBEING_REIMBURSEMENT_V2',
  WellBeingReimbursementCardV2 = 'WELLBEING_REIMBURSEMENT_CARD_V2',
}

const features = Object.entries(config.FEATURE_TOGGLE)
  .filter(([_, value]) => value === 'true')
  .map(([key, _]) => key);

const isFeatureEnabled = (key: FeatureToggleKeys) => features.includes(key);

export default isFeatureEnabled;
