import React from 'react';
import { Control, Path, FieldValues, useController, FieldError } from 'react-hook-form';
import { DsSelectProps, DsSelect } from '@mvp/design-system';

interface SelectProps extends Omit<DsSelectProps, 'handleChange'> {
  handleChange?: (event: any) => void;
}

export interface ControlledSelectProps<T extends FieldValues> {
  name: Path<T>;
  control: Control<T>;
  selectProps: SelectProps;
  dataTestId?: string;
  helperText?: string;
  error?: FieldError;
  children?: React.ReactNode;
}

export function ControlledSelect<T extends FieldValues>({
  control,
  name,
  error,
  selectProps,
  dataTestId,
  helperText,
  children,
}: ControlledSelectProps<T>) {
  const {
    field: { ref, ...field },
    fieldState: { invalid },
  } = useController({ name, control });
  return (
    <DsSelect error={!!error} helperText={invalid || helperText} {...field} {...selectProps} data-testid={dataTestId}>
      {children}
    </DsSelect>
  );
}
