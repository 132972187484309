/** @jsxRuntime classic */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'url-polyfill/url-polyfill';
import { GlobalStoreProvider } from './storeProviders';
import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig, msalGhostUserConfig } from './authConfig';
import { routes } from './routesConfig';
import TagManager from 'react-gtm-module';
import config from './config';
import { AuthProvider, isGhostUser } from './components/AuthProvider';
import GhostSignout from './pages/GhostSignout';
import { CustomMuiTheme } from './components/CustomMuiTheme';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './appInsights';
import './i18n';
import { SignalRContextProvider } from './components/SignalRContextProvider';
import { SnackbarContextProvider } from './components/SnackbarContextProvider';
TagManager.initialize(config.TAG_MANAGER_ARGS);

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const ghostInstance = new PublicClientApplication(msalGhostUserConfig);
const instance = new PublicClientApplication(msalConfig);
const SigninBlockedPage = lazy(() => import('./pages/ErrorPages/SigninBlocked'));
const LogoutPage = lazy(() => import('./pages/SharedComponentsLogout/SharedComponentsLogout'));

ReactDOM.render(
  <AppInsightsContext.Provider value={reactPlugin}>
    <GlobalStoreProvider>
      <SignalRContextProvider>
        <BrowserRouter
          getUserConfirmation={() => {
            /* Empty callback to block the default browser prompt */
          }}
        >
          <CustomMuiTheme>
            <Switch>
              <Route path={routes.signinBlocked}>
                <Suspense fallback={<div></div>}>
                  <SigninBlockedPage />
                </Suspense>
              </Route>
              <Route path={routes.sharedLogout}>
                <Suspense fallback={<div></div>}>
                  <MsalProvider instance={isGhostUser() ? ghostInstance : instance}>
                    <LogoutPage />
                  </MsalProvider>
                </Suspense>
              </Route>
              <Route path="/ghost-signout">
                <MsalProvider instance={ghostInstance}>
                  <GhostSignout />
                </MsalProvider>
              </Route>
              <Route path="/ghost*">
                <MsalProvider instance={ghostInstance}>
                  <AuthProvider>
                    <App setIsGhostFlag={true} />
                  </AuthProvider>
                </MsalProvider>
              </Route>
              <Route path="*">
                <MsalProvider instance={isGhostUser() ? ghostInstance : instance}>
                  <AuthProvider>
                    <App setIsGhostFlag={isGhostUser()} />
                  </AuthProvider>
                </MsalProvider>
              </Route>
            </Switch>
          </CustomMuiTheme>
        </BrowserRouter>
      </SignalRContextProvider>
    </GlobalStoreProvider>
  </AppInsightsContext.Provider>,
  document.getElementById('root')
);
