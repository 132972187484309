export interface DeductiblesAndLimitsBalance {
  name: string;
  deductibleBalance: number;
  deductibleMetBalance: number;
  remainingBalance: number;
}

export type DeductibleOptions = Map<PlanYearCategory, PlanYear>;

interface AmountUsage {
  amount: number;
  amountMet: number;
  amountRemaining: number;
  percentageMet: number;
}

interface AccumulatorBase {
  accumulatorDescription: string;
  planStartDate: string;
  planEndDate: string;
  individualUsageList: IndividualUsage[];
  invalidFamilyAmount: boolean;
}

export interface AccumulatorResponse extends AmountUsage, AccumulatorBase {
  isCurrentPlanYear: boolean;
  percentageMet: number;
  percentageRemaining: number;
  embeddedOrAggregate: EmbeddedOrAggregate;
  showFamilyAggregator: boolean;
  isEmbedded: boolean;
  isValidDate: boolean;
}

export interface IndividualUsage extends AmountUsage {
  fullName: string;
  carryOver: number;
  invalidFamilyAmount: boolean;
  familyRole: string;
}

export enum UnitType {
  dollars = 'dollars',
  visits = 'visits',
}

export interface OtherLimitsAccumulatorResponse extends AccumulatorResponse {
  unitType: UnitType;
}

export interface DeductiblesAndLimitsResponse {
  coverageType: string;
  planName: string;
  planType: PlanType;
  isCurrentPlanYear: boolean;
  numberOfFamilyMembers: number;
  isSubscriberOnly: boolean;
  noCoverage: boolean;
  deductibleAccumulators: AccumulatorResponse[];
  otherLimitAccumulators: OtherLimitsAccumulatorResponse[];
  outOfPocketLimitAccumulators: AccumulatorResponse[];
}

export enum PlanYearCategory {
  currentPlanYear = 'Current Plan Year',
  previousPlanYear = 'Previous Plan Year',
}

export enum EmbeddedOrAggregate {
  embedded = 'Embedded',
  aggregate = 'Aggregate',
  both = 'Both',
}

export enum AccumulatorType {
  deductibles = 'Deductibles',
  outOfPocket = 'Out-of-Pocket',
}

export enum PlanType {
  Medical = 'Medical',
  Dental = 'Dental',
  Pharmacy = 'Pharmacy',
  Vision = 'Vision',
}

export interface PlanYear {
  Medical: DeductiblesAndLimitsResponse | undefined;
  Dental: DeductiblesAndLimitsResponse | undefined;
}

export const isMedical = (planType: PlanType): boolean => {
  return planType === PlanType.Medical;
};

export const isVision = (planType: PlanType): boolean => {
  return planType === PlanType.Vision;
};

export const isPharmacy = (planType: PlanType): boolean => {
  return planType === PlanType.Pharmacy;
};
