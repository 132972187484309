const SecureMessagingText = {
  table: {
    header: {
      messagesTitle: 'Messages',
      dateTitle: 'Date',
    },
    cell: {
      dateContent: {
        expiringMessagingFirst: 'Message',
        expiringMessagingSecond: 'Expiring',
      },
      message: {
        draft: 'DRAFT',
      },
    },
  },
  tabs: {
    options: {
      inbox: 'Inbox',
      drafts: 'Drafts',
      archive: 'Archive',
    },
  },
  snackbar: {
    success: 'Your message has been sent',
    error:
      'There was an error trying to send your message. Try again, or contact customer care at the number on the back of your Member ID card.',
    malware1: 'Your file was not accepted.',
    malware2: 'An issue was detected during the upload.',
    discard: 'Your message has been discarded',
    download: {
      started: 'Your download has started.',
      error: 'We were unable to process your download request. Try again or',
    },
    filter: {
      error: `We were unable to apply your filter selections. Try again later or `,
    },
  },
  newMessage: {
    title: 'New Message',
    inboxButton: 'Inbox',
    send: 'Send',
    discard: 'Discard',
    saveAsDraft: 'Save as Draft',
    form: {
      memberSelection: 'Member or Dependent',
      topicInput: 'Topic',
      messageInput: 'Message',
      requiredField: '*Required field',
    },
  },
  attachments: {
    viewAll: 'View All Attachments',
    downloadAll: 'Download All',
  },
  newMessageButton: 'New Message',
  title: 'Messages',
  reply: 'Reply',
  youHaveNo: 'You have no',
  inYour: 'in your',
  messages: 'messages',
  messageThreadAlerts: {
    threadExpiring: (daysLeft: number | string) =>
      `Messages in this conversation will expire in ${daysLeft} days. Please print it if you want to save this for your records.`,
    dependentAge: 'Due to state confidentiality laws, you can only view this message until your dependent turns 12 years old.',
    inactiveThread: 'This message is no longer active. Please start a new message if you want to continue the discussion.',
    responseTime:
      'Thank you for reaching out to MVP Customer Care. This auto reply is to let you know we received your message. We will email you once a response to your message is available. Please allow one business day for a response.',
  },
};

export default SecureMessagingText;
