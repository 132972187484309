import { EligibilityStatus, FamilyMemberInformation, MemberEnrollmentStatus } from '.';
import { ProductInfo, Status } from './member.model';

export enum FamilyRole {
  Subscriber = 'Subscriber',
  Spouse = 'Spouse',
  Dependent = 'Dependent',
}

export interface MemberProfileInformation {
  memberName: string;
  memberId: string;
  groupNumber: string;
  groupName: string;
  isChpHoh: boolean;
  originalEffectiveDate: string;
  coverageType: string;
  birthDate: string;
  gender: string;
  mainPlanName: string;
  memberProfilePlans?: ProductInfo[];
  // otherInsurances is obsolete, use CoordinationOfBenefits
  otherInsurances?: OtherInsurance[];
  CoordinationOfBenefits?: COB[];
  taxInfo: { [key: string]: TaxFormInfo[] };
  dependents: Dependent[];
  memberInformation: FamilyMemberInformation[];
  isGlobalFoundry: boolean;
  canManagePrescriptions: boolean;
  firstName: string;
  lastName: string;
  status: { name: MemberEnrollmentStatus; id: MemberEnrollmentStatus };
  familyRole: FamilyRole;
  eligibilityStatus: EligibilityStatus;
  isFutureEffectiveOrActive: boolean;
}

export interface Dependent {
  firstName: string;
  lastName: string;
  familyRole: FamilyRole;
  memberProfilePlans: ProductInfo[];
  status: Status;
  memberId: string;
  isTwelvePlus: boolean;
  birthDate: string;
}

export interface TaxFormInfo {
  pdfByteArray: string;
  groupName: string;
  groupId: string;
  payorId: string;
  formNumber: string;
}

export interface OtherInsurance {
  carrierName: string;
  effectiveDt: string;
  effectiveDtSpecified: boolean;
  orderApplied: string;
  type: string;
}

export interface COB {
  carrierName: string;
  effectiveDate: string;
  effectiveDtSpecified: boolean;
  orderApplied: string;
  type: string;
}

export interface PlansAndBenefits {
  planName: string;
  startDate: Date;
  pcpRequired?: string;
  effectiveDate: Date;
  planStatus: PlanStatus;
  endDate: Date;
  eligibilityDate: Date;
  productCatDesc: string;
  productCat: string;
}

/**
 * @deprecated The method should not be used, use @EligibilityStatus instead
 */
export enum PlanStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  PendingActivation = 'Pending Activation',
  PendingRenewal = 'Pending Renewal',
  PendingTermination = 'Pending Termination',
}

export const isActive = (status: EligibilityStatus) => {
  return status === EligibilityStatus.Active;
};

export const isMemberPendingActivation = (status: EligibilityStatus) => {
  return status === EligibilityStatus.PendingActivation;
};

export const isActiveOrPendingActivation = (status: EligibilityStatus) => {
  return isActive(status) || status === EligibilityStatus.PendingActivation;
};
