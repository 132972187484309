import React from 'react';
import { getQueryParam } from '../../utils/queryParam';
import { useNavigationItems } from '../../hooks/useNavigationItems';
import { sharedUrls } from '../../sharedUrls';
import { LoadingContainer } from '../../components';
import { handlePostLogin } from '../../components/AuthProvider';
import { useMsal } from '@azure/msal-react';

export const Redirect: React.FC = () => {
  const { instance } = useMsal();
  const { categorizedLinks } = useNavigationItems(true);
  const redirectUrl = getQueryParam('url');
  const claims = instance.getActiveAccount()?.idTokenClaims;
  handlePostLogin(`${claims?.FIRST_NAME} ${claims?.LAST_NAME}`);
  window.location.href = categorizedLinks.length > 0 && redirectUrl !== null ? redirectUrl : sharedUrls.portalUrl;

  return <LoadingContainer isLoading={true} loadingText={'Redirecting'} />;
};
