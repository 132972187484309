import React from 'react';
import { Helmet } from 'react-helmet';

export const InMomentFeedbackWidget = () => {
  return (
    <Helmet>
      <script type="text/javascript" id="inmoment-intercept" async>
        {(function () {
          var interceptFn = function (interceptHost: any, orgId: any, key: any, isTesting: any) {
            if (typeof IMI === 'object') return;

            var url = '//' + interceptHost + '/intercept/v1/init/' + orgId + '/' + key + '?testing=' + isTesting;

            this.go = function () {
              var scriptEl = document.createElement('script');
              scriptEl.type = 'text/javascript';
              scriptEl.async = !0;
              scriptEl.src = url;
              if (document.body) {
                document.body.appendChild(scriptEl);
              }
            };

            this.start = function () {
              if (navigator.userAgent.indexOf('MSIE') >= 0 && navigator.userAgent.indexOf('MSIE 11') < 0) {
                return;
              }

              var _interceptFn = this;
              if (!isTesting) {
                if (document.readyState !== 'loading') _interceptFn.go();
                else if (window.addEventListener)
                  window.addEventListener(
                    'DOMContentLoaded',
                    function () {
                      _interceptFn.go();
                    },
                    !1
                  );
                else if (window.attachEvent)
                  window.attachEvent('onload', function () {
                    _interceptFn.go();
                  });
              } else {
                _interceptFn.go();
              }
            };
          };
          try {
            new interceptFn('dispawsusva.inmoment.com', 4281, 'zb0erSp3vcl8Db7ZpaeB6w==', !1).start();
          } catch (i) {}
        })()}
      </script>
    </Helmet>
  );
};
