import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { DsIcon, size } from '@mvp/design-system';
import { ESupportTextLink } from './ESuppportTextLink';
import { PhoneLine } from './PhoneLine';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    borderStyle: 'solid',
    borderRadius: '.375rem',
    borderColor: theme.palette.grey[100],
    borderWidth: '0.0625rem',
  },
  icon: {
    fontSize: theme.spacing(8),
    color: 'rgba(0,0,0,0.6)',
  },
  iconContainer: {
    width: '10%',
    borderRadius: '.375rem 0 0 .375rem',
    backgroundColor: theme.palette.grey[50],
    borderRightColor: theme.palette.grey[100],
    borderRight: '0.0625rem',
    borderRightStyle: 'solid',
    minWidth: '4rem',
  },
  messageContainer: {
    padding: size.s(theme),
    width: '90%',
  },
  boldText: {
    display: 'inline',
    fontWeight: 800,
    fontSize: '1.25rem',
  },
  cardStyles: {
    padding: 0,
    margin: 0,
  },
}));

export const StandardErrorCard: React.FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.rootContainer} width="100%" display="flex" alignItems="stretch">
      <Box className={classes.iconContainer} display="flex" justifyContent="center" alignItems="center">
        <DsIcon icon="exclamation-circle" className={classes.icon} title="exclamation-circle" />
      </Box>
      <Box className={classes.messageContainer}>
        <Typography variant="body2" display="inline">
          {"Hmm, we can't access that information right now. Please refresh or try again later. If you still have trouble, "}
        </Typography>
        <ESupportTextLink linkText="contact eSupport" variant="body2" openInNewTab={false} />
        <Typography variant="body2" display="inline">
          {' or call '}
        </Typography>
        <PhoneLine variant="body2" phoneNumber="1-888-656-5695" />.
      </Box>
    </Box>
  );
};
