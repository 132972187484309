import axiosSingleton from '../components/axios';
import config from '../config';
import { GetPaymentHistoryResult } from '../models/api/models';
import { History } from '../models/payments/payments.model';
import {
  GetAutoPayTokenResult,
  GetInvoiceUrlResult,
  GetPaymentAndBillingHistoryResult,
  GetPaymentCenterResult,
  GetPaymentSSOTokensResult,
  GetPaymentStatementResponse,
  GetPaymentStatementsResult,
  GetPaymentTokenResult,
} from '../models/payments/paymentsProvider.model';
import { combinePaymentAndHistoryArrays } from '../pages/Payments/utils/paymentUtils';
import { formatDate } from '../utils/dates';

export const paymentsProvider = {
  async getPaymentStatements(): Promise<GetPaymentStatementResponse> {
    const response = await axiosSingleton.get<GetPaymentStatementsResult>(`${config.API_URL}/v3/payment/statements`);
    return response.data.data;
  },

  async getPaymentHistory(paymentIdentifier: string): Promise<GetPaymentHistoryResult> {
    const response = await axiosSingleton.get<GetPaymentHistoryResult>(
      `${config.API_URL}/v1/payment/payment-history?identifier=${paymentIdentifier}&count=5&countType=mostRecent`
    );
    return response.data;
  },

  async getPaymentAndBillingHistory(paymentIdentifier?: string): Promise<History[]> {
    const response = await axiosSingleton.get<GetPaymentAndBillingHistoryResult>(
      `${config.API_URL}/v1/payment/balance-history?identifier=${paymentIdentifier}&count=18&countType=month`
    );
    return combinePaymentAndHistoryArrays(response.data.data.paymentHistory, response.data.data.billingInvoiceHistory);
  },

  async getInvoiceUrl(paymentIdentifier?: string, statementDate?: string, invoiceNumber?: string): Promise<GetInvoiceUrlResult> {
    const response = await axiosSingleton.get<GetInvoiceUrlResult>(
      `${config.API_URL}/v1/payment/invoice?paymentIdentifier=${paymentIdentifier}&statementDate=${statementDate}&invoiceNumber=${invoiceNumber}&paymentOutletType=Subscriber`
    );
    return response.data;
  },

  async getPaymentSSOTokens(
    paymentIdentifier: string,
    statementDate: string,
    paymentOutletType: string
  ): Promise<GetPaymentSSOTokensResult> {
    const response = await axiosSingleton.get<GetPaymentSSOTokensResult>(
      `${config.API_URL}/v1/payment/paymentSSOTokens?paymentIdentifier=${paymentIdentifier}&statementDate=${formatDate(
        statementDate
      )}&paymentOutletType=${paymentOutletType}`
    );
    return response.data;
  },

  async getPaymentToken(
    paymentAmount: string,
    paymentIdentifier: string,
    statementDate: string,
    invoiceNumber: string,
    paymentOutletType: string,
    clientId: string
  ): Promise<GetPaymentTokenResult> {
    const response = await axiosSingleton.get<GetPaymentTokenResult>(
      `${config.API_URL}/v1/payment/token?paymentAmount=${paymentAmount}&paymentIdentifier=${paymentIdentifier}&statementDate=${statementDate}&invoiceNumber=${invoiceNumber}&paymentOutletType=${paymentOutletType}&clientId=${clientId}`
    );
    return response.data;
  },

  async getAutoPayToken(paymentIdentifier: string, paymentOutletType: string): Promise<GetAutoPayTokenResult> {
    const response = await axiosSingleton.get<GetAutoPayTokenResult>(
      `${config.API_URL}/v1/autopay/token?paymentIdentifier=${paymentIdentifier}&paymentOutletType=${paymentOutletType}`
    );
    return response.data;
  },

  async getPaymentCenterToken(): Promise<string> {
    const response = await axiosSingleton.get<GetPaymentCenterResult>(`${config.API_URL}/v1/payment/center/token`);
    return response.data.data.token;
  },
};
