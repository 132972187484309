import { useMsal, useMsalAuthentication, useIsAuthenticated, AuthenticatedTemplate, AccountIdentifiers } from '@azure/msal-react';
import React, { FC, useEffect, useState } from 'react';
import axiosSingleton, { addBearerTokenToAxios } from './axios';
import { queryClient } from '../ReactQueryClientProvider';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionRequiredAuthError,
  InteractionStatus,
  InteractionType,
} from '@azure/msal-browser';
import { loginRequest, userNameKey, createTokenRequest, appInitialLoginSuccessFlag } from '../authConfig';
import { selfServiceSessionProvider } from '../api/selfServiceSessionProvider';
import { lastInteractionTimerKey } from '../utils/sessionTimeout';
import { StorageLocation, StorageProvider } from '../utils/storage';
import { LoadingContainer } from './LoadingContainer';
import { StandardErrorCard } from './StandardErrorCard';
import { useAppInsightsContext, useTrackEvent } from '@microsoft/applicationinsights-react-js';
import { InsightsEventType } from '../appInsights';

export const hasLoggedIn = () => {
  return StorageProvider.getItem(StorageLocation.localStorage, appInitialLoginSuccessFlag) !== null;
};

export const handlePostLogout = async () => {
  try {
    (window as any).heap?.clearEventProperties();
    (window as any).heap?.resetIdentity();
    (window as any).Genesys?.('command', 'Messenger.close');
    (window as any).Genesys?.('command', 'MessagingService.clearConversation');
    StorageProvider.clear(StorageLocation.localStorage);
    StorageProvider.clear(StorageLocation.sessionStorage);
    document.cookie = 'isGhostAdmin=false';
    queryClient.clear();
    await selfServiceSessionProvider.clearSession();
  } catch {}
};

export const handlePostLogin = (userName: string, interactionTimer: string = Date.now().toString()) => {
  StorageProvider.setItem(StorageLocation.localStorage, appInitialLoginSuccessFlag, `${Math.random()}`); // set flag that react app has logged-in
  StorageProvider.setItem(StorageLocation.localStorage, lastInteractionTimerKey, interactionTimer); // set last user interaction timestamp
  StorageProvider.setItem(StorageLocation.localStorage, userNameKey, userName); // set user's name for header
};

export const isGhostUser = () => document.cookie.indexOf('isGhostAdmin=true') !== -1;

export const setIsGhostAdminInCookie = (isGhostAdmin: boolean) => {
  document.cookie = `isGhostAdmin=${isGhostAdmin}`;
};

// can remove when there are no more mvc pages
export const getUsersName = () => {
  return StorageProvider.getItem(StorageLocation.localStorage, userNameKey) || '';
};

export const AuthProvider: FC = ({ children }) => {
  const LOCAL_ACCOUNT_ID = '_mvp_local_acc_id';
  const HOME_ACCOUNT_ID = '_mvp_home_acc_id';
  const [tokenAcquired, setTokenAcquired] = useState(false);
  const accountIdentifiers: AccountIdentifiers = {
    localAccountId: StorageProvider.getItem(StorageLocation.localStorage, LOCAL_ACCOUNT_ID) ?? undefined,
    homeAccountId: StorageProvider.getItem(StorageLocation.localStorage, HOME_ACCOUNT_ID) ?? undefined,
  };

  const appInsights = useAppInsightsContext();
  const { instance, accounts, inProgress } = useMsal();
  const { login, error } = useMsalAuthentication(InteractionType.Silent, loginRequest, accountIdentifiers);

  const isAuthenticated = useIsAuthenticated();
  const trackLogoutError = useTrackEvent(appInsights, InsightsEventType.LoginError, error);

  useEffect(() => {
    if (error instanceof InteractionRequiredAuthError) {
      login(InteractionType.Redirect, loginRequest);
    } else {
      trackLogoutError(error);
    }
  }, [error, login, trackLogoutError]);

  useEffect(() => {
    if (!tokenAcquired && inProgress === InteractionStatus.None && accounts.length > 0) {
      if (
        axiosSingleton.defaults.headers !== undefined &&
        axiosSingleton.defaults.headers['Authorization'] &&
        axiosSingleton.defaults.headers['Authorization'].length > 0
      ) {
        // Skip data refresh if already set - adjust logic for your specific use case
        return;
      }

      const tokenRequest = createTokenRequest(accounts[0]);
      // Acquire an access token
      instance
        .acquireTokenSilent(tokenRequest)
        .then((response) => {
          // Call your API with the access token and return the data you need to save in state
          addBearerTokenToAxios(response.accessToken);
          setTokenAcquired(true);
        })
        .catch(async (e) => {
          // Catch interaction_required errors and call interactive method to resolve
          if (e instanceof InteractionRequiredAuthError) {
            await instance.acquireTokenRedirect(tokenRequest);
          } else {
            trackLogoutError(e);
            throw e;
          }
        });
    }
  }, [inProgress, accounts, instance, tokenAcquired, appInsights, trackLogoutError]);

  //msal events handler
  useEffect(() => {
    // This will be run on component mount
    const handlePostLogoutAsync = async () => {
      await handlePostLogout();
    };

    //need to call the enableAccountStorageEvents API to enable Syncing logged in state across tabs and windows
    instance?.enableAccountStorageEvents();
    const callbackId = instance.addEventCallback((event: EventMessage) => {
      switch (event.eventType) {
        case EventType.LOGOUT_SUCCESS:
          setTokenAcquired(false);
          handlePostLogoutAsync();
          break;
        case EventType.ACCOUNT_REMOVED:
          StorageProvider.removeItem(StorageLocation.localStorage, appInitialLoginSuccessFlag);
          setTokenAcquired(false);
          break;
        case EventType.LOGIN_START:
        case EventType.LOGOUT_START:
          setTokenAcquired(false);
          break;
        // Adding the acquire token success case was a result of 487052. After digging through logs on successful logins, and those that result in a white screen,
        // I found those that white screen have an ACQUIRE_TOKEN_SUCCESS event as the last event in the MSAL event callback. The event payload is the same as the Login_Sucess event.
        // Looking at the docs , ACQUIRE_TOKEN_SUCCESS is called when a token is acquired, either from cache or network.
        case EventType.LOGIN_SUCCESS:
        case EventType.ACQUIRE_TOKEN_SUCCESS:
          if (event.payload) {
            const payload = event.payload as AuthenticationResult;
            const claims = payload.account?.idTokenClaims;

            if (!!payload.account) {
              instance.setActiveAccount(payload.account);
              StorageProvider.setItem(StorageLocation.localStorage, HOME_ACCOUNT_ID, payload.account.homeAccountId);
              StorageProvider.setItem(StorageLocation.localStorage, LOCAL_ACCOUNT_ID, payload.account.localAccountId);
            }

            setIsGhostAdminInCookie(claims?.ghostadmin === 'true');
            handlePostLogin(`${claims?.FIRST_NAME} ${claims?.LAST_NAME}`);
          }
          break;

        default:
      }
    });

    return () => {
      // This will be run on component unmount
      instance.disableAccountStorageEvents();
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance, tokenAcquired]);

  if (isAuthenticated && tokenAcquired && hasLoggedIn()) {
    return <AuthenticatedTemplate>{children}</AuthenticatedTemplate>;
  }

  if (error && inProgress === InteractionStatus.None) {
    return <StandardErrorCard />;
  }

  if (inProgress !== InteractionStatus.None) {
    return <LoadingContainer isLoading={true} />;
  }

  return null;
};
