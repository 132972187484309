import { Grid, makeStyles } from '@material-ui/core';
import { DsDivider, DsPrimaryLoadingButton, size } from '@mvp/design-system';
import React, { useState } from 'react';
import { CommunicationPreferencesAddress } from '../../../../models/communicationPreferences.model';
import { ErrorMessage } from '../../../../models/errorMessage.model';
import { getHeapIdent, HeapLocation, Target } from '../../../../models/heapIdentifiers.model';
import { AddressForm, FormProps, PhoneForm } from '../../../../models/updateUserInfo.model';
import ProfileAndSettingsText from '../../ProfileAndSettingsText';
import { UserAddressAccordion } from './UserAddressAccordion';

interface PhoneErrors {
  homeErrorMessage: ErrorMessage | null;
  mailingErrorMessage: ErrorMessage | null;
}

interface Addresses {
  home: CommunicationPreferencesAddress;
  mailing: CommunicationPreferencesAddress;
}

interface PhoneNumbers {
  home: string;
  mailing: string;
}

export interface FormPropsGrouped {
  address: FormProps<AddressForm>;
  phone: FormProps<PhoneForm>;
}
interface ModifyAddressAndPhoneProps {
  addresses: Addresses;
  phoneNumbers: PhoneNumbers;
  phoneErrorMessages: PhoneErrors;
  formProps: FormPropsGrouped;
  handleOnSave: () => void;
  disableButton: boolean;
  setMailAddressToHome: () => void;
  onFormChange: (e: React.FormEvent<HTMLFormElement>) => void;
  addressesAreSame: boolean;
  setAddressesAreSame: React.Dispatch<React.SetStateAction<boolean>>;
  onStateSelectChange: () => void;
  isLoading?: boolean;
  disableUpdates?: boolean;
}

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: '100%',
  },
  accordionContainer: {
    margin: `${size.m(theme)} 0`,
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      marginTop: '-0.063rem',
      marginLeft: `-${size.m(theme)}`,
    },
  },
  mailingAddressContainer: {
    marginTop: size.m(theme),
    [theme.breakpoints.down('xs')]: {
      marginTop: '-0.063rem',
    },
  },
  saveChangesButton: {
    marginBottom: size.m(theme),
  },
  divider: {
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      marginLeft: `-${size.m(theme)}`,
    },
  },
}));

export const ModifyAddressAndPhone: React.FC<ModifyAddressAndPhoneProps> = (props) => {
  const {
    addresses,
    phoneNumbers,
    phoneErrorMessages,
    formProps,
    handleOnSave,
    disableButton,
    setMailAddressToHome,
    onFormChange,
    addressesAreSame,
    setAddressesAreSame,
    onStateSelectChange,
    disableUpdates,
    isLoading = false,
  } = props;

  const [expandMailingAccordion, setExpandMailingAccordion] = useState(!addressesAreSame);
  const [expandHomeAccordion, setExpandHomeAccordion] = useState(true);
  const classes = useStyles();

  const homeAddressAccordionId = getHeapIdent(HeapLocation.acctprefs, Target.homeAddressAccordion);
  const mailingAddressAccordionId = getHeapIdent(HeapLocation.acctprefs, Target.mailingAddressAccordion);
  const mailingCheckbox = getHeapIdent(HeapLocation.acctprefs, Target.sameAsHomeAddressCheckbox);
  const saveButtonId = getHeapIdent(HeapLocation.acctprefs, Target.modifyAddressSaveButton);

  const onMailAddressCheckBoxClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, value: boolean) => {
    event.stopPropagation();
    setAddressesAreSame(value);
    if (value) {
      setMailAddressToHome();
    }
    setExpandMailingAccordion(!value);
  };

  return (
    <form className={classes.fullWidth} onChange={(e) => onFormChange(e)}>
      <Grid className={`${classes.accordionContainer} ${classes.fullWidth}`}>
        <Grid className={classes.fullWidth}>
          <UserAddressAccordion
            expandMoreIconId="homedropdown"
            title={ProfileAndSettingsText.contactInformation.updateAddress.homeAddressText}
            userInfo={{
              address: addresses.home,
              phone: phoneNumbers.home,
            }}
            address={addresses.home}
            formProps={formProps}
            onStateSelectChange={onStateSelectChange}
            ids={{
              accordion: homeAddressAccordionId,
            }}
            manuallyExpand={expandHomeAccordion}
            setManuallyExpand={setExpandHomeAccordion}
            phoneErrorMessage={phoneErrorMessages.homeErrorMessage}
            isDisabled={disableUpdates}
          />
        </Grid>
        <Grid className={`${classes.fullWidth} ${classes.mailingAddressContainer}`}>
          <UserAddressAccordion
            expandMoreIconId="mailingdropdown"
            title={ProfileAndSettingsText.contactInformation.updateAddress.mailingAddressText}
            userInfo={{
              address: addresses.mailing,
              phone: phoneNumbers.mailing,
            }}
            address={addresses.mailing}
            formProps={formProps}
            showCheckBox
            checkBoxValue={addressesAreSame}
            onCheckBoxChange={onMailAddressCheckBoxClick}
            manuallyExpand={expandMailingAccordion}
            setManuallyExpand={setExpandMailingAccordion}
            isDisabled={addressesAreSame || disableUpdates}
            onStateSelectChange={onStateSelectChange}
            ids={{
              accordion: mailingAddressAccordionId,
              checkbox: mailingCheckbox,
            }}
            phoneErrorMessage={phoneErrorMessages.mailingErrorMessage}
          />
        </Grid>
      </Grid>
      <DsPrimaryLoadingButton
        id={saveButtonId}
        actionInProgress={isLoading}
        actionText={ProfileAndSettingsText.contactInformation.submittingText}
        text={ProfileAndSettingsText.contactInformation.saveText}
        className={classes.saveChangesButton}
        onClick={handleOnSave}
        disabled={disableButton || disableUpdates}
      />
      <Grid item xs={12}>
        <DsDivider className={classes.divider} />
      </Grid>
    </form>
  );
};
