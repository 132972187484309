import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import { size } from '@mvp/design-system';
import Close from '@material-ui/icons/Close';
import { getHeapIdent, HeapLocation, Target } from '../../../models/heapIdentifiers.model';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    top: `${size.m(theme)}`,
    right: `${size.m(theme)}`,
    backgroundColor: theme.palette.text.disabled,
    color: 'white',
    padding: '2px',
    '&:hover': {
      backgroundColor: theme.palette.text.disabled,
    },
  },
  container: {
    position: 'relative',
    height: '100%',
    maxWidth: '100%',
  },
}));

const CloseButtonOverlay: React.FC<{ onClick: () => void }> = (props) => {
  const classes = useStyles();
  const closeButtonId = getHeapIdent(HeapLocation.onboarding_modal, Target.closebutton);

  return (
    <div className={classes.container}>
      <IconButton
        onClick={props.onClick}
        className={classes.closeButton}
        id={closeButtonId}
        aria-label="modal-close"
        aria-controls={closeButtonId}
      >
        <Close />
      </IconButton>
      {props.children}
    </div>
  );
};

export default CloseButtonOverlay;
