export enum StorageLocation {
  localStorage = 'localStorage',
  sessionStorage = 'sessionStorage',
}

export enum StorageKey {
  onboardingTasks = 'onboardingTasks',
  hasSeenOnboarding = 'hasSeenOnboarding',
  recertModalViewFlag = 'recertModalViewFlag',
}

export const StorageProvider = {
  setItem(location: StorageLocation, name: string, value: string): void {
    switch (location) {
      case StorageLocation.localStorage:
        try {
          localStorage.setItem(name, value);
        } catch (e) {}
        break;
      default:
        try {
          sessionStorage.setItem(name, value);
        } catch (e) {}
        break;
    }
  },

  getItem(location: StorageLocation, name: string): string | null {
    switch (location) {
      case StorageLocation.localStorage:
        try {
          return localStorage.getItem(name);
        } catch (e) {
          return null;
        }
      default:
        try {
          return sessionStorage.getItem(name);
        } catch (e) {
          return null;
        }
    }
  },

  removeItem(location: StorageLocation, name: string): void {
    switch (location) {
      case StorageLocation.localStorage:
        try {
          localStorage.removeItem(name);
        } catch (e) {}
        break;
      default:
        try {
          sessionStorage.removeItem(name);
        } catch (e) {}
        break;
    }
  },

  clear(location: StorageLocation): void {
    switch (location) {
      case StorageLocation.localStorage:
        try {
          localStorage.clear();
        } catch (e) {}
        break;
      default:
        try {
          sessionStorage.clear();
        } catch (e) {}
        break;
    }
  },

  isStorageEnabled(): boolean {
    try {
      localStorage.setItem('mvp', 'mvp');
      localStorage.removeItem('mvp');
      return true;
    } catch (e) {
      return false;
    }
  },
};
