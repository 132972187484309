export const OnboardingText = {
  submitting: 'Submitting...',
  continueText: 'Continue',
  cancelText: 'Cancel',
  esupportText: 'eSupport',
  saveChangesText: 'Save Changes',
  successSnackbarText: 'Success! Your changes were saved.',
  commPref: {
    title: 'Update Contact Information',
    subtitle: 'Lorem ipsum this is your contact information for your MVP account.',
    updateEmail: {
      title: 'Update Email Address',
      subtitle:
        'What email address would you like important communications sent to? Note that changing this email address will not change the address you use to sign into your account.',
      userEmailTitle: 'Current Email Address',
    },
  },
};
