import React, { useEffect, useState } from 'react';
import { useMediaQuery, useTheme, makeStyles, Dialog, Grid, IconButton, DialogTitle } from '@material-ui/core';
import lgFortImg from '../../assets/img/onboarding/OnboardingImgs-Welcome-3-LG.png';
import { DsImage, DsPillStepper, size } from '@mvp/design-system';
import { CloseButtonOverlay } from './components';
import mvpLogo from '../../svgs/mvp-logo.svg';
import Close from '@material-ui/icons/Close';
import { Target, HeapLocation, getHeapIdent } from '../../models/heapIdentifiers.model';
import { useHeap } from '../../hooks/useHeap';
import { ImageSizes, OnboardingData, onboardingImages, showStepForTask, TaskStep } from '../../models';
import StepSelector from './steps/StepSelector';
import isFeatureEnabled, { FeatureToggleKeys } from '../../hooks/useFeature';
import { useModalSaveStatus } from './steps/useModalSaveStatus';
import { StorageKey, StorageLocation, StorageProvider } from '../../utils/storage';
import { invalidateQuery, QueryKey } from '../../ReactQueryClientProvider';

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    [theme.breakpoints.down('sm')]: {
      maxHeight: '15rem',
      padding: `0 ${size.m(theme)}`,
    },
  },
  paper: {
    minHeight: '70vh',
    flexDirection: 'unset',
  },
  dialog: {
    overflowY: 'unset',
    overflowX: 'unset',
  },
  imageSizing: {
    [theme.breakpoints.up('md')]: {
      borderRadius: `0 ${size.xxxs(theme)} ${size.xxxs(theme)} 0`,
      objectPosition: '85% 50% ',
      objectFit: 'cover',
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      borderRadius: '1rem',
      objectFit: 'cover',
      width: '100%',
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
  contentGrid: {
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
    },
  },
  primaryGrid: {
    [theme.breakpoints.up('sm')]: {
      padding: `${size.l(theme)} 5rem ${size.l(theme)} ${size.xl(theme)}`,
    },
    [theme.breakpoints.down('sm')]: {
      padding: `0 ${size.l(theme)} ${size.s(theme)} ${size.l(theme)}`,
    },
  },
  contentContainer: {
    [theme.breakpoints.up('sm')]: {
      marginTop: '2.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: size.s(theme),
    },
    height: '100%',
  },
  logo: {
    width: '7.375rem',
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      width: '5.4375rem',
    },
  },
  pillContainer: {
    margin: `0 ${size.m(theme)} ${size.s(theme)} ${size.m(theme)}`,
  },
}));

const OnboardingModal: React.FC<{ onboardingData: OnboardingData | undefined }> = ({ onboardingData }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const onlyShowWelcomePcp = isFeatureEnabled(FeatureToggleKeys.WelcomePcpOnboarding);
  const isOnBoardingV2 = isFeatureEnabled(FeatureToggleKeys.OnboardingV2);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<TaskStep>(TaskStep.Welcome);
  const [currentImage, setCurrentImage] = useState<string>(lgFortImg);
  const [showStepper, setShowStepper] = useState<boolean>(true);

  const backdropId = getHeapIdent(HeapLocation.onboarding_modal, Target.backdrop);
  const closeButtonId = getHeapIdent(HeapLocation.onboarding_modal, Target.closebutton);

  const { context: UseModalSaveStatusContext, data: useModalSaveStatusData } = useModalSaveStatus();

  const goBack = () => {
    if (currentStep === TaskStep.Select_PCP && !isOnBoardingV2) {
      setCurrentStep(TaskStep.Welcome);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };
  const advanceStep = () => {
    if (currentStep === TaskStep.Welcome && !isOnBoardingV2) {
      setCurrentStep(TaskStep.Select_PCP);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };
  const goToStep = (step: TaskStep) => {
    setCurrentStep(step);
  };
  const heap = useHeap();

  const setSideImage = () => {
    if (isMobile) {
      setCurrentImage(onboardingImages[currentStep][ImageSizes.SM]);
    } else {
      setCurrentImage(onboardingImages[currentStep][ImageSizes.LG]);
    }
  };

  useEffect(() => {
    if (onboardingData) {
      setShowModal(onboardingData.shouldViewOnboarding);
      if (!!onboardingData.shouldViewOnboarding) {
        heap.trackOnboardingWelcome();
      }
    }
  }, [onboardingData]);

  useEffect(() => {
    setSideImage();
  }, [isMobile]);

  useEffect(() => {
    setSideImage();
    setShowStepper(showStepForTask[currentStep]);
  }, [currentStep]);

  const closeModal = () => {
    setShowModal(false);
    invalidateQuery(QueryKey.Onboarding);
  };

  return onboardingData?.shouldViewOnboarding ? (
    <Dialog
      open={showModal}
      maxWidth="lg"
      BackdropProps={{ id: backdropId }}
      classes={!isMobile ? { paper: classes.paper } : undefined}
      className={isMobile ? classes.dialog : undefined}
      onClose={closeModal}
      scroll="paper"
      fullScreen={isMobile}
      fullWidth
    >
      {isMobile && (
        <DialogTitle style={{ paddingRight: '.75rem' }}>
          <Grid container justify="space-between">
            <DsImage src={mvpLogo} alt="MVP mobile Logo" id="mvp-logo" className={classes.logo} />
            <IconButton aria-label="modal-close" aria-controls={closeButtonId} id={closeButtonId} onClick={closeModal}>
              <Close />
            </IconButton>
          </Grid>
        </DialogTitle>
      )}
      <Grid container className={classes.contentGrid} direction={isMobile ? 'column-reverse' : 'row'} wrap="nowrap">
        <Grid md={8} xs={12} className={classes.primaryGrid} wrap="nowrap" direction="column" item container>
          {!isMobile && !onlyShowWelcomePcp && showStepper && (
            <Grid item>
              <DsPillStepper totalSteps={onboardingData.tasks.length + 1} currentStep={currentStep} />
            </Grid>
          )}
          <Grid item className={classes.contentContainer} direction="column" wrap="nowrap" container>
            <UseModalSaveStatusContext.Provider value={useModalSaveStatusData}>
              <StepSelector
                step={currentStep}
                advanceStep={advanceStep}
                goBack={goBack}
                closeModal={closeModal}
                onlyShowWelcomePcp={onlyShowWelcomePcp}
                goToStep={goToStep}
              />
            </UseModalSaveStatusContext.Provider>
          </Grid>
        </Grid>
        <Grid md={4} xs={12} item container className={classes.imageContainer}>
          <img className={classes.imageSizing} src={currentImage} alt="Onboarding screen" />
          {!isMobile && <CloseButtonOverlay onClick={closeModal} />}
        </Grid>
        {isMobile && !onlyShowWelcomePcp && showStepper && (
          <Grid item className={classes.pillContainer}>
            <DsPillStepper totalSteps={onboardingData.tasks.length + 1} currentStep={currentStep} />
          </Grid>
        )}
      </Grid>
    </Dialog>
  ) : (
    <></>
  );
};

export default OnboardingModal;
