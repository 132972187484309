import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { modifyAddressSchema } from '../../pages/CommunicationPreferences/components/address/ModifyAddressSchema';
import { convertToSchema } from '../../pages/CommunicationPreferences/utils/updateUserAddress';
import { useForm, useFormState, useWatch } from 'react-hook-form';
import { AddressForm, FormProps } from '../../models/updateUserInfo.model';
import { CommunicationPreferencesAddress } from '../../models/communicationPreferences.model';

interface AddressInfo {
  address: CommunicationPreferencesAddress;
}

export const useUpdateAddress = (homeAddressInfo: AddressInfo, mailingAddressInfo: AddressInfo) => {
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [addressesAreSame, setAddressesAreSame] = useState<boolean>(
    JSON.stringify(homeAddressInfo.address) === JSON.stringify(mailingAddressInfo.address)
  );

  const { control, setValue, watch, reset, getValues, clearErrors, getFieldState } = useForm<AddressForm>({
    resolver: yupResolver(modifyAddressSchema),
    mode: 'onChange',
    defaultValues: {
      homeAddress: convertToSchema(homeAddressInfo.address),
      mailingAddress: convertToSchema(mailingAddressInfo.address),
    },
  });

  const { isDirty, errors, touchedFields } = useFormState({ control });
  const formProps: FormProps<AddressForm> = { control, watch, setValue };

  const watcher = useWatch({ control });

  const homeAddressState = getFieldState('homeAddress');
  const mailingAddressState = getFieldState('mailingAddress');

  useEffect(() => {
    if (homeAddressState.error || mailingAddressState.error || errors.homeAddress || errors.mailingAddress) {
      setDisableButton(true);
    } else {
      const initalHomeAddress = convertToSchema(homeAddressInfo.address);
      const initalMailingAddress = convertToSchema(mailingAddressInfo.address);
      const homeAddressHasBeenModified = JSON.stringify(initalHomeAddress) !== JSON.stringify(watcher.homeAddress);
      const mailingAddressHasBeenModified = JSON.stringify(initalMailingAddress) !== JSON.stringify(watcher.mailingAddress);

      if (isDirty) {
        setDisableButton(false);
      } else {
        setDisableButton(!homeAddressHasBeenModified && !mailingAddressHasBeenModified);
      }
    }
  }, [homeAddressState, mailingAddressState, isDirty, errors, watcher.homeAddress, watcher.mailingAddress]);

  useEffect(() => {
    const homeAddress = convertToSchema(homeAddressInfo.address);
    const mailingAddress = convertToSchema(mailingAddressInfo.address);

    reset({
      homeAddress,
      mailingAddress,
    });
    setAddressesAreSame(JSON.stringify(homeAddress) === JSON.stringify(mailingAddress));
  }, [homeAddressInfo.address, mailingAddressInfo.address]);

  const setMailAddressToHome = () => {
    const currentHomeAddressState = getValues('homeAddress');
    setValue('mailingAddress', currentHomeAddressState);
    clearErrors('mailingAddress');
  };

  const onFormChange = () => {
    if (addressesAreSame) {
      setMailAddressToHome();
    }
  };

  const getAddresses = () => {
    return getValues();
  };

  return {
    onFormChange,
    getAddresses,
    setAddressesAreSame,
    reset,
    addressesAreSame,
    formProps,
    setMailAddressToHome,
    disableButton,
    touchedFields,
    homeAddressStateErrors: homeAddressState.error,
  };
};
