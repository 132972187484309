import { Grid, makeStyles } from '@material-ui/core';
import { DsIcon, size } from '@mvp/design-system';
import React from 'react';
import { AddressType, CommunicationPreferencesAddress } from '../../../../models/communicationPreferences.model';
import { AddressBlock } from '../AddressBlock';

interface AddressesDisplayProps {
  homeAddress: CommunicationPreferencesAddress | undefined;
  mailingAddress: CommunicationPreferencesAddress | undefined;
}

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    padding: `1.75rem ${size.m(theme)} 1.5rem 0 `,
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '1.5rem',
    },
  },
  addressContainer: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      paddingRight: 0,
      '&:not(:last-child)': {
        paddingBottom: size.s(theme),
      },
    },
  },
  iconColor: {
    color: theme.palette.primary.main,
  },
  iconFontSize: {
    fontSize: '2.25rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: size.l(theme),
    },
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    width: '100%',
    paddingTop: '1.5rem',
    [theme.breakpoints.down('xs')]: {
      paddingTop: size.m(theme),
      flexDirection: 'column',
    },
  },
  container: {
    display: 'flex',
  },
}));

export const AddressesDisplay: React.FC<AddressesDisplayProps> = (props) => {
  const { homeAddress, mailingAddress } = props;
  const classes = useStyles();

  return (
    <Grid className={classes.container}>
      <Grid>
        <Grid item className={classes.iconContainer}>
          <DsIcon title="Home Icon" icon="home" className={`${classes.iconColor} ${classes.iconFontSize}`} />
        </Grid>
      </Grid>
      <Grid item className={classes.infoContainer}>
        <Grid item className={classes.addressContainer}>
          {homeAddress && <AddressBlock address={homeAddress} addressType={AddressType.Home} disableCounty={true} />}
          {mailingAddress && <AddressBlock address={mailingAddress} addressType={AddressType.Mailing} disableCounty={true} />}
        </Grid>
      </Grid>
    </Grid>
  );
};
