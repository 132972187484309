import { MvpResult } from './api';

export interface InitInfoResult extends MvpResult {
  data: InitInfo;
}

export interface InitInfo {
  recertData: RecertModel;
  genesysMemberChat: GenesysMemberChat;
  featureFlags: InitFeatureFlags;
}

export interface RecertModel {
  visible: boolean;
  recertCategory: RecertCategoryEnum;
  hireDate: string;
}

export interface GenesysMemberChat {
  firstName: string;
  lastName: string;
  memberId: string;
  mpi: string;
  dob: string;
  memeCk: string;
  planType: string;
  dynamicsContactId: string;
  bcat: string;
}

export interface InitFeatureFlags {
  genesysChat: boolean;
}

export enum RecertCategoryEnum {
  MedicaidHarpEp60 = 'MedicaidHarpEp60',
  CHPHOH60 = 'CHPHOH60',
  CHPDependent60 = 'CHPDependent60',
  LDSS60 = 'LDSS60',
  MedicaidHarpEp90 = 'MedicaidHarpEp90',
  CHPHOH90 = 'CHPHOH90',
  CHPDependent90 = 'CHPDependent90',
  LDSS90 = 'LDSS90',
}

export interface RecertDisplayText {
  Title: string;
  PrependBody: string;
  MidBody: string;
  AppendBody: string;
  PhoneNumber: string;
  healthNyGovLink: string;
  healthNyGovLinkAppendText: string;
}

export enum RecertCategoryGroupEnum {
  Group60 = 'Group60',
  Group90 = 'Group90',
}
