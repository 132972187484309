import React, { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { GenesysMemberChat } from '../models/InitInfoResult.model';
import config from '../config';
import { useMediaQuery, useTheme } from '@material-ui/core';

const addInfoToChat = (customAttributes: GenesysMemberChat | undefined) => {
  if (customAttributes) {
    (window as any)?.Genesys('command', 'Database.set', {
      messaging: {
        customAttributes,
      },
    });
  }
};

interface GenesysChatProps {
  genesysMemberChat: GenesysMemberChat | undefined;
}

const GenesysChat: FC<GenesysChatProps> = ({ genesysMemberChat }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    if ((window as any)?.Genesys) {
      (window as any).Genesys('subscribe', 'Database.ready', () => {
        addInfoToChat(genesysMemberChat);
      });

      (window as any).Genesys('subscribe', 'Database.removed', () => {
        addInfoToChat(genesysMemberChat);
      });
    }
  }, [genesysMemberChat]);

  useEffect(() => {
    if (isMobile) {
      localStorage.setItem(`_${config.GENESYS_DEPLOY_ID}:gcmcopn`, '{"value":"false","ttl":null}');
    }
  }, [isMobile]);

  return !(window as any)?.Genesys && genesysMemberChat ? (
    <Helmet>
      <script type="text/javascript" charset="utf-8" async>
        {(function (g, e, n, es, ys) {
          g['_genesysJs'] = e;
          g[e] =
            g[e] ||
            function () {
              (g[e].q = g[e].q || []).push(arguments);
            };
          g[e].t = 1 * new Date();
          g[e].c = es;
          ys = document.createElement('script');
          ys.async = 1;
          ys.src = n;
          ys.charset = 'utf-8';
          document.head.appendChild(ys);
        })(window, 'Genesys', 'https://apps.mypurecloud.com/genesys-bootstrap/genesys.min.js', {
          environment: config.GENESYS_ENVIRONMENT,
          deploymentId: config.GENESYS_DEPLOY_ID,
        })}
      </script>
    </Helmet>
  ) : null;
};

export default GenesysChat;
