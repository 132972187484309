import { makeStyles } from '@material-ui/core';
import { DsSnackbar, DsText, size } from '@mvp/design-system';
import React from 'react';
import { useOnboardingContext } from '../../../hooks/useOnboardingContext';
import { OnboardingStepProps, TaskStep } from '../../../models';
import { getHeapIdent, HeapLocation, Target } from '../../../models/heapIdentifiers.model';
import { OnboardingText } from '../OnboardingText';
import CommPrefContent from './commPref/CommPrefContent';
import ContentTemplate from './ContentTemplate';
import { useModalSaveStatus } from './useModalSaveStatus';

const useStyles = makeStyles((theme) => ({
  divider: {
    borderTop: '0.063rem solid',
    borderTopColor: theme.palette.grey[100],
    justifySelf: 'flex-end',
  },
  bottomMargin: {
    marginBottom: size.xs(theme),
  },
  text: {
    [theme.breakpoints.up('md')]: {
      paddingRight: '10rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: '1.75rem',
    },
  },
  textContainer: {
    flexGrow: 1,
  },
  subtitle: {
    fontWeight: 600,
  },
  nextStepButton: {
    padding: `${size.xxs(theme)} ${size.l(theme)}`,
    margin: `${size.m(theme)} 0 0`,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  title: {
    [theme.breakpoints.only('xs')]: {
      fontSize: '2rem',
    },
  },
}));

const UpdateCommPref: React.FC<OnboardingStepProps> = ({ advanceStep, goBack, goToStep }) => {
  const classes = useStyles();
  const advanceStepId = getHeapIdent(HeapLocation.onboarding_modal, Target.advancebutton);

  const onUpdate = (step: TaskStep) => {
    goToStep && goToStep(step);
  };

  const { commPref } = useOnboardingContext();
  const { useContext } = useModalSaveStatus();

  return (
    <ContentTemplate
      goBack={{ function: goBack, id: getHeapIdent(HeapLocation.onboarding_modal, Target.gobackbutton) }}
      advanceStep={{ function: advanceStep, text: OnboardingText.continueText, id: advanceStepId }}
    >
      <DsText className={`${classes.bottomMargin} ${classes.title}`} variant="h1">
        {OnboardingText.commPref.title}
      </DsText>
      <DsText className={`${classes.bottomMargin} ${classes.text}`} variant="subtitle2">
        {OnboardingText.commPref.subtitle}
      </DsText>
      <CommPrefContent isLoading={commPref.isFetching} onUpdate={onUpdate} />
      <DsSnackbar
        open={useContext.commPrefSaveStatus.value}
        autoHideDuration={5000}
        onClose={() => useContext.commPrefSaveStatus.updateValue && useContext.commPrefSaveStatus.updateValue(false)}
        message={OnboardingText.successSnackbarText}
      />
    </ContentTemplate>
  );
};

export default UpdateCommPref;
