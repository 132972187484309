import { Box, makeStyles, Typography } from '@material-ui/core';
import { DsExternalButtonLink, DsInternalButtonLink, size } from '@mvp/design-system';
import React from 'react';
import { routes } from '../../routesConfig';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.grey[300],
    marginBottom: size.xl(theme),
  },
  container: {
    textAlign: 'center',
    maxWidth: '42rem',
    height: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '8.25rem',
  },
  largeFont: {
    fontSize: '10rem',
    fontWeight: 800,
    color: theme.palette.grey[100],
  },
  mbXs: {
    marginBottom: size.xs(theme),
  },
  button: {
    margin: '2rem 0 0 0',
  },
}));

export interface ErrorPageTemplateProps {
  errorCode?: number;
  errorMessage: string;
  buttonLinkProps?: {
    to: string;
    text: string;
    onClick?: () => void;
    id?: string;
  };
  classes?: {
    errorCode?: string;
    errorMessage?: string;
    button?: string;
    container?: string;
  };
  isInternalLink?: boolean;
}

export const ErrorPageTemplate: React.FC<ErrorPageTemplateProps> = ({
  errorCode,
  errorMessage,
  buttonLinkProps = {
    to: routes.dashboard,
    text: 'Back to Dashboard',
  },
  classes,
  children,
  isInternalLink = true,
}) => {
  const styles = useStyles();

  return (
    <Box className={`${styles.container} ${classes?.container}`}>
      <Typography className={`${styles.title} ${classes?.errorCode}`} variant="h1" classes={{ h1: styles.largeFont }}>
        {errorCode}
      </Typography>
      <Typography className={`${styles.mbXs} ${classes?.errorMessage}`} variant="h1">
        {errorMessage}
      </Typography>
      {children}
      {isInternalLink ? (
        <DsInternalButtonLink
          onClick={buttonLinkProps.onClick && buttonLinkProps.onClick}
          {...buttonLinkProps}
          color="primary"
          className={`${styles.button} ${classes?.button}`}
          variant="contained"
        />
      ) : (
        <DsExternalButtonLink
          onClick={buttonLinkProps.onClick && buttonLinkProps.onClick}
          href={buttonLinkProps.to}
          text={buttonLinkProps.text}
          id={buttonLinkProps.id}
          color="primary"
          className={`${styles.button} ${classes?.button}`}
          variant="contained"
        />
      )}
    </Box>
  );
};
