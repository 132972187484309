import * as yup from 'yup';
import { isPhoneNumberEmpty, standardUSPhoneRegex } from '../../../../utils/string';

export const modifyPhoneSchema = yup.object().shape({
  homePhoneNumber: yup.object().shape({
    phone: yup
      .string()
      .matches(standardUSPhoneRegex, { excludeEmptyString: true })
      .transform((value, originalValue) => {
        return isPhoneNumberEmpty(value) ? '' : value;
      }),
    confirmPhone: yup
      .string()
      .matches(standardUSPhoneRegex, { excludeEmptyString: true })
      .transform((value, originalValue) => {
        return isPhoneNumberEmpty(value) ? '' : value;
      }),
  }),
  mailingPhoneNumber: yup.object().shape({
    phone: yup
      .string()
      .matches(standardUSPhoneRegex, { excludeEmptyString: true })
      .transform((value, originalValue) => {
        return isPhoneNumberEmpty(value) ? '' : value;
      }),
    confirmPhone: yup
      .string()
      .matches(standardUSPhoneRegex, { excludeEmptyString: true })
      .transform((value, originalValue) => {
        return isPhoneNumberEmpty(value) ? '' : value;
      }),
  }),
});
