import { AddressFormDetails } from './updateUserInfo.model';

export interface CommunicationPreferences extends BaseCommunicationPreferences {
  phoneExt: string;
  lastConfirmationDate: string;
  transactionalPreferences: TransactionalPreferences[];
  commercialPreference: CommercialMemberPreferenceSelection;
  updateAccountStatus: UpdateAccountStatus;
}

export interface BaseCommunicationPreferences {
  homeAddress: CommunicationPreferencesAddress;
  mailingAddress: CommunicationPreferencesAddress;
  phoneNumber: string;
  mailingAddressPhoneNumber: string;
  email: string;
}

export interface MemberSelection {
  description: string;
  displayOrder: number;
  id: string;
  modifiedDate: string;
  preferenceKey: string;
  preferenceName: string;
  legalCategory: LegalCategory;
}

export interface TransactionalPreferences extends MemberSelection {
  selectedMethodOfCommunicationId: string;
  selectedMethodOfCommunicationName: string;
  availableMethodsOfCommunication: AvailableMethodsOfCommunication[];
}

export interface CommercialMemberPreferenceSelection extends MemberSelection {
  options: CommercialOption[];
}

export interface CommercialOption {
  allow: boolean;
  displayName: string;
  methodOfCommunicationId: string;
  displayOrder: number;
}

export enum LegalCategory {
  transactional = 'Transactional',
  commercial = 'Commercial',
  unknown = 'Unknown',
}

export interface AvailableMethodsOfCommunication {
  key: string;
  value: string;
}

export interface PaperlessOptions {
  [key: string]: PaperlessPreference;
}

export interface CommunicationPreferencesAddress {
  line1: string;
  line2?: string;
  line3?: string;
  city: string;
  state: string;
  zipCode: string;
  county: string;
}

export interface PaperlessPreference {
  isVisible: boolean;
  preference: PaperlessOption;
}

export enum PaperlessOption {
  Email = 'Email',
  Mail = 'Mail',
  None = 'None',
}

export enum AddressType {
  Home = 'Home Address',
  Mailing = 'Mailing Address',
}

export enum PhoneType {
  Home = 'Home Phone',
  Mailing = 'Mailing Phone',
}

export interface UpdateAccountStatus {
  updateAddress: boolean;
  updatePhone: boolean;
  phoneMessage: UpdateAccountMessageCode;
  addressMessage: UpdateAccountMessageCode;
}

export enum UpdateAccountMessageCode {
  contactUs = 'CONTACT_US',
  onlySubscriberCanUpdate = 'ONLY_SUBSCRIBER_CAN_UPDATE',
  contactSocialServices = 'CONTACT_SOCIAL_SERVICES',
  nyStateOfHealth = 'NY_STATE_OF_HEALTH',
  vermontHealthConnect = 'VERMONT_HEALTH_CONNECT',
  contactYourEmployer = 'CONTACT_YOUR_EMPLOYER',
}

export const PaperlessOptionsMap = {
  explanationOfBenefits: 'Explanation of Benefits',
  electronicBilling: 'Electronic Billing',
  affordableCareActTaxForms: 'Affordable Care Act Tax Forms',
  generalUpdates: 'General Updates',
  newsletters: 'Newsletters',
  surveys: 'Surveys',
  electronicConsent: 'Electronic Consent',
};

export interface UpdateAddressAndPhone {
  homeAddress: AddressFormDetails;
  mailingAddress: AddressFormDetails;
  homePhone: string;
  mailingPhone: string;
}
