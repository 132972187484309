import { PortalLink } from './portalLink.model';

export enum LocationId {
  navigation = 'navigation',
  accountSettings = 'accountsettings',
}

export enum LocationName {
  navigation = 'Navigation',
  accountSettings = 'AccountSettings',
}

export interface Location {
  id: LocationId;
  name: LocationName;
}

export interface CategorizedNavigationLinks {
  category: string;
  links: NavigationLink[];
  isNested: boolean;
  analyticsID: string;
  location: Location;
}

export interface NavigationLink extends PortalLink {
  selected?: boolean;
  analyticsID: string;
}
