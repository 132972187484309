import React, { ReactChild } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { DsIcon, DsText } from '@mvp/design-system';
import { size } from '@mvp/design-system';

const useStyle = makeStyles((theme) => ({
  textMuted: {
    color: theme.palette.grey[700],
    textAlign: 'center',
    padding: size.s(theme),
  },
  contrast: {
    color: theme.palette.grey[700],
    backgroundColor: theme.palette.grey[100],
    textAlign: 'center',
    padding: size.s(theme),
    height: theme.spacing(50),
  },
  icon: {
    marginBottom: size.xxs(theme),
    fontSize: theme.spacing(10.5),
    color: 'black',
  },
  text: {
    maxWidth: '100%',
  },
}));

interface ErrorCardProps {
  text1?: string;
  text2?: string;
  backgroundContrast?: boolean;
  className?: string;
  children?: ReactChild;
  hideIcon?: boolean;
}

export const ErrorCard = (props: ErrorCardProps) => {
  const { text1, text2, backgroundContrast, className, hideIcon } = props;
  const classes = useStyle();

  return (
    <Box
      className={`${backgroundContrast ? classes.contrast : classes.textMuted} ${className || ''}`}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      {!hideIcon && <DsIcon icon="exclamation-circle" title="exclamation-circle" className={classes.icon} />}
      {text1 && <DsText className={classes.text} variant="body1" text={text1} />}
      {text2 && <DsText className={classes.text} variant="body2" text={text2} />}
      {props.children}
    </Box>
  );
};
