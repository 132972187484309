import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { DsDivider, DsPrimaryLoadingButton, size } from '@mvp/design-system';
import React from 'react';
import { ESupportTextLink } from '../../../../components/ESuppportTextLink';
import { ControlledTextField } from '../../../../formControl/ControlledTextField';
import { ErrorMessage } from '../../../../models/errorMessage.model';
import { getHeapIdent, HeapLocation, Target } from '../../../../models/heapIdentifiers.model';
import { EmailForm, FormProps } from '../../../../models/updateUserInfo.model';
import ProfileAndSettingsText from '../../ProfileAndSettingsText';

interface ModifyEmailProps {
  formProps: FormProps<EmailForm>;
  handleOnSave: () => void;
  disableButton: boolean;
  errorMessage: ErrorMessage | null;
  isLoading?: boolean;
  isOnboarding?: boolean;
}

interface StyleProps {
  isOnboarding: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  modifyEmailContainer: {
    marginTop: '0.375rem',
    marginLeft: 0,
  },
  errorMessage: {
    color: theme.palette.primary.main,
    marginTop: (props) => (props.isOnboarding ? 0 : size.xxs(theme)),
    fontSize: '0.875rem',
  },
  submitAndErrorContainer: {
    marginLeft: '0.375rem',
  },
  divider: {
    marginTop: size.m(theme),
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      marginLeft: `-${size.m(theme)}`,
    },
  },
  saveButton: {
    marginTop: size.xs(theme),
  },
}));

export const ModifyEmail: React.FC<ModifyEmailProps> = (props) => {
  const { formProps, handleOnSave, disableButton, errorMessage, isOnboarding = false, isLoading = false } = props;
  const classes = useStyles({ isOnboarding });

  const saveChangesButtonHeapId = getHeapIdent(HeapLocation.acctprefs, Target.modifyEmailSaveButton);

  return (
    <>
      <Grid container spacing={3} className={classes.modifyEmailContainer}>
        <Grid item lg={5} md={5} sm={12} xs={12}>
          <ControlledTextField<EmailForm>
            inputProps={{
              label: ProfileAndSettingsText.contactInformation.updateEmail.form.email,
              id: 'email',
            }}
            name="email"
            control={formProps.control}
            variant="none"
          />
        </Grid>
        <Grid item lg={5} md={5} sm={12} xs={12}>
          <ControlledTextField<EmailForm>
            inputProps={{
              label: ProfileAndSettingsText.contactInformation.updateEmail.form.confirmEmail,
              id: 'confirm-email',
            }}
            name="confirmEmail"
            control={formProps.control}
            variant="none"
          />
        </Grid>
      </Grid>
      <Grid item direction="column" className={classes.submitAndErrorContainer}>
        {!isOnboarding && (
          <DsPrimaryLoadingButton
            id={saveChangesButtonHeapId}
            actionInProgress={isLoading}
            actionText={ProfileAndSettingsText.contactInformation.submittingText}
            disabled={disableButton}
            onClick={handleOnSave}
            text={ProfileAndSettingsText.contactInformation.saveText}
            className={classes.saveButton}
          />
        )}
        {errorMessage && (
          <Typography className={classes.errorMessage}>
            {errorMessage.text}{' '}
            {errorMessage.linkText && (
              <ESupportTextLink openInNewTab className={classes.errorMessage} variant="body2" linkText={errorMessage.linkText} />
            )}{' '}
            {errorMessage.endText}
          </Typography>
        )}
      </Grid>
      {!isOnboarding && (
        <Grid item xs={12}>
          <DsDivider className={classes.divider} />
        </Grid>
      )}
    </>
  );
};
