import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { size } from '@mvp/design-system';

const useStyles = makeStyles((theme) => ({
  instructions: {
    '&:not(:last-child)': {
      marginBottom: size.s(theme),
    },
  },
}));

export interface PageErrorInstructionsProps {
  instructions: (string | number | JSX.Element)[][];
}

export const PageErrorInstructions: React.FC<PageErrorInstructionsProps> = ({ instructions }) => {
  const classes = useStyles();

  return (
    <Box>
      {instructions.map((instructionSet, index) => (
        <Typography key={`instruction-set-${index}`} className={classes.instructions} variant="body1">
          {instructionSet.map((instruction, subIndex) => (
            <span key={`instruction-detail-${subIndex}`}>{instruction}</span>
          ))}
        </Typography>
      ))}
    </Box>
  );
};
