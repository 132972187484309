import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';

export type IdTokenClaims = {
  role?: MembershipStatus;
  firstName?: string;
  lastName?: string;
  memberId?: string;
  groupNumber?: string;
  accessCodes?: string;
  isGhostAdmin?: boolean;
  hashedMemberId?: string;
  b2cObjectId?: string;
};

export enum MembershipStatus {
  NonMember = 'N',
  Member = 'M',
}

export type ClaimsHookResult = {
  claims?: IdTokenClaims;
};

export interface ClaimsObject {
  ROLE?: string;
  FIRST_NAME?: string;
  LAST_NAME?: string;
  MEMBERID?: string;
  GROUPID?: string;
  ACCESS_CODES?: string;
  ghostadmin?: string;
  HASHEDMEMBERID?: string;
  B2C_OBJID?: string;
}

export const IsMember = (claims?: IdTokenClaims) => {
  return claims && claims?.role && claims?.role !== MembershipStatus.NonMember;
};

export function useIdTokenClaims(): ClaimsHookResult {
  const [claims, setClaims] = useState<IdTokenClaims>();
  const { instance, accounts } = useMsal();

  useEffect(() => {
    if (accounts.length > 0) {
      const claims = accounts[0].idTokenClaims as ClaimsObject;
      setClaims({
        role: claims['ROLE'],
        firstName: claims['FIRST_NAME'],
        lastName: claims['LAST_NAME'],
        memberId: claims['MEMBERID'],
        groupNumber: claims['GROUPID'],
        accessCodes: claims['ACCESS_CODES'],
        isGhostAdmin: claims['ghostadmin'] === 'true',
        hashedMemberId: claims['HASHEDMEMBERID'],
        b2cObjectId: claims['B2C_OBJID'],
      });
    }
  }, [instance, accounts]);

  return { claims };
}
