import { makeStyles } from '@material-ui/core';
import { DsText, size } from '@mvp/design-system';
import React, { useEffect } from 'react';
import { onboardingProvider } from '../../../api/onboardingProvider';
import { BaseOnboardingStepProps, TaskKeys } from '../../../models';
import { getHeapIdent, HeapLocation, Target } from '../../../models/heapIdentifiers.model';
import ContentTemplate from './ContentTemplate';

const useStyles = makeStyles((theme) => ({
  bottomMargin: {
    marginBottom: size.m(theme),
  },
  text: {
    [theme.breakpoints.up('md')]: {
      paddingRight: '10rem',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      paddingRight: '2.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: '1.75rem',
    },
  },
  boldText: {
    fontStyle: 'italic',
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const updateOnboardingStatus = async () => {
  await Promise.all([
    onboardingProvider.updateOnboardingStatus({ onboardingTask: TaskKeys.Select_PCP, isComplete: false }),
    onboardingProvider.updateOnboardingStatus({ onboardingTask: TaskKeys.Onboarding, isComplete: false }),
  ]);
};

const WelcomeAndPcp: React.FC<BaseOnboardingStepProps> = ({ advanceStep }) => {
  const classes = useStyles();

  useEffect(() => {
    updateOnboardingStatus();
  }, []);

  return (
    <ContentTemplate
      advanceStep={{
        function: advanceStep,
        text: 'Find My Doctor',
        id: getHeapIdent(HeapLocation.onboarding_modal, Target.findmydoc),
      }}
    >
      <DsText className={classes.bottomMargin} variant="h1">
        Welcome to your MVP Account!
      </DsText>
      <DsText className={`${classes.bottomMargin} ${classes.text}`} variant="subtitle2">
        Thanks for choosing MVP! We’re excited to show you all the great features of your online account.
      </DsText>
      <DsText variant="subtitle2" className={classes.text}>
        First, let's personalize your account by finding your doctor in our network. Click{' '}
        <span className={classes.boldText}>Find My Doctor</span> below to get started.
      </DsText>
    </ContentTemplate>
  );
};

export default WelcomeAndPcp;
