import React from 'react';
import { makeStyles } from '@material-ui/core';
import { DsExternalTextLink } from '@mvp/design-system';

interface ESupportProps {
  openInNewTab: boolean;
  linkText?: string;
  className?: string;
  id?: string;
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'caption'
    | 'button'
    | 'overline'
    | undefined;
}

const useStyles = makeStyles((theme) => ({
  boldLink: {
    fontWeight: 600,
    textDecoration: 'underline',
  },
}));

export const ESupportTextLink: React.FC<ESupportProps> = (props) => {
  const { openInNewTab, variant = 'subtitle2', linkText = 'eSupport@mvphealthcare.com', className, id } = props;
  const classes = useStyles();

  return (
    <DsExternalTextLink
      openInNewTab={openInNewTab}
      className={`${classes.boldLink} ${className}`}
      variant={variant}
      text={linkText}
      href="mailto:esupport@mvphealthcare.com"
      id={id}
    />
  );
};
