import { useEffect, useState } from 'react';
import { UpdateAccountStatus } from '../models/communicationPreferences.model';
import { PhraseDetails, getUpdateInfoValues, InfoItems } from '../pages/CommunicationPreferences/utils/updateInfoText';

export const useUpdateAccountStatus = (updateAccountStatus: UpdateAccountStatus | undefined) => {
  const [updateMessage, setUpdateMessage] = useState<PhraseDetails>();

  useEffect(() => {
    if (updateAccountStatus && !updateAccountStatus.updateAddress && !updateAccountStatus.updatePhone) {
      setUpdateMessage(getUpdateInfoValues(updateAccountStatus.addressMessage, InfoItems.both));
    }
  }, [updateAccountStatus]);

  return { updateMessage };
};
