import React, { createContext, useContext, useState } from 'react';

interface Status {
  value: boolean;
  updateValue: React.Dispatch<React.SetStateAction<boolean>> | undefined;
}

interface SaveStatus {
  [key: string]: Status;
}

export const SavingStatusContext = createContext<SaveStatus>({
  commPrefSaveStatus: {
    value: false,
    updateValue: undefined,
  },
});

export const useModalSaveStatus = () => {
  const [commPrefUpdateSaved, setCommPrefUpdateSaved] = useState<boolean>(false);

  const baseData: SaveStatus = {
    commPrefSaveStatus: {
      value: commPrefUpdateSaved,
      updateValue: setCommPrefUpdateSaved,
    },
  };

  return { context: SavingStatusContext, useContext: useContext(SavingStatusContext), data: baseData };
};
