import { PublicClientApplication } from '@azure/msal-browser';
import React from 'react';
import { msalFrontInstanceLogoutConfig } from '../../authConfig';

export const FrontChannelLogout: React.FC = () => {
  const fcInstance = new PublicClientApplication(msalFrontInstanceLogoutConfig);

  // Automatically on page load
  fcInstance.logoutRedirect({
    onRedirectNavigate: () => {
      // Return false to stop navigation after local logout
      return false;
    },
  });

  return <h1>Logout</h1>;
};
