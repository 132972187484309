import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { DsAppBar, DsAppBarLinks } from '@mvp/design-system';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStoreon } from 'storeon/react';
import { ErrorCard } from '.';
import { logoutRequest, userNameKey } from '../authConfig';
import isFeatureEnabled, { FeatureToggleKeys } from '../hooks/useFeature';
import { filterLinks, mapLinksToProps, removeLinks, setSelected } from '../hooks/useNavigationItems';
import { CategorizedNavigationLinks, LocationId } from '../models';
import { getHeapIdent, HeapLocation, Target } from '../models/heapIdentifiers.model';
import { RecertModel } from '../models/InitInfoResult.model';
import { routes } from '../routesConfig';
import { sharedUrls } from '../sharedUrls';
import { NavLinksEvents, NavLinksState } from '../storeProviders/reducers';
import giaLogo from '../svgs/GiaByMVP.svg';
import { getUsersName, handlePostLogout } from './AuthProvider';
import RecertGlobalBanner from './MedicaidRecert/RecertGlobalBanner';
//IMPORTANT: opt out from adding additional imports to <Header> & <PortalSidebar> if an alternative is possible...
//Test out integration with dotnet pages when not

const portalUrl = sharedUrls.portalUrl;

const logoId = getHeapIdent(HeapLocation.header, Target.logo);
const contactUsId = getHeapIdent(HeapLocation.header, Target.contactus);
const signoutId = getHeapIdent(HeapLocation.header, Target.signout);
const accountDropdown = getHeapIdent(HeapLocation.header, Target.accountDropdown);
const secureMessagingId = getHeapIdent(HeapLocation.header, Target.secureMessagingButton);

interface MessageIconProps {
  isNewMessage: boolean;
  onMessagePage: boolean;
}

const MessageIcon: FC<MessageIconProps> = ({ isNewMessage, onMessagePage }) => {
  const iconColor = onMessagePage ? '#BE143C' : 'rgb(112, 101, 93)';
  return (
    <svg style={{ marginBottom: '1px' }} width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22 16H16L14 19H10L8 16H2" stroke={iconColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M5.45 9.11L2 16V22C2 22.5304 2.21071 23.0391 2.58579 23.4142C2.96086 23.7893 3.46957 24 4 24H20C20.5304 24 21.0391 23.7893 21.4142 23.4142C21.7893 23.0391 22 22.5304 22 22V16L18.55 9.11C18.3844 8.77679 18.1292 8.49637 17.813 8.30028C17.4967 8.10419 17.1321 8.0002 16.76 8H7.24C6.86792 8.0002 6.50326 8.10419 6.18704 8.30028C5.87083 8.49637 5.61558 8.77679 5.45 9.11Z"
        stroke={iconColor}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      {isNewMessage && (
        <>
          <path
            d="M20 12C22.7614 12 25 9.76142 25 7C25 4.23858 22.7614 2 20 2C17.2386 2 15 4.23858 15 7C15 9.76142 17.2386 12 20 12Z"
            fill="#00B3E3"
            stroke="white"
            stroke-width="2"
          />
          <g opacity="0.2">
            <path
              d="M20 14C23.866 14 27 10.866 27 7C27 3.13401 23.866 0 20 0C16.134 0 13 3.13401 13 7C13 10.866 16.134 14 20 14Z"
              fill="#00B3E3"
            />
          </g>
        </>
      )}
    </svg>
  );
};

const Header = ({
  isWithinReactApp,
  categorizedLinks,
  errorState = false,
  hasNewMessage,
  recertData,
  showSecureMessaging = true,
}: {
  isWithinReactApp: boolean;
  categorizedLinks: CategorizedNavigationLinks[];
  errorState?: boolean;
  hasNewMessage?: boolean;
  recertData?: RecertModel | undefined;
  showSecureMessaging?: boolean;
}) => {
  const history = useHistory();
  const logoRedirect = () => {
    isWithinReactApp ? history.push('/') : window.location.replace(portalUrl);
  };
  const { instance, inProgress } = useMsal();

  const [username, setUsername] = useState<string>(getUsersName());

  const secureMessagingRoutes = Object.values(routes.secureMessaging);
  const onMessagePage = secureMessagingRoutes.some((route: string) => window.location.pathname.includes(route));
  const enableSecureMessage = showSecureMessaging && isFeatureEnabled(FeatureToggleKeys.SecureMessaging);

  useEffect(() => {
    setUsername(localStorage.getItem(userNameKey) ?? '');
  }, [localStorage.getItem(userNameKey)]);

  const { navLinksStore } = useStoreon<NavLinksState, NavLinksEvents>('navLinksStore');
  const { data: navLinks } = navLinksStore;
  const links = isWithinReactApp ? categorizedLinks : navLinks;

  const filteredLinks = removeLinks(links, [LocationId.accountSettings]);
  const filteredAccountSettingLinks = filterLinks(links, [LocationId.accountSettings]);
  const dropdownLinks = filteredAccountSettingLinks[0]?.links;
  const mobileAccountSettingsLinks = mapLinksToProps(filteredAccountSettingLinks, isWithinReactApp, history)[0]?.subItems || [];

  const [mobileNavLinks, setMobileNavLinks] = useState([
    ...mapLinksToProps(filteredLinks, isWithinReactApp, history),
    ...mobileAccountSettingsLinks,
  ]);

  const isSigningOut = isWithinReactApp ? inProgress === InteractionStatus.Logout : false;

  useEffect(() => {
    setMobileNavLinks([...mapLinksToProps(setSelected(filteredLinks), isWithinReactApp, history), ...mobileAccountSettingsLinks]);
  }, [categorizedLinks]);

  useEffect(() => {
    if (isWithinReactApp) {
      const unlisten = history.listen(() => {
        setMobileNavLinks([...mapLinksToProps(setSelected(filteredLinks), isWithinReactApp, history), ...mobileAccountSettingsLinks]);
      });
      return () => {
        unlisten();
      };
    }
  }, [history, categorizedLinks]);

  const handleOnDropItemClick = (option: string) => {
    const selectedOption = dropdownLinks?.find((link) => link.label === option);
    if (selectedOption?.url) {
      const route = new URL(selectedOption.url).pathname;
      isWithinReactApp ? history.push(route) : window.location.replace(`${portalUrl}${route}`);
    }
  };

  const handleSignOut = async () => {
    if (isWithinReactApp) {
      await handlePostLogout();
      instance.logoutRedirect(logoutRequest);
    } else {
      //redirect to logout page
      window.location.href = sharedUrls.sharedComponentsLogoutUrl;
    }
  };

  const dropdownItems = dropdownLinks?.map((link) => ({
    text: link.label,
    heapId: getHeapIdent(HeapLocation.header, link.analyticsID),
  }));

  const dropdownProps = {
    title: username,
    onClick: handleOnDropItemClick,
    mobileId: accountDropdown,
    desktopId: accountDropdown,
    showLoading: isSigningOut,
    items: dropdownItems,
  };

  const quickActionLinks: DsAppBarLinks[] = [
    {
      url: !isWithinReactApp ? `${portalUrl}/contact-us` : `/contact-us`,
      text: 'Contact Us',
      title: 'Contact Us',
      isExternal: !isWithinReactApp,
      shouldOpenNewTab: false,
      id: contactUsId,
    },
  ];

  if (enableSecureMessage) {
    quickActionLinks.push({
      url: routes.secureMessaging.base,
      text: 'Messages',
      title: 'Messages',
      isExternal: !isWithinReactApp,
      id: secureMessagingId,
      fontColor: onMessagePage ? 'primary' : 'default',
      frontIcon: <MessageIcon isNewMessage={!!hasNewMessage} onMessagePage={onMessagePage} />,
      isButton: true,
      variant: 'outlined',
    });
  }

  return (
    <DsAppBar
      text=""
      logo={{ src: giaLogo, alt: 'MVP Logo', width: 75, onClick: logoRedirect, id: logoId }}
      showSearch={false}
      handleSignOut={handleSignOut}
      signOutInProgress={isWithinReactApp ? inProgress === InteractionStatus.Logout : false}
      signOutLinkId={signoutId}
      links={quickActionLinks}
      mobileMenuSettings={{
        title: username,
        headerIcon: 'fal fa-user-circle',
        fullHeight: true,
      }}
      mobileNavItems={mobileNavLinks}
      mobileChildren={errorState && <ErrorCard text1="Unable to load navigation" backgroundContrast />}
      dropdownProps={dropdownProps}
      globalBanner={isWithinReactApp ? <RecertGlobalBanner recertData={recertData} /> : null}
    />
  );
};

export default Header;
