import React, { ReactNode, useEffect, useState } from 'react';
import { Grid, makeStyles, Typography, Icon, useMediaQuery, useTheme } from '@material-ui/core';
import { DsPrimaryButton, size } from '@mvp/design-system';
import { getHeapIdent, Target } from '../models/heapIdentifiers.model';
import { CommonSsoTooltip } from './CommonSsoTooltip';
import { printPage } from '../utils/printPage';
import { ReactComponent as GlobeIcon } from '../assets/svg/GlobeIcon.svg';
import { useTranslation } from 'react-i18next';

interface PageTitleProps {
  title: string | ReactNode;
  showToolTip: boolean;
  toolTipMessage: string;
}

export enum LanguageToggleType {
  English = 'en',
  Spanish = 'es',
}

const translations: {
  [key: string]: {
    toggleText: string;
  };
} = {
  en: {
    toggleText: 'Ver en Español',
  },
  es: {
    toggleText: 'View in English',
  },
};

const useStyles = makeStyles((theme) => ({
  printPage: {
    paddingRight: '1.5rem',
    paddingLeft: '1.5rem',
    whiteSpace: 'nowrap',
    '& *.fa-print': { color: `${theme.palette.grey[700]}` },
    [theme.breakpoints.down('sm')]: {
      paddingRight: size.xs(theme),
      minWidth: 0,
    },
    [`@media print`]: {
      display: 'none',
    },
  },
  toggleLanguage: {
    paddingRight: '1.5rem',
    paddingLeft: '1.5rem',
    whiteSpace: 'nowrap',
    '& *.MuiButton-label': {
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: size.xs(theme),
      minWidth: 0,
    },
    [`@media print`]: {
      display: 'none',
    },
  },
  providerHiddenCell: {
    color: theme.palette.text.disabled,
    textDecoration: 'underline dashed',
    textUnderlineOffset: '0.375rem',
    textDecorationThickness: '0.125rem',
  },
}));

const PageTitle: React.FC<PageTitleProps> = ({ title, showToolTip, toolTipMessage }) => {
  const classes = useStyles();
  const baseTitle = (
    <Typography variant="h1" className={showToolTip ? classes.providerHiddenCell : ''}>
      {title}
    </Typography>
  );
  return showToolTip ? <CommonSsoTooltip placement="bottom" children={baseTitle} text={toolTipMessage} /> : baseTitle;
};

export interface PageHeaderProps {
  pageTitle: string | ReactNode;
  pageId: string;
  showToolTip?: boolean;
  toolTipMessage?: string;
  showPrint?: boolean;
  showLanguageToggle?: boolean;
  printOrientation?: 'portrait' | 'landscape';
  classes?: {
    printButtonContainerStyle?: string;
    toggleLanguageButtonContainerStyle?: string;
    container?: string;
    titleContainer?: string;
  };
}

export const PageHeader: React.FC<PageHeaderProps> = (props) => {
  const { pageTitle, pageId, showToolTip = false, toolTipMessage = '', showPrint = true, showLanguageToggle = false, classes } = props;
  const [language, setLanguage] = useState(LanguageToggleType.English); // Default language is English
  const classStyles = useStyles();
  const { i18n, t } = useTranslation(['common']);

  const theme = useTheme();
  const printButtonText = useMediaQuery(theme.breakpoints.down('sm'))
    ? ''
    : showLanguageToggle
    ? t('common:header.printPage')
    : 'Print Page';
  const printPageId = getHeapIdent(pageId, Target.printpage);
  const toggleLanguageId = getHeapIdent(pageId, Target.togglelanguage);

  const toggleLanguage = () => {
    i18n.changeLanguage(language === LanguageToggleType.English ? LanguageToggleType.Spanish : LanguageToggleType.English);
    setLanguage((prevLanguage) => (prevLanguage === LanguageToggleType.English ? LanguageToggleType.Spanish : LanguageToggleType.English));
  };

  useEffect(() => {
    const browserLanguage = navigator.language.substring(0, 2);
    if (browserLanguage === LanguageToggleType.Spanish) {
      setLanguage(LanguageToggleType.Spanish);
    }

    setLanguage(i18n.language === 'es' ? LanguageToggleType.Spanish : LanguageToggleType.English);
  }, []);

  const { toggleText } = useMediaQuery(theme.breakpoints.down('sm')) ? { toggleText: '' } : translations[language];

  return (
    <Grid container className={classes?.container} justifyContent="space-between">
      <Grid className={classes?.titleContainer} item xs={8}>
        <PageTitle title={pageTitle} showToolTip={showToolTip} toolTipMessage={toolTipMessage} />
      </Grid>
      <Grid item xs>
        {showLanguageToggle && (
          <Grid item className={classes?.toggleLanguageButtonContainerStyle} container xs justifyContent="flex-end">
            <DsPrimaryButton
              startIcon={<GlobeIcon />}
              onClick={toggleLanguage}
              variant="text"
              className={classStyles.toggleLanguage}
              id={toggleLanguageId}
              text={toggleText}
            />
          </Grid>
        )}
        {showPrint && (
          <Grid item className={classes?.printButtonContainerStyle} container xs justifyContent="flex-end">
            <DsPrimaryButton
              startIcon={<Icon style={{ fontSize: 24 }} className="fal fa-print" />}
              onClick={printPage}
              variant="text"
              className={classStyles.printPage}
              id={printPageId}
              text={printButtonText}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
