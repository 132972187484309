import React from 'react';
import { makeStyles } from '@material-ui/core';
import { DsTooltip, DsTooltipProps, onlyIE } from '@mvp/design-system';
import { useTranslation } from 'react-i18next';

const useClasses = makeStyles(() => ({
  tooltipBox: {
    display: 'inline-block',
    margin: 0,
    width: 'fit-content',
    [onlyIE]: {
      display: 'table',
    },
  },
}));

export interface CommonSsoTooltipProps extends Omit<DsTooltipProps, 'title'> {
  title?: string;
  translateDefaultSSOText?: boolean;
}

export const defaultSSOLinkText = 'This link takes you away from MVP’s website';

export const CommonSsoTooltip: React.FC<CommonSsoTooltipProps> = ({
  children,
  placement = 'top',
  title = '',
  text,
  translateDefaultSSOText = false,
  ...props
}) => {
  const { t } = useTranslation(['common']);

  var translatedTooltip = text;
  if (!text && translateDefaultSSOText) {
    translatedTooltip = t('common:externalSSOText');
  } else if (!text) {
    translatedTooltip = defaultSSOLinkText;
  }
  const classes = useClasses();

  return (
    <DsTooltip {...props} placement={placement} arrow title={title} text={translatedTooltip}>
      <span className={classes.tooltipBox}>{children}</span>
    </DsTooltip>
  );
};
