import React from 'react';
import { QueryClient, QueryClientProvider, useMutation } from 'react-query';

export enum QueryKey {
  Analytics = 'Analytics',
  PlanInfo = 'PlanInfo',
  QuickLinks = 'QuickLinks',
  IdCardInformation = 'IdCardInformation',
  IdCardPdfInformation = 'IdCardPdfInformation',
  DeductiblesAndLimits = 'DeductiblesAndLimits',
  NotificationCenter = 'NotificationCenter',
  MemberClaims = 'MemberClaims',
  ClaimsDetails = 'ClaimsDetails',
  ClaimDocInfo = 'ClaimDocInfo',
  ClaimDocEOBInfo = 'ClaimDocEOBInfo',
  ClaimDocADLInfo = 'ClaimDocADLInfo',
  Onboarding = 'Onboarding',
  PaymentStatements = 'PaymentStatements',
  PaymentHistory = 'PaymentHistory',
  PreventiveCareReminders = 'PreventtiveCareReminders',
  PreventiveCareWellnessAlertsThruDate = 'PreventiveCareWellnessAlertsThruDate',
  PriorAuth = 'PriorAuth',
  UserInfo = 'UserInfo',
  MemberHasPlanInfo = 'MemberHasPlanInfo',
  CommunicationPreference = 'CommunicationPreference',
  NewCommunicationPreference = 'NewCommunicationPreference',
  MemberInformation = 'MemberInformation',
  MemberProfileInformation = 'MemberProfileInformation',
  MemberEnrollmentStatus = 'MemberEnrollmentStatus',
  MemberMedicalLinks = 'MemberMedicalLinks',
  MemberMedicalGuide = 'MemberMedicalGuide',
  ContactInfo = 'ContactInfo',
  Languages = 'Languages',
  States = 'States',
  MemberPcpProvider = 'MemberPcpProvider',
  SubscriberInfo = 'SubscriberInfo',
  ActiveMemberInformation = 'ActiveMemberInformation',
  SideNavigations = 'SideNavigations',
  IdCardInformationWithProducts = 'IdCardInformationWithProducts',
  WellbeingReimbursement = 'WellbeingReimbursement',
  TaxForms = 'TaxForms',
  Dashboard = 'Dashboard',
  SecureMessagingInbox = 'SecureMessagingInbox',
  SecureMessagingArchive = 'SecureMessagingArchive',
  SecureMessagingDrafts = 'SecureMessagingDrafts',
  MessageThread = 'MessageThread',
  SecureMessagingNewMessage = 'SecureMessagingNewMessage',
  NewMessageCount = 'NewMessageCount',
  PaymentAndBillingHistory = 'PaymentAndBillingHistory',
  InvoiceUrl = 'InvoiceUrl',
  Statements = 'Statements',
  Documents = 'Documents',
  DownloadDocument = 'DownloadDocument',
  DownloadAllDocument = 'DownloadAllDocument',
  InitInfo = 'InitInfo',
  CustomerCareInfo = 'CustomerCareInfo',
  PaymentSSOTokens = 'PaymentSSOTokens',
  AutoPayToken = 'AutoPayToken',
  PaymentCenterToken = 'PaymentCenterToken',
}

export type QueryKeyType = string | (string | number)[];

export const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: 0, staleTime: Infinity, cacheTime: Infinity, refetchOnWindowFocus: false } },
});

export const invalidateQuery = async (queryKey: QueryKeyType, options?: any) => await queryClient.invalidateQueries(queryKey, options);

export const removeQuery = (queryKey: QueryKeyType) => queryClient.removeQueries({ queryKey });

export const invalidateAllQueries = async () => await queryClient.invalidateQueries();

export const invalidateListOfQueries = async (invalidateKeys: QueryKeyType[] | undefined, refetchInvalidationQueries: boolean = true) => {
  invalidateKeys?.forEach((key: QueryKeyType) => {
    invalidateAndRemoveQueries(key, refetchInvalidationQueries);
  });
};

export const invalidateAndRemoveQueries = async (key: QueryKeyType, refetchInvalidationQueries: boolean = true) => {
  await invalidateQuery(key);
  if (refetchInvalidationQueries) {
    removeQuery(key);
  }
};

interface UseMutateDataProps {
  api: (param0?: any, param1?: any) => any;
  invalidateKeys?: QueryKeyType[];
  options?: any;
}

export const useMutateData = ({ api, invalidateKeys, options }: UseMutateDataProps) =>
  useMutation(api, {
    ...options,
    onSuccess: () => {
      options?.onSuccess && options.onSuccess();
      invalidateListOfQueries(invalidateKeys, options?.refetchInvalidationQueries);
    },
  });

const ReactQueryClientProvider: React.FC = (props) => <QueryClientProvider client={queryClient}>{props.children}</QueryClientProvider>;
export default ReactQueryClientProvider;
