import { sharedUrls } from '../sharedUrls';
import axiosSingleton from '../components/axios';

export const selfServiceSessionProvider = {
  async clearSession(): Promise<boolean> {
    try {
      await axiosSingleton.post(sharedUrls.legacySessionClear, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-Requested-With': 'XMLHttpRequest',
        },
      });

      return true;
    } catch {
      return false;
    }
  },
};
