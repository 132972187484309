import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

/**
 * Feedback widget is generated via a 3rd party script. We only want to show on the dashboard so we override some styles manually.
 */
export const FeedbackWidget: React.FC = () => {
  useEffect(() => {
    const getFeedback = () => document.getElementById('inviteDiv');
    const feedback = getFeedback();
    const footer = document.getElementById('footerContents');

    if (feedback) {
      footer?.insertBefore(feedback, footer.firstChild);
      feedback.style.display = 'block';
    }

    return () => {
      const removedFeedback = getFeedback();
      if (removedFeedback) {
        removedFeedback.style.display = 'none';
      }
    };
  });
  return (
    <Helmet>
      <script src="https://mvphealthcare.allegiancetech.com/surveys/5SEV99/Preview/Scripts/clientExtensibility.js?ts=636966285386340788" />
    </Helmet>
  );
};
