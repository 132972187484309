import { Grid, Icon, makeStyles, Typography } from '@material-ui/core';
import { DsDivider, DsPrimaryButton, size } from '@mvp/design-system';
import React, { useEffect, useState } from 'react';
import { useUpdateEmail } from '../../../hooks/commPref/useUpdateEmail';
import { useOnSaveContext } from '../../../hooks/useOnSaveContext';
import { TaskStep } from '../../../models';
import { getHeapIdent, HeapLocation, Target } from '../../../models/heapIdentifiers.model';
import ProfileAndSettingsText from '../ProfileAndSettingsText';
import { ModifyEmail } from './email/ModifyEmail';

interface CommPrefEmailProps {
  email: string;
  onBoardingUpdate?: (step: TaskStep) => void;
  disableUpdates?: boolean;
}

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    padding: `2rem ${size.m(theme)} 1.5rem 0 `,
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: size.m(theme),
      paddingTop: size.l(theme),
    },
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    width: '100%',
    padding: `${size.m(theme)} 0`,
    [theme.breakpoints.down('sm')]: {
      paddingTop: size.m(theme),
      flexDirection: 'column',
    },
  },
  iconColor: {
    color: theme.palette.primary.main,
  },
  iconFontSize: {
    fontSize: '2.25rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },
  updateButton: {
    marginRight: '2rem',
    [theme.breakpoints.up('sm')]: {
      height: '3rem',
    },
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      marginLeft: '2.8125rem',
      marginBottom: size.m(theme),
    },
  },
  wrapEmail: {
    overflowWrap: 'anywhere',
  },
  updateButtonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  divider: {
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      marginLeft: `-${size.m(theme)}`,
    },
  },
}));

export const CommPrefEmail: React.FC<CommPrefEmailProps> = (props) => {
  const { email, onBoardingUpdate, disableUpdates } = props;

  const [updateEmail, setUpdateEmail] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const { reset, disableButton, errorMessage, setErrorMessage, onSave, formProps } = useUpdateEmail(email);
  const { useContext } = useOnSaveContext();

  const classes = useStyles();

  const updateButtonHeapId = getHeapIdent(HeapLocation.acctprefs, Target.emailUpdateButton);
  const cancelButtonHeapId = getHeapIdent(HeapLocation.acctprefs, Target.emailCancelButton);

  useEffect(() => {
    if (!updateEmail) {
      reset();
      setErrorMessage(null);
    }
  }, [updateEmail]);

  const handleModifyEmail = () => {
    if (onBoardingUpdate) {
      onBoardingUpdate(TaskStep.Update_Comm_Pref_Email);
    } else {
      setUpdateEmail(!updateEmail);
    }
  };

  const handleOnSave = async () => {
    setIsSaving(true);

    const response = await onSave();

    useContext.updateSnackbar({ showSnackbar: response });
    setUpdateEmail(!response);
    setIsSaving(false);
  };

  return (
    <>
      <Grid container alignItems="center" wrap="nowrap" className={classes.container}>
        <Grid style={{ display: 'flex' }}>
          <Grid item className={classes.iconContainer}>
            <Icon className={`fal fa-envelope ${classes.iconColor} ${classes.iconFontSize}`} />
          </Grid>
          <Grid item className={classes.infoContainer}>
            <Grid item>
              <Typography variant="button">{ProfileAndSettingsText.contactInformation.updateEmail.title}</Typography>
              <Typography variant="body2" className={classes.wrapEmail}>
                {email}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.updateButtonContainer}>
          <DsPrimaryButton
            text={updateEmail ? ProfileAndSettingsText.contactInformation.cancelText : ProfileAndSettingsText.contactInformation.updateText}
            id={updateEmail ? cancelButtonHeapId : updateButtonHeapId}
            variant="text"
            onClick={handleModifyEmail}
            className={classes.updateButton}
            disabled={disableUpdates}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {!onBoardingUpdate && <DsDivider className={classes.divider} />}
      </Grid>
      {updateEmail && (
        <ModifyEmail
          formProps={formProps}
          handleOnSave={handleOnSave}
          disableButton={disableButton || disableUpdates === true}
          errorMessage={errorMessage}
          isLoading={isSaving}
        />
      )}
    </>
  );
};
