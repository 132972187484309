import React from 'react';
import { isMobile } from 'react-device-detect';
import { makeStyles, Typography } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
import { DsExternalButtonLink } from '@mvp/design-system';

const useStyles = makeStyles(() => ({
  baseStyles: {
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
  mobilePhoneLink: {
    textDecoration: 'none',
  },
}));

interface PhoneLineProps {
  phoneNumber: string;
  variant?: Variant;
  color?: 'initial' | 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error';
  id?: string;
  className?: string;
  altDisplayText?: string;
  isButton?: boolean;
  displayAltTextInDesktop?: boolean;
}

export const PhoneLine = ({
  phoneNumber,
  variant = 'h5',
  color = 'primary',
  id,
  className,
  altDisplayText,
  isButton = false,
  displayAltTextInDesktop = false,
}: PhoneLineProps) => {
  const classes = useStyles();

  const purePhoneNumber = phoneNumber.replace(/-/g, '');

  const typographyClassName = `${classes.baseStyles} ${className}`;

  if (!isMobile) {
    return (
      <Typography variant={variant} color={color} display="inline" className={typographyClassName}>
        {displayAltTextInDesktop ? altDisplayText : phoneNumber}
      </Typography>
    );
  }

  return isButton ? (
    <DsExternalButtonLink
      id={id}
      text={altDisplayText ?? phoneNumber}
      href={`tel:+${purePhoneNumber}`}
      color="primary"
      variant="contained"
    />
  ) : (
    <a href={`tel:+${purePhoneNumber}`} className={classes.mobilePhoneLink} id={id}>
      <Typography variant={variant} color={color} display="inline" className={typographyClassName}>
        <span>{altDisplayText ?? phoneNumber}</span>
      </Typography>
    </a>
  );
};
