import React, { useEffect, useState } from 'react';
import { Dialog, Grid, IconButton, Typography, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { RecertModel, RecertDisplayText, RecertCategoryGroupEnum } from '../../models/InitInfoResult.model';
import { invalidateQuery, QueryKey } from '../../ReactQueryClientProvider';
import { DsCard, DsCardContent, DsIcon, size, DsText, DsExternalTextLink } from '@mvp/design-system';
import { formatDate } from '../../utils/dates';
import { StorageKey, StorageLocation, StorageProvider } from '../../utils/storage';
import { HeapLocation, Target, getHeapIdent } from '../../models/heapIdentifiers.model';
import { PhoneLine } from '../../components/PhoneLine';
import { useHeap } from '../../hooks/useHeap';
import { CommonSsoTooltip } from './../CommonSsoTooltip';
import { GetRecertCategoryGroup, GetRecertMessage } from './RecertUtils';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
  actionContainer: {
    borderTop: '1px solid #DDDAD7',
    padding: size.m(theme),
  },
  card: {
    [theme.breakpoints.down('xs')]: {
      width: '90vw',
    },
  },
  dialogPaper: {
    borderRadius: '4px',
    [theme.breakpoints.down('xs')]: {
      margin: '0',
    },
  },
  closeIcon: {
    height: '2.5rem',
  },
  headerContainer: {
    padding: size.m(theme),
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    [theme.breakpoints.down('xs')]: {
      padding: `${size.xs(theme)} ${size.m(theme)}`,
    },
  },
  link: {
    fontWeight: 600,
    textDecoration: 'underline',
  },
  cardContent: {
    padding: `${size.m(theme)} ${size.m(theme)}`,
  },
  bodyMessage: {
    fontSize: '1.125rem',
    lineHeight: '1.75rem',
  },
  headerTitle: {
    fontWeight: 600,
    lineHeight: '2.75rem',
    fontSize: '1.75rem',
  },
  toolTipPopper: {
    zIndex: 10000,
  },
  phoneLine: {
    fontSize: '1.125rem',
  },
}));

const renewModalViewHeapId = getHeapIdent(HeapLocation.recert, Target.recertRenewModalView);
const renewContactNumberHeapId = getHeapIdent(HeapLocation.recert, Target.recertRenewContactNumber);
const renewRenewalWebLinkHeapId = getHeapIdent(HeapLocation.recert, Target.recertRenewRenewalWebLink);
const renewRenewalSpecialistNumberHeapId = getHeapIdent(HeapLocation.recert, Target.recertRenewRenewalSpecialistNumber);
const deadlineMissedModalViewHeapId = getHeapIdent(HeapLocation.recert, Target.recertDeadlineMissedModalView);
const deadlineMissedContactNumberHeapId = getHeapIdent(HeapLocation.recert, Target.recertDeadlineMissedContactNumber);
const deadlineMissedRenewalWebLinkId = getHeapIdent(HeapLocation.recert, Target.recertDeadlineMissedRenewalWebLink);
const deadlineMissedRenewalSpecialistNumberHeapId = getHeapIdent(HeapLocation.recert, Target.recertDeadlineMissedRenewalSpecialistNumber);

const RecertModal: React.FC<{ recertData: RecertModel | undefined }> = ({ recertData }) => {
  const classes = useStyles();

  const [showModal, setShowModal] = useState<boolean>(false);

  const [displayText, setDisplayText] = useState<RecertDisplayText | null>(null);

  const [currentCatGroup, setCurrentCategoryGroup] = useState<RecertCategoryGroupEnum | null>(null);

  const { sendCustomEvent } = useHeap();

  const onCloseModal = () => {
    setShowModal(false);
    invalidateQuery(QueryKey.InitInfo);
  };

  const updateStorageData = () => {
    StorageProvider.setItem(StorageLocation.sessionStorage, StorageKey.recertModalViewFlag, `isViewed`);
  };

  useEffect(() => {
    if (recertData) {
      setDisplayText(GetRecertMessage(recertData.recertCategory, formatDate(recertData.hireDate)));
      setCurrentCategoryGroup(GetRecertCategoryGroup(recertData.recertCategory));
    }
  }, [recertData]);

  useEffect(() => {
    if (recertData && displayText && !StorageProvider.getItem(StorageLocation.sessionStorage, StorageKey.recertModalViewFlag)) {
      setShowModal(recertData.visible);
      updateStorageData();

      if (recertData.visible) {
        sendCustomEvent(currentCatGroup === RecertCategoryGroupEnum.Group60 ? renewModalViewHeapId : deadlineMissedModalViewHeapId);
      }
    }
  }, [recertData, displayText]);

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={showModal}
      style={{ zIndex: 10000 }}
      maxWidth={'md'}
      onClose={() => {
        return;
      }}
    >
      <DsCard topColorVariant="primary" className={classes.card}>
        <Grid container className={classes.headerContainer} justifyContent="space-between" wrap="nowrap">
          <Typography variant="h4" className={classes.headerTitle}>
            {displayText?.Title}
          </Typography>
          <IconButton onClick={onCloseModal} className={classes.closeIcon}>
            <DsIcon title="" icon="times" />
          </IconButton>
        </Grid>
        <BodyContent recertData={recertData} categoryGroup={currentCatGroup} />
        <Grid container alignContent="center" className={classes.actionContainer} alignItems="center" direction="column">
          <FooterContent categoryGroup={currentCatGroup} />
        </Grid>
      </DsCard>
    </Dialog>
  );
};

export default RecertModal;

export const BodyContent: React.FC<{ recertData: RecertModel | undefined; categoryGroup: RecertCategoryGroupEnum | null }> = ({
  recertData,
  categoryGroup,
}) => {
  const classes = useStyles();

  if (!recertData || !categoryGroup) return <DsCardContent />;

  var displayText = GetRecertMessage(recertData.recertCategory, formatDate(recertData.hireDate));

  var healthNyGovLink = displayText.healthNyGovLink ? (
    <CommonSsoTooltip classes={{ popper: classes.toolTipPopper }} placement="bottom">
      <DsExternalTextLink href="https://health.ny.gov" text={displayText.healthNyGovLink} openInNewTab />
    </CommonSsoTooltip>
  ) : (
    <></>
  );

  return (
    <DsCardContent className={classes.cardContent}>
      <DsText variant="body1" className={classes.bodyMessage} data-testid="recert-modal-body-text">
        {displayText.PrependBody}
        {healthNyGovLink}
        {displayText.healthNyGovLinkAppendText}
        <PhoneLine
          phoneNumber={displayText.PhoneNumber}
          id={categoryGroup === RecertCategoryGroupEnum.Group60 ? renewContactNumberHeapId : deadlineMissedContactNumberHeapId}
          className={classes.phoneLine}
        />
        {displayText.MidBody}
        <DsExternalTextLink
          className={classes.link}
          href="https://www.mvphealthcare.com/plans/renew-plan"
          text="renewal web page"
          openInNewTab
          id={categoryGroup === RecertCategoryGroupEnum.Group60 ? renewRenewalWebLinkHeapId : deadlineMissedRenewalWebLinkId}
        />
        {displayText.AppendBody}
      </DsText>
    </DsCardContent>
  );
};

export const FooterContent: React.FC<{ categoryGroup: RecertCategoryGroupEnum | null }> = ({ categoryGroup }) => {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const phoneLineHeapId =
    categoryGroup === RecertCategoryGroupEnum.Group60 ? renewRenewalSpecialistNumberHeapId : deadlineMissedRenewalSpecialistNumberHeapId;

  return isExtraSmall && isMobile ? (
    <PhoneLine
      variant="h6"
      phoneNumber="1-844-687-1235"
      id={phoneLineHeapId}
      altDisplayText="Call an MVP Renewal Specialist"
      isButton={true}
    />
  ) : (
    <>
      <Typography variant="h6" color="primary">
        Call an MVP Renewal Specialist
      </Typography>
      <PhoneLine variant="h6" phoneNumber="1-844-687-1235" id={phoneLineHeapId} />
    </>
  );
};
