import { createContext, useContext } from 'react';
import { QueryObserverResult, RefetchOptions } from 'react-query';
import { OnboardingData } from '../models';
import { CommunicationPreferences } from '../models/communicationPreferences.model';
import { QueryStatusState } from '../models/reactQuery.model';

export interface QueryResponse<T> {
  data: T | null | undefined;
  refetch: ((options?: RefetchOptions | undefined) => Promise<QueryObserverResult<T | null, unknown>>) | undefined;
  isFetching: boolean;
  status: QueryStatusState;
}

export interface OnboardingContextData {
  onboardingWidget: QueryResponse<OnboardingData>;
  commPref: QueryResponse<CommunicationPreferences>;
}

export const OnboardingContext = createContext<OnboardingContextData>({
  onboardingWidget: {
    data: undefined,
    refetch: undefined,
    isFetching: false,
    status: QueryStatusState.idle,
  },
  commPref: {
    data: undefined,
    refetch: undefined,
    isFetching: false,
    status: QueryStatusState.idle,
  },
});

export const useOnboardingContext = () => useContext(OnboardingContext);
