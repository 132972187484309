import React from 'react';
import { Control, RegisterOptions, Path, FieldValues, useController, FieldError } from 'react-hook-form';
import {
  DsCurrencyTextField,
  DsPhoneNumber,
  DsTextField,
  DsTextFieldProps,
  DsTaxIdField,
  Variant,
  DsCombinedZipCode,
  DsZipCode,
} from '@mvp/design-system';

export interface ControlledTextFieldProps<T extends FieldValues> {
  name: Path<T>;
  control: Control<T>;
  helperText?: string;
  inputProps: Omit<DsTextFieldProps, 'onChange' | 'value'>;
  validation?: RegisterOptions;
  variant?: Variant;
  addMarginBottomOnError?: boolean;
  onChange?: () => void;
  error?: FieldError;
}

export function ControlledTextField<T extends FieldValues>({
  control,
  inputProps,
  name,
  helperText,
  error,
  variant,
  onChange,
  addMarginBottomOnError = false,
}: ControlledTextFieldProps<T>) {
  const {
    field: { ref, ...field },
    fieldState: { invalid },
  } = useController({ name, control });

  const showError = () => {
    if (error !== undefined) {
      return !!error;
    }

    return invalid;
  };

  const getHelperText = () => {
    if (error !== undefined) {
      return helperText;
    }

    return invalid && helperText;
  };

  return (
    <DsTextField
      helperText={getHelperText()}
      error={showError()}
      {...field}
      onChange={(event) => {
        field.onChange(event);
        if (onChange) onChange();
      }}
      value={field.value as string}
      {...inputProps}
      paletteVariant={variant}
      style={{ marginBottom: invalid && addMarginBottomOnError ? '1rem' : 0 }}
    />
  );
}

export function ControlledCurrencyField<T extends FieldValues>({
  control,
  inputProps,
  name,
  helperText,
  variant,
  onChange,
}: ControlledTextFieldProps<T>) {
  const {
    field: { ref, ...field },
    fieldState: { invalid },
  } = useController({ name, control });

  return (
    <DsCurrencyTextField
      helperText={invalid && helperText}
      error={invalid}
      {...field}
      onChange={(event) => {
        field.onChange(event);
        if (onChange) onChange();
      }}
      value={field.value as string}
      {...inputProps}
      paletteVariant={variant}
    />
  );
}

export function ControlledPhoneNumberField<T extends FieldValues>({
  control,
  inputProps,
  name,
  helperText,
  variant,
  onChange,
}: ControlledTextFieldProps<T>) {
  const {
    field: { ref, ...field },
    fieldState: { invalid },
  } = useController({ name, control });

  return (
    <DsPhoneNumber
      helperText={invalid && helperText}
      error={invalid}
      {...field}
      onChange={(event) => {
        field.onChange(event);
        if (onChange) onChange();
      }}
      value={field.value as string}
      {...inputProps}
      paletteVariant={variant}
    />
  );
}

export function ControlledZipCodeField<T extends FieldValues>({
  control,
  inputProps,
  name,
  helperText,
  variant,
  onChange,
}: ControlledTextFieldProps<T>) {
  const {
    field: { ref, ...field },
    fieldState: { invalid },
  } = useController({ name, control });

  return (
    <DsCombinedZipCode
      helperText={invalid && helperText}
      error={invalid}
      {...field}
      onChange={(event) => {
        field.onChange(event);
        if (onChange) onChange();
      }}
      value={field.value as string}
      {...inputProps}
      paletteVariant={variant}
    />
  );
}

export function ControlledBaseZipCodeField<T extends FieldValues>({
  control,
  inputProps,
  name,
  helperText,
  variant,
  onChange,
}: ControlledTextFieldProps<T>) {
  const {
    field: { ref, ...field },
    fieldState: { invalid },
  } = useController({ name, control });

  return (
    <DsZipCode
      helperText={invalid && helperText}
      error={invalid}
      {...field}
      onChange={(event) => {
        field.onChange(event);
        if (onChange) onChange();
      }}
      value={field.value as string}
      {...inputProps}
      paletteVariant={variant}
    />
  );
}

export function ControlledTaxNumberField<T extends FieldValues>({
  control,
  inputProps,
  name,
  helperText,
  variant,
  onChange,
}: ControlledTextFieldProps<T>) {
  const {
    field: { ref, ...field },
    fieldState: { invalid },
  } = useController({ name, control });

  return (
    <DsTaxIdField
      helperText={invalid && helperText}
      error={invalid}
      {...field}
      onChange={(event) => {
        field.onChange(event);
        if (onChange) onChange();
      }}
      value={field.value as string}
      {...inputProps}
      paletteVariant={variant}
    />
  );
}
