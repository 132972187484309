import { Grid, makeStyles, Typography } from '@material-ui/core';
import { DsIcon, DsText, size } from '@mvp/design-system';
import React, { useState } from 'react';
import { useUpdateEmail } from '../../../../hooks/commPref/useUpdateEmail';
import { useOnboardingContext } from '../../../../hooks/useOnboardingContext';
import { OnboardingStepProps, TaskStep } from '../../../../models';
import { getHeapIdent, HeapLocation, Target } from '../../../../models/heapIdentifiers.model';
import { ModifyEmail } from '../../../../pages/CommunicationPreferences/components/email/ModifyEmail';
import { OnboardingText } from '../../OnboardingText';
import ModifyContentTemplate from '../ModifyContentTemplate';
import { useModalSaveStatus } from '../useModalSaveStatus';

const useStyles = makeStyles((theme) => ({
  divider: {
    borderTop: '0.063rem solid',
    borderTopColor: theme.palette.grey[100],
    justifySelf: 'flex-end',
  },
  bottomMargin: {
    marginBottom: size.xs(theme),
  },
  text: {
    [theme.breakpoints.up('md')]: {
      paddingRight: '10rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: '1.75rem',
    },
  },
  textContainer: {
    flexGrow: 1,
  },
  subtitle: {
    fontWeight: 600,
  },
  nextStepButton: {
    padding: `${size.xxs(theme)} ${size.l(theme)}`,
    margin: `${size.m(theme)} 0 0`,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  title: {
    [theme.breakpoints.only('xs')]: {
      fontSize: '2rem',
    },
  },
  emailIcon: {
    color: theme.palette.primary.main,
    fontSize: '2.25rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },
  emailTitle: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  emailDetailsContainer: {
    marginBottom: '1.75rem',
  },
  emailInfoContainer: {
    marginLeft: size.m(theme),
  },
  modifyEmailContainer: {
    marginLeft: '-0.375rem',
  },
}));

const UpdateEmail: React.FC<OnboardingStepProps> = ({ goToStep }) => {
  const { commPref } = useOnboardingContext();
  const { useContext } = useModalSaveStatus();

  const advanceStepId = getHeapIdent(HeapLocation.onboarding_modal, Target.onBoardingEmailUpdateSaveButton);
  const goBackStepId = getHeapIdent(HeapLocation.onboarding_modal, Target.onBoardingEmailUpdateCancelButton);

  const classes = useStyles();

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const { disableButton, errorMessage, onSave, formProps } = useUpdateEmail(commPref.data?.email || '');

  const goBackToMainScreen = () => {
    goToStep && goToStep(TaskStep.Update_Comm_Pref);
  };

  const handleOnSave = async () => {
    setIsSaving(true);
    const response = await onSave();

    useContext.commPrefSaveStatus.updateValue && useContext.commPrefSaveStatus.updateValue(response);

    if (response) {
      goBackToMainScreen();
    }

    setIsSaving(false);
  };

  return (
    <ModifyContentTemplate
      goBack={{ function: goBackToMainScreen, text: OnboardingText.cancelText, id: goBackStepId }}
      advanceStep={{
        function: handleOnSave,
        text: OnboardingText.saveChangesText,
        id: advanceStepId,
        disabled: disableButton,
        loading: isSaving,
      }}
    >
      <DsText className={`${classes.bottomMargin} ${classes.title}`} variant="h1">
        {OnboardingText.commPref.updateEmail.title}
      </DsText>
      <DsText className={`${classes.bottomMargin} ${classes.text}`} variant="subtitle2">
        {OnboardingText.commPref.updateEmail.subtitle}
      </DsText>
      <Grid container alignItems="center" className={classes.emailDetailsContainer}>
        <DsIcon title="Email" icon={`fal fa-envelope ${classes.emailIcon}`} />
        <Grid className={classes.emailInfoContainer}>
          <Typography variant="body1" className={classes.emailTitle}>
            {OnboardingText.commPref.updateEmail.userEmailTitle}
          </Typography>
          {commPref.data?.email}
        </Grid>
      </Grid>
      <Grid className={classes.modifyEmailContainer}>
        <ModifyEmail
          formProps={formProps}
          handleOnSave={onSave}
          disableButton={disableButton}
          errorMessage={errorMessage}
          isOnboarding={true}
        />
      </Grid>
    </ModifyContentTemplate>
  );
};

export default UpdateEmail;
