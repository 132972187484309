import { useMsal } from '@azure/msal-react';
import { Box, CssBaseline, Grid, Hidden, makeStyles } from '@material-ui/core';
import { size } from '@mvp/design-system';
import * as $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { communicationPreferencesProvider } from './api/communicationPreferencesProvider';
import { memberInformationProvider } from './api/memberInformationProvider';
import { onboardingProvider } from './api/onboardingProvider';
import AppRoutes from './AppRoutes';
import { sessionTimeoutInSeconds, sessionTimeoutIntervalCheckInSeconds, userNameKey } from './authConfig';
import { setIsGhostAdminInCookie } from './components/AuthProvider';
import Footer from './components/Footer';
import Header from './components/Header';
import OnboardingModal from './components/onboarding/OnboardingModal';
import PortalSidebar from './components/PortalSidebar';
import { ScrollToTop } from './components/ScrollToTop';
import Snackbar from './components/Snackbar';
import isFeatureEnabled, { FeatureToggleKeys } from './hooks/useFeature';
import { useIdleTimer } from './hooks/useIdleTimer';
import { IdTokenClaims, IsMember, useIdTokenClaims } from './hooks/useIdTokenClaims';
import { useNavigationItems } from './hooks/useNavigationItems';
import { OnboardingContext, OnboardingContextData } from './hooks/useOnboardingContext';
import { QueryStatusState } from './models/reactQuery.model';
import { useMessageCount } from './pages/SecureMessaging/hooks/useMessageCount';
import SecureMessagingText from './pages/SecureMessaging/SecureMessagingText';
import { discardMessageQueryParam } from './pages/SecureMessaging/utils/queryParams';
import ReactQueryClientProvider, { QueryKey, removeQuery } from './ReactQueryClientProvider';
import { routes, routesConfig } from './routesConfig';
import { sharedUrls } from './sharedUrls';
import { determineAge, determineAgeCategory } from './utils/age';
import isSubscriber from './utils/isSubscriber';
import { getQueryParam } from './utils/queryParam';
import { InactivityCheckAsync, lastInteractionTimerKey } from './utils/sessionTimeout';
import { StorageKey, StorageLocation, StorageProvider } from './utils/storage';

import { initInfoProvider } from './api/initInfoProvider';
import { isGhostUser } from './components/AuthProvider';
import GenesysChat from './components/GenesysChat';
import RecertModal from './components/MedicaidRecert/RecertModal';
import { SnackbarContextProvider } from './components/SnackbarContextProvider';
import { MemberFilterContextProvider } from './components/MemberFilterContextProvider';

const useStyles = makeStyles((theme) => ({
  content: {
    minHeight: '100vh',
    display: 'block',
    padding: `2.5rem 3.5rem 2.5rem 4.5rem`,
    [theme.breakpoints.down('lg')]: {
      padding: `2.5rem 2rem 2.5rem 2.5rem`,
    },
    [theme.breakpoints.down('sm')]: {
      padding: size.m(theme),
    },
    flexGrow: 1,
    overflowX: 'hidden',
  },
  container: {
    width: '100%',
    display: 'flex',
    boxSizing: 'border-box',
  },
}));

function AppContent() {
  const classes = useStyles();
  const idToken = useIdTokenClaims();
  const { replace } = useHistory();
  const location = useLocation();
  const { i18n } = useTranslation();

  const [claims, setClaims] = useState<IdTokenClaims>();
  const [snackbarMessage, setSnackbarMessage] = useState<string>();

  const { data: memberInfo, refetch } = useQuery(QueryKey.MemberInformation, memberInformationProvider.getMemberInformation, {
    enabled: false,
    retryOnMount: false,
  });
  const { categorizedLinks, isLoading, errorState } = useNavigationItems();

  useEffect(() => {
    if (location.search === discardMessageQueryParam) {
      location.search = '';
      replace(location.pathname);
      setSnackbarMessage(SecureMessagingText.snackbar.discard);
    }
  }, [location]);

  useEffect(() => {
    if (idToken.claims) {
      setClaims(idToken.claims);
    }
  }, [idToken]);

  useEffect(() => {
    const lgn = navigator.language;
    i18n.changeLanguage(lgn);
  }, [i18n]);

  useEffect(() => {
    StorageProvider.setItem(StorageLocation.localStorage, userNameKey, `${claims?.firstName} ${claims?.lastName}`);
    if (IsMember(claims)) {
      refetch();
    }

    const hashedMemberId = claims?.hashedMemberId;
    const accessCodes = claims?.accessCodes;
    const GroupID = claims?.groupNumber;

    hashedMemberId && (window as any).heap?.identify(hashedMemberId);
    accessCodes && (window as any).heap?.addUserProperties({ AccessCode: accessCodes });
    GroupID && (window as any).heap?.addUserProperties({ GroupID: GroupID });
    claims?.memberId && (window as any).heap?.addUserProperties({ MemberType: isSubscriber(claims.memberId) ? 'Subscriber' : 'Dependent' });
  }, [claims]);

  useEffect(() => {
    if (memberInfo && memberInfo.length) {
      const age = determineAge(memberInfo[0].dateOfBirth).years;
      const category = age && determineAgeCategory(age);
      category && (window as any).heap?.addUserProperties({ MemberAge: category });
      (window as any).heap?.addUserProperties({ MemberStatus: memberInfo[0].status.id });
    }
  }, [memberInfo]);

  const onboardingIsEnabled = isFeatureEnabled(FeatureToggleKeys.Onboarding);
  const showWellBeingBanner = isFeatureEnabled(FeatureToggleKeys.WellBeingReimbursementBanner);

  const { data: onboardingData, refetch: onboardingRefetch, status: onboardingStatus, isFetching: isOnboardingFetching } = useQuery(
    QueryKey.Onboarding,
    onboardingProvider.getOnboardingLinks,
    {
      enabled: onboardingIsEnabled && !StorageProvider.getItem(StorageLocation.sessionStorage, StorageKey.hasSeenOnboarding),
      retryOnMount: false,
      onSuccess: (data) => {
        if (!data.shouldViewOnboarding) {
          StorageProvider.setItem(StorageLocation.sessionStorage, StorageKey.hasSeenOnboarding, `true`);
        }
        StorageProvider.setItem(StorageLocation.sessionStorage, StorageKey.onboardingTasks, JSON.stringify(data.tasks));
      },
    }
  );

  const { data: initInfoData, isSuccess: isInitInfoSuccess } = useQuery(QueryKey.InitInfo, initInfoProvider.getInitInfo, {
    retryOnMount: false,
  });

  const { data: commPrefData, refetch: commPrefRefetch, status: commPrefStatus, isFetching: isCommPrefFetching } = useQuery(
    QueryKey.NewCommunicationPreference,
    communicationPreferencesProvider.getNewCommunicationPreferences,
    {
      enabled: !!onboardingData?.shouldViewOnboarding,
    }
  );

  const { threadCount } = useMessageCount({
    onSuccess: (data) => {
      if (data?.inbox > 0) {
        removeQuery(QueryKey.SecureMessagingInbox);
      }

      if (data?.archive > 0) {
        removeQuery(QueryKey.SecureMessagingArchive);
      }
    },
  });

  const redirectUrl = getQueryParam('url');

  const onboardingContextData: OnboardingContextData = {
    onboardingWidget: {
      data: onboardingData,
      refetch: onboardingRefetch,
      isFetching: isOnboardingFetching,
      status: onboardingStatus as QueryStatusState,
    },
    commPref: {
      data: commPrefData,
      refetch: commPrefRefetch,
      isFetching: isCommPrefFetching,
      status: commPrefStatus as QueryStatusState,
    },
  };

  return (
    <div>
      <Snackbar open={!!snackbarMessage} message={snackbarMessage} onClose={() => setSnackbarMessage('')} />
      <CssBaseline />
      {!redirectUrl ? (
        <Header
          isWithinReactApp
          categorizedLinks={categorizedLinks}
          errorState={errorState}
          hasNewMessage={!!threadCount?.inbox}
          recertData={showWellBeingBanner ? initInfoData?.recertData : undefined}
          showSecureMessaging={IsMember(claims)}
        />
      ) : null}
      <main>
        <Box className={classes.container}>
          {!redirectUrl ? (
            <Hidden smDown>
              <PortalSidebar isWithinReactApp={true} categorizedLinks={categorizedLinks} errorState={errorState} isLoading={isLoading} />
            </Hidden>
          ) : null}
          <ScrollToTop>
            <Grid container item xs={12} className={classes.content}>
              <MemberFilterContextProvider>
                <OnboardingContext.Provider value={onboardingContextData}>
                  <OnboardingModal onboardingData={onboardingData} />
                  <AppRoutes routes={routesConfig} />
                </OnboardingContext.Provider>
              </MemberFilterContextProvider>
              <RecertModal recertData={initInfoData?.recertData} />
            </Grid>
          </ScrollToTop>
        </Box>
        <Footer removedTitles={['mportal_quicklinks_makeapayment']} />
        {!isGhostUser() && isInitInfoSuccess && !!initInfoData?.featureFlags?.genesysChat && (
          <GenesysChat genesysMemberChat={initInfoData?.genesysMemberChat} />
        )}
      </main>
    </div>
  );
}

const App = ({ setIsGhostFlag = false }: { setIsGhostFlag: boolean }) => {
  const { instance } = useMsal();
  const history = useHistory();
  (window as any).jQuery = $; // Used by Chatbot

  setIsGhostFlag && setIsGhostAdminInCookie(true);

  useEffect(() => {
    return history.listen(async () => {
      //check for last user interaction & set last interaction to now if within session
      const isTimedOut = await InactivityCheckAsync(sessionTimeoutInSeconds, instance);
      if (!isTimedOut) {
        StorageProvider.setItem(StorageLocation.localStorage, lastInteractionTimerKey, Date.now().toString());
      }

      //according to docs clearEventProperties should be called on every page change
      (window as any).heap?.clearEventProperties();
    });
  }, []);

  useIdleTimer({
    instance,
    timeoutInSeconds: sessionTimeoutInSeconds,
    timeoutIntervalCheckInSeconds: sessionTimeoutIntervalCheckInSeconds,
  });

  //redirect (with reload) to signinBlocked page
  if (!StorageProvider.isStorageEnabled()) {
    window.location.replace(sharedUrls.portalUrl + routes.signinBlocked);
  }

  return (
    <ReactQueryClientProvider>
      <SnackbarContextProvider>
        <AppContent />
      </SnackbarContextProvider>
    </ReactQueryClientProvider>
  );
};

export default App;
