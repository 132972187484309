import { QueryStatus } from 'react-query';

export interface BaseQueryStatus {
  queryStatus: QueryStatus;
}

export enum QueryStatusState {
  loading = 'loading',
  idle = 'idle',
  error = 'error',
  success = 'success',
}

export const isQueryLoading = (status: QueryStatus): boolean => {
  return status === QueryStatusState.loading;
};

export const areQueriesLoading = (status: QueryStatus[]) => {
  return status.includes(QueryStatusState.loading);
};

export const isQueryIdle = (status: QueryStatus): boolean => {
  return status === QueryStatusState.idle;
};

export const isQueryLoadingOrIdle = (status: QueryStatus): boolean => {
  return status === QueryStatusState.loading || status === QueryStatusState.idle;
};

export const isQueryError = (status: QueryStatus): boolean => {
  return status === QueryStatusState.error;
};

export const areQueriesErroring = (status: QueryStatus[]) => {
  return status.includes(QueryStatusState.error);
};

export const areQueriesLoadingOrIdle = (status: QueryStatus[]) => {
  return status.includes(QueryStatusState.loading) || status.includes(QueryStatusState.idle);
};

export const isQuerySuccessful = (status: QueryStatus): boolean => {
  return status === QueryStatusState.success;
};
