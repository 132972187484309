import { StatusTypes } from './statusTypes.model';

export enum ClaimSource {
  MEDCO = 'MEDCO',
  FACETS = 'FACETS',
  CAREMARK = 'CAREMARK',
}

export enum ClaimType {
  Facility = 'Facility',
  Medical = 'Medical',
  Dental = 'Dental',
  Pharmacy = 'Pharmacy',
}
export interface ClaimsListingEntry {
  claimId: string;
  displayClaimId: string;
  claimType: ClaimType;
  status: StatusTypes;
  dateOfService: string;
  providerName: string;
  totalBilled: string;
  yourCost: string;
  source: ClaimSource | null;
  memberName: string | null;
  memberId: string;
  groupId: string;
  dateReceived: string | null;
  dateProcessed: string | null;
  facilityPaidTo: string | null;
  admissionDate: string | null;
  admittingFacility: string | null;
  yourSavings: string;
  providerInNetwork: boolean;
  infoIsAgeRestricted: boolean;
  isKodak: boolean;
  disableReadClaim: boolean;
  mvpDiscountAmount: string;
  planPaidAmount: string;
  otherInsuranceAmount: string;
  copayAmount: string;
  deductibleAmount: string;
  coinsuranceAmount: string;
  nonCoveredAmount: string;
}
