import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Page: React.FC<{ title: string }> = ({ title, children }) => {
  const location = useLocation();
  useEffect(() => {
    document.title = title;
  }, [location, title]);

  return <>{children}</>;
};

export default Page;
