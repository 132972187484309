import isFeatureEnabled, { FeatureToggleKeys } from '../../../hooks/useFeature';
import { UpdateAccountMessageCode } from '../../../models/communicationPreferences.model';
import { routes } from '../../../routesConfig';
import { sharedUrls } from '../../../sharedUrls';

const IdCardPageIsEnabled = isFeatureEnabled(FeatureToggleKeys.IdCardsPage);
const idcardRoute = IdCardPageIsEnabled ? routes.idCards : sharedUrls.legacyIdCards;

export interface PhraseDetails {
  link: string;
  linkText: string;
  displayText: string;
}

interface Phrase {
  [key: string]: PhraseDetails;
}

export const phrase: Phrase = {
  [UpdateAccountMessageCode.contactUs.toString()]: {
    link: idcardRoute,
    linkText: 'Member ID card.',
    displayText: 'contact MVPs Customer Care Center at the number on the back of your',
  },
  [UpdateAccountMessageCode.onlySubscriberCanUpdate.toString()]: {
    link: idcardRoute,
    linkText: 'Member ID card.',
    displayText: `contact the subscriber listed on your`,
  },
  [UpdateAccountMessageCode.contactSocialServices.toString()]: {
    link: 'https://www.health.ny.gov/health_care/medicaid/ldss.htm',
    linkText: 'Local Department of Social Services.',
    displayText: 'contact your',
  },
  [UpdateAccountMessageCode.nyStateOfHealth.toString()]: {
    link: 'https://nystateofhealth.ny.gov',
    linkText: 'New York State of Health Official Health Plan Marketplace.',
    displayText: 'contact the',
  },
  [UpdateAccountMessageCode.vermontHealthConnect.toString()]: {
    link: 'https://portal.healthconnect.vermont.gov/VTHBELand/welcome.action',
    linkText: 'Vermont Health Connect.',
    displayText: 'contact the',
  },
  [UpdateAccountMessageCode.contactYourEmployer.toString()]: {
    link: '',
    linkText: '',
    displayText: 'contact your employer.',
  },
};

export enum InfoItems {
  phone = 'phone number',
  address = 'address',
  both = 'address or phone number',
}

export const getUpdateInfoValues = (stateCode: UpdateAccountMessageCode, item: InfoItems) => {
  if (Object.keys(phrase).includes(stateCode)) {
    const status = JSON.parse(JSON.stringify(phrase[stateCode]));
    status.displayText = `To update your ${item}, ${status.displayText}`;

    return status;
  }
  return null;
};
