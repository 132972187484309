export enum HeapLocation {
  nurseadvice = 'nurse-advice',
  claim_status_history = 'csandh',
  header = 'header',
  sidenav = 'sidenav',
  welcomecard = 'welcomecard',
  idcardwidget = 'idcardwidget',
  dlwidget = 'dlwidget',
  important_links_widget = 'important-links-widget',
  payment_widget = 'payment-widget',
  priorauth = 'priorauth',
  submitclaim = 'submit-claim',
  pcp = 'pcp',
  estimate_treatment_cost = 'estimate-treatment-cost',
  csandh = 'csandh',
  spending_account = 'spending-accounts',
  quick_links_widget = 'important-links-widget_link',
  onboarding_modal = 'onboarding-modal',
  communication_preferences = 'communication-preferences',
  deductibles_limits = 'deductibles_limits',
  member_guide_banner = 'member-guide-banner',
  mytaskwidget = 'mytaskwidget',
  contact_us = 'contact-us',
  idcardpage = 'idcardpage',
  mybenefits = 'mybenefits',
  myProfile = 'myprofile',
  acctprefs = 'acctprefs',
  preventive_care_reminder = 'prevcare',
  cookies_error = 'cookies-error',
  taxdocuments = 'taxdocuments',
  taxDocumentsBanner = 'tax-documents',
  wellbeing_reimbursements = 'wellbeing-reimbursements',
  be_well_rewards = 'be_well_rewards',
  documents_center = 'documents_center',
  secure_messaging = 'secure-messaging',
  new_message = 'new-message',
  send_message = 'send-message',
  recert = 'recert',
  payments = 'payments',
}

export enum Environment {
  member_portal = 'mportal',
}

export enum Target {
  GetStarted = 'get-started',
  addprimarydoctorbutton = 'add-primary-doctor-button',
  findmydoc = 'find-my-doc-button',
  advancebutton = 'advancebutton',
  gobackbutton = 'gobackbutton',
  printpage = 'printpage',
  togglelanguage = 'togglelanguage',
  printidcard = 'printidcard',
  submitbutton = 'submitbutton',
  resetbutton = 'resetbutton',
  closebutton = 'closebutton',
  backbutton = 'backbutton',
  replybutton = 'replybutton',
  backdrop = 'backdrop',
  memberselector = 'memberselector',
  gialink = 'gialink',
  logo = 'logo',
  exitbutton = 'exit-button',
  contactus = 'contactus',
  signout = 'signout',
  mydashboard = 'mydashboard',
  link_tellus = 'link_tellus',
  link_wellbeingcompain = 'link_wellbeingcompain',
  link_contactus = 'link_contactus',
  link_ordercard = 'link_ordercard',
  link_to_csandh = 'link_to_csandh',
  link_to_dandl = 'link_to_dandl',
  showback = 'showback',
  showfront = 'showfront',
  ordercard = 'ordercard',
  flip_button = 'flip_button',
  ordercardmodal = 'ordercardmodal',
  tablink_deductible = 'tablink_deductible',
  tablink_outofpocket = 'tablink_outofpocket',
  link_limitdetails = 'link_limitdetails',
  filterbutton = 'filterbutton',
  typeselector = 'typeselector',
  advancedoptions = 'advancedoptions',
  searchbutton = 'searchbutton',
  acceptbutton = 'acceptbutton',
  cancelbutton = 'cancelbutton',
  estimatetreatmentcostbutton = 'estimate-treatment-cost-button',
  benefitsdetailsbutton = 'benefitsdetailsbutton',
  treatmentcostcalculatorlink = 'treatment-cost-calculator-link',
  fairhealthcalculatorlink = 'fair-health-calculator-link',
  datepresetselector = 'datepresetselector',
  startDatePicker = 'start-date-picker',
  endDatePicker = 'end-date-picker',
  vieweoblink = 'view-eob-link',
  viewadllink = 'view-adl-link',
  med_claim_form_pdf_link = 'med-claim-form-pdf-link',
  emaillink = 'emaillink',
  email_checkbox = 'email-checkbox',
  patient_name_selector = 'patient-name-selector',
  attachment = 'attachment',
  allattachments = 'allattachments',
  zipcodetab = 'zipcodetab',
  citytab = 'citytab',
  countytab = 'countytab',
  previous_page = 'previouspage',
  boa_link = 'BOAlink',
  benefits_wallet_link = 'BenefitsWalletLink',
  phonenumber = 'phonenumber',
  emailLink = 'emaillink',
  updateresultsbutton = 'updateresultsbutton',
  summaryexpandbar = 'summaryexpandbar',
  claimtypeselector = 'claimtypeselector',
  memberGuide = 'view-member-guide',
  selectpcp = 'selectpcp',
  download_csv = 'download_csv',
  commPrefs = 'commprefs',
  accountDropdown = 'accountdropdown',
  mybenefitslink = 'mybenefitslink',
  emailPrefRadioBtn = 'emailPrefRadioBtn',
  phonePrefRadioBtn = 'phonePrefRadioBtn',
  spendingAccountsLink = 'spendingaccounts',
  fsaLink = 'view-fsa-link',
  accountUpdateNowLink = 'account-update-now-link',
  homeAddressAccordion = 'home-address-accordion',
  mailingAddressAccordion = 'mailing-address-accordion',
  sameAsHomeAddressCheckbox = 'home-address-checkbox',
  modifyAddressSaveButton = 'address-save-changes-button',
  updateAddressButton = 'address-update-button',
  cancelAddressButton = 'address-cancel-button',
  phoneUpdateButton = 'phone-update-button',
  phoneCancelButton = 'phone-cancel-button',
  modifyPhoneSaveButton = 'phone-save-changes-button',
  preventiveCareESupportLink = 'eSupport-link',
  emailUpdateButton = 'email-update-button',
  emailCancelButton = 'email-cancel-button',
  modifyEmailSaveButton = 'email-save-changes-button',
  visionButton = 'vision',
  prescriptionButton = 'manageprescriptions',
  deltaDental = 'manage-delta-dental',
  healthPlexDental = 'manage-healthplex-dental',
  libertyDental = 'manage-liberty-dental',
  paperlessToggle = 'paperless-toggle',
  paperlessAccordion = 'paperless-accordion',
  careDocsMailRadioButton = 'care-docs-mail-radio',
  careDocsEmailRadioButton = 'care-docs-email-radio',
  planDocsMailRadioButton = 'plan-docs-mail-radio',
  planDocsEmailRadioButton = 'plan-docs-email-radio',
  billingDocsMailRadioButton = 'billing-docs-mail-radio',
  billingDocsEmailRadioButton = 'billing-docs-email-radio',
  preventiveCareContactProviderButton = 'contact-provider-button',
  preventiveCareBackArrowButton = 'back-arrow-button',
  profileStepper = 'stepper_profile',
  contactInfoStepper = 'stepper_contact-info',
  accountSettingsStepper = 'stepper_acct-settings',
  commPrefStepper = 'stepper_comm-prefs',
  onBoardingEmailUpdateCancelButton = 'email-cancel-button',
  onBoardingEmailUpdateSaveButton = 'email-save-changes-button',
  deltaDentalURL = 'delta-dental-url',
  healthplexURL = 'healthplex-url',
  libertyDentalURL = 'healthplex-url',
  stayInformedEmailCheckBox = 'emails-checkbox',
  stayInformedPhoneCheckBox = 'phone-checkbox',
  stayInformedTextCheckBox = 'sms-checkbox',
  healthSparqSSO = 'health-sparq-sso',
  cookiesErrorEsupport = 'esupport-link',
  cookiesErrorEnableCookiesLink = 'enable-cookies-link',
  backToSignInButton = 'back-to-sign-in-button',
  memberMedicalGuide = 'member-medical-guide',
  taxDocument = 'tax-document',
  reimbursementPage = 'page',
  reimbursementClaimsLink = 'claims-link',
  reimbursementDownloadLink = 'download-button',
  reimbursmentClaimsEmail = 'claims-email',
  benefitDetailsLink = 'memberdetails',
  taxDocuments = 'banner_viewtaxdocuments',
  taxCustCare = 'banner_contactus',
  beWellRewardsHomepage = 'be-well-rewards-homepage',
  annualWellnessVisitLearnMore = 'annual-wellness-visit-learn-more',
  rewardLearnMore = 'reward-learn-more',
  saveOnPurchases = 'save-on-purchases',
  wellBeingFlyerLink = 'wellbeing-flyer-link',
  costCalculatorVideo = 'video',
  secureMessagingButton = 'secure-messaging-button',
  secureMessagingNewMessage = 'new-message',
  secureMessagingTable = 'table',
  secureMessagingHeaderCheckbox = 'header-checkbox',
  secureMessagingTab = 'tab',
  favoriteIcon = 'icon-favorite',
  archiveIcon = 'icon-archive',
  unFavoriteIcon = 'icon-unfavorite',
  unArchiveIcon = 'icon-unarchive',
  bulkFavoriteIcon = 'icon-bulk-favorite-unfavorite',
  bulkArchiveIcon = 'icon-bulk-archive',
  bulkUnarchiveIcon = 'icon-bulk-unarchive',
  secureMessagingSendButton = 'send-button',
  secureMessagingDiscardButton = 'discard-button',
  secureMessagingDraftButton = 'draft-button',
  secureMessagingTopicSelector = 'topic-selector',
  secureMessagingMemberSelector = 'member-selector',
  secureMessagingBodyInput = 'body',
  secureMessagingBackToInboxButton = 'back-to-inbox-button',
  secureMessagingAttachmentInput = 'upload-attachment-button',
  secureMessagingCancelDiscard = 'cancel-discard-button',
  secureMessagingCancelDiscardIconButton = 'cancel-discard-icon-button',
  secureMessagingDiscard = 'discard-button',
  secureMessagingReadMessageClick = 'read-message-click',
  secureMessagingUnreadMessageClick = 'unread-message-click',
  secureMessagingSortByDateAsc = 'sort-by-date-asc',
  secureMessagingSortByDateDesc = 'sort-by-date-desc',
  secureMessagingEmptyInboxView = 'empty-inbox-view',
  secureMessagingEmptyArchiveView = 'empty-archive-view',
  secureMessagingGetAccountReadyMsgView = 'getting-account-ready-message-view',
  secureMessagingMessageSentSnackbarView = 'message-sent-snackbar-view',
  secureMessagingUnableToSendMsgSnackbarView = 'unable-to-send-message-snackbar-view',
  secureMessagingSuccessMessageSnackbarView = 'message-snackbar-view',
  secureMessagingFailedMessageSnackbarView = 'failed-message-snackbar-view',
  secureMessagingFileUnacceptedSnackbarView = 'file-unaccepted-snackbar-view',
  secureMessagingFileUnacceptedSnackbarLearnMore = 'file-unaccepted-snackbar-learn-more-click',
  secureMessagingUnableToProcessDownloadReqSnackbarView = 'unable_process_download_req_snackbar_view',
  secureMessagingUnableToProcessDownloadReqContactUs = 'unable_process_download_req_contact_us_click',
  recertRenewModalView = 'renew-modal-view',
  recertRenewContactNumber = 'renew-contact-number',
  recertRenewRenewalWebLink = 'renew-renewal-web-link',
  recertRenewRenewalSpecialistNumber = 'renew-renewal-specialist-number',
  recertDeadlineMissedModalView = 'deadline-missed-modal-view',
  recertDeadlineMissedContactNumber = 'deadline-missed-contact-number',
  recertDeadlineMissedRenewalWebLink = 'deadline-missed-renewal-web-link',
  recertDeadlineMissedRenewalSpecialistNumber = 'deadline-missed-renewal-specialist-number',
  recertBannerRenewView = 'banner-renew-view',
  recertBannerRenewContactNumber = 'banner-renew-contact-number',
  recertBannerRenewRenewalWebLink = 'banner-renew-renewal-web-link',
  recertBannerDeadlineMissedView = 'banner-deadline-missed-view',
  recertBannerDeadlineMissedContactNumber = 'banner-deadline-missed-contact-number',
  recertBannerDeadlineMissedRenewalWebLink = 'banner-deadline-missed-renewal-web-link',
  dashboardBeWellRewardsLearnMore = 'dashboard-learn-more',
  customerCareNumber = 'customer-care-number',
  eSupportNumber = 'esupport-number',
  eSupportEmail = 'esupport-email',
  suicideTextChatButton = 'suicide-text-chat-button',
  suicideCallButton = 'suicide-call-button',
  namiCallButton = 'nami-call-button',
  namiEmailButton = 'nami-email-button',
  crisisTextLine = 'crisis-text-line',
  crisisHotline = 'crisis-hotline',
  startMessageButton = 'startMessageBtn',
  docCenterSelectDependentDropdown = 'select-dependent-dropdown',
  docCenterSortDateAsc = 'sort-by-date-asc',
  docCenterSortDateDesc = 'sort-by-date-desc',
  docCenterNoDocumentMsgView = 'no-documents-message-view',
  docCenterGetAccountReadyMsgView = 'getting-account-ready-message-view',
  docCenterUnreadDocumentView = 'unread-document-view',
  docCenterReadDocumentView = 'read-document-view',
  accountSelector = 'account-selector',
  manageAutoPay = 'manage-auto-pay-button',
  setUpAutoPay = 'setup-auto-pay-button',
  manageWallet = 'manage-wallet-button',
  makePayment = 'make-payment-button',
  downloadPBH = 'download-payment-billing-hist',
  outstandingBalance = 'outstanding-balance',
  statementBalance = 'statement-balance',
  otherAmount = 'other-amount',
  continueButton = 'continue-button',
  cancelButton = 'cancel-button',
  successfulSnackbar = 'success-snackbar',
  errorSnackbar = 'error-snackbar',
  inboxMemberFilterErrorSnackbar = 'inbox-member-filter-error-snackbar',
  archiveMemberFilterErrorSnackbar = 'archive-member-filter-error-snackbar',
  inboxMemberFilterErrorSnackbarContactUs = 'inbox-member-filter-error-snackbar-contact-us',
  archiveMemberFilterErrorSnackbarContactUs = 'archive-member-filter-error-snackbar-contact-us',
  invoiceLink = 'invoice-link',
  invoicePdfIcon = 'invoice-pdf-icon',
  payBinderButton = 'pay-binder-button',
  paymentDashboardCard = 'payment-dashboard-card',
  goToPayments = 'go-to-payments',
  binderOutstandingBalance = 'binder-outstanding-balance',
  binderStatementBalance = 'binder-statement-balance',
  binderOtherAmount = 'binder-other-amount',
  binderContinue = 'binder-continue-button',
  binderCancel = 'binder-cancel-button',
}

export enum HTMLDataHeap {
  claims_listings_row = 'claims_listings_row',
  preventative_care_tab = 'preventative_care_tab',
  preventative_care_row = 'reminder-row-next-page-button',
  preventive_care_schedule_card = 'schedule-same-visit-card',
  select_pcp_list_button = 'select-pcp-list-button',
  select_pcp_button = 'select-pcp-button',
  change_pcp_button = 'change-pcp-button',
  wealthcare_links = 'spending-accounts_wealthcarelinks',
}

export const getHeapIdent = (location: HeapLocation | string, target: Target | string) => {
  return `${Environment.member_portal}_${location}_${target}`;
};
