import { CategorizedNavigationLinks, LocationId, NavigationLink } from '../models/navigationLinks.model';
import { History } from 'history';
import { useEffect } from 'react';
import { navigationLinksProvider } from '../api/navigationLinksProvider';
import { DsNavigationItemProps, DsSubNavigationItemProps, DsTooltipProps } from '@mvp/design-system/components';
import { sharedUrls } from '../sharedUrls';
import { useStoreon } from 'storeon/react';
import { NavLinksEvents, NavLinksReducerActions, NavLinksState } from '../storeProviders/reducers';

const isSelfServiceUrl = (url: URL): boolean => {
  const legacy = sharedUrls.legacyPortalDomain.toLowerCase();
  return url
    .toString()
    .toLowerCase()
    .startsWith(legacy + '/');
};

const mapLinkToSubItemProps = (link: NavigationLink, history: History, isWithinReactApp: boolean): DsSubNavigationItemProps | null => {
  try {
    const url = new URL(link.url);
    const tooltipProps = link.externalConfirmText
      ? ({ title: "This link takes you away from MVP's website" } as DsTooltipProps)
      : undefined;
    const baseLink = {
      title: link.label,
      analyticsID: link.analyticsID,
      selected: link.selected,
      tooltipProps,
    };
    if (link.isGhostUserLink) {
      return {
        ...baseLink,
        onClick: () => {},
      };
    }
    return isWithinReactApp && !isSelfServiceUrl(url) && !link.isExternal
      ? {
          ...baseLink,
          onClick: () => {
            history?.push(url.pathname);
          },
        }
      : {
          ...baseLink,
          href: link.url,
          external: link.isExternal,
          referrer: link.referrer,
        };
  } catch (error) {
    return null;
  }
};

const nonNestedLink = (categorizedLink: CategorizedNavigationLinks, history: History, isWithinReactApp: boolean) => {
  const link = categorizedLink.links[0];
  const url = new URL(link.url);
  const removeTrailingSlash = (str: string) => str.replace(/\/$/, '').trim();

  const selected = removeTrailingSlash(link.url) === removeTrailingSlash(window.location.href);

  return isWithinReactApp && !isSelfServiceUrl(url) && !link.isExternal
    ? {
        title: link.label,
        selected,
        onClick: () => {
          history?.push(url.pathname);
        },
        analyticsID: link.analyticsID,
      }
    : {
        title: link.label,
        selected,
        href: link.url,
        analyticsID: link.analyticsID,
      };
};

export const mapLinksToProps = (
  categorizedLinks: CategorizedNavigationLinks[],
  isWithinReactApp: boolean,
  history: History
): DsNavigationItemProps[] => {
  return categorizedLinks.map((categorizedLink) => {
    if (categorizedLink.isNested) {
      const mappedSubLinkProps: DsSubNavigationItemProps[] = categorizedLink.links
        .map((link) => mapLinkToSubItemProps(link, history, isWithinReactApp))
        .filter((subItem) => !!subItem) as DsSubNavigationItemProps[];
      return {
        title: categorizedLink.category,
        selected: categorizedLink.links.some((link) => link.selected) || mappedSubLinkProps.some((link) => link.selected),
        subItems: mappedSubLinkProps,
        analyticsID: categorizedLink.analyticsID,
      };
    } else {
      return nonNestedLink(categorizedLink, history, isWithinReactApp);
    }
  });
};

export const setSelected = (categorizedLinks: CategorizedNavigationLinks[]): CategorizedNavigationLinks[] => {
  return categorizedLinks.map((categorizedLink) => ({
    ...categorizedLink,
    links: categorizedLink.links.map((link) => {
      return { ...link, selected: link.url === window.location.href };
    }),
  }));
};

export const useNavigationItems = (
  isWithinReactApp: boolean = true
): {
  isLoading: boolean;
  errorState: boolean;
  categorizedLinks: CategorizedNavigationLinks[];
} => {
  const { navLinksStore, dispatch } = useStoreon<NavLinksState, NavLinksEvents>('navLinksStore');
  const { data: navLinks, errorState, isLoading } = navLinksStore;
  useEffect(() => {
    if ((!navLinks?.length && !isLoading && isWithinReactApp) || (navLinks?.length && !navLinks[0].location)) {
      dispatch(NavLinksReducerActions.updateIsLoading, true);
      dispatch(NavLinksReducerActions.updateErrorStatus, false);
      navigationLinksProvider
        .getAllNavigationLinks()
        .then((data) => {
          dispatch(NavLinksReducerActions.updateNavlinks, setSelected(data));
        })
        .catch(() => {
          dispatch(NavLinksReducerActions.updateErrorStatus, true);
        })
        .finally(() => {
          dispatch(NavLinksReducerActions.updateIsLoading, false);
        });
    }
  }, [navLinks]);

  return {
    isLoading,
    errorState,
    categorizedLinks: navLinks || [],
  };
};

export const filterLinks = (links: CategorizedNavigationLinks[], filter: LocationId[]): CategorizedNavigationLinks[] => {
  return links.filter((link) => link.location && filter.includes(link.location.id));
};

export const removeLinks = (links: CategorizedNavigationLinks[], filter: LocationId[]): CategorizedNavigationLinks[] => {
  return links.filter((link) => link.location && !filter.includes(link.location.id));
};
