import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import config from './config';

//constants
export enum InsightsEventType {
  LoginError = 'msal-login-error',
}

const browserHistory = createBrowserHistory({ basename: '' });
export const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: config.APP_INSIGHTS_CONNECTION_STRING ?? null,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
    correlationHeaderExcludedDomains: ['*.queue.core.windows.net'],
  },
});

appInsights.loadAppInsights();
