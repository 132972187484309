import axiosSingleton from '../components/axios';
import config from '../config';
import { TaskKeys } from '../models';
import { OnboardingData } from '../models/onboarding.model';

interface UpdateOnboardingRequest {
  onboardingTask: TaskKeys;
  isComplete: boolean;
}

export const onboardingProvider = {
  async getOnboardingLinks(): Promise<OnboardingData> {
    const response = await axiosSingleton.get<OnboardingData>(`${config.API_URL}/v1/onboarding-navigation`, {
      headers: {
        accept: 'application/json',
      },
    });
    return response.data;
  },
  async updateOnboardingStatus(request: UpdateOnboardingRequest) {
    await axiosSingleton.patch<UpdateOnboardingRequest>(`${config.API_URL}/v1/onboarding-navigation`, request);
  },
};
