import { UseQueryOptions, useQuery } from 'react-query';
import { QueryKey } from '../../../ReactQueryClientProvider';
import { secureMessagingProvider } from '../../../api/secureMessagingProvider';
import { ThreadCount } from '../../../models/secureMessaging';
import { secureMessagingCacheTime } from '../../../utils/caching';

export const useMessageCount = (
  options?: Omit<UseQueryOptions<ThreadCount, unknown, ThreadCount, QueryKey.NewMessageCount>, 'queryKey' | 'queryFn'>
) => {
  const { data: threadCount, refetch: refetchMessageCount, error, ...rest } = useQuery(
    QueryKey.NewMessageCount,
    secureMessagingProvider.getThreadCount,
    {
      refetchInterval: secureMessagingCacheTime,
      refetchIntervalInBackground: true,
      ...options,
    }
  );

  return { threadCount, refetchMessageCount, error: error as any, ...rest };
};
