import { Box } from '@material-ui/core';
import { DsSpinner } from '@mvp/design-system';
import React from 'react';

interface LoadingContainerProps {
  isLoading: boolean;
  children?: React.ReactNode;
  loadingText?: string;
}

export const LoadingContainer = (props: LoadingContainerProps) => {
  const { isLoading, loadingText, children } = props;
  return isLoading ? (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight={150}
      height={isLoading ? '100%' : undefined}
    >
      {loadingText || null}
      <DsSpinner />
    </Box>
  ) : (
    <>{children}</>
  );
};
