import { makeStyles } from '@material-ui/core';
import { DsText, size } from '@mvp/design-system';
import React from 'react';
import { BaseOnboardingStepProps } from '../../../models';
import { getHeapIdent, HeapLocation, Target } from '../../../models/heapIdentifiers.model';
import ContentTemplate from './ContentTemplate';

const useStyles = makeStyles((theme) => ({
  divider: {
    borderTop: '0.063rem solid',
    borderTopColor: theme.palette.grey[100],
    justifySelf: 'flex-end',
  },
  bottomMargin: {
    marginBottom: size.xs(theme),
  },
  text: {
    [theme.breakpoints.up('md')]: {
      paddingRight: '10rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: '1.75rem',
    },
  },
  textContainer: {
    flexGrow: 1,
  },
  subtitle: {
    fontWeight: 600,
  },
  nextStepButton: {
    padding: `${size.xxs(theme)} ${size.l(theme)}`,
    margin: `${size.m(theme)} 0 0`,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const Welcome: React.FC<BaseOnboardingStepProps> = ({ advanceStep }) => {
  const classes = useStyles();
  const advanceStepId = getHeapIdent(HeapLocation.onboarding_modal, Target.advancebutton);
  return (
    <ContentTemplate advanceStep={{ function: advanceStep, text: 'Choose My Doctor', id: advanceStepId }}>
      <DsText className={classes.bottomMargin} variant="h1">
        Welcome to your MVP Account!
      </DsText>
      <DsText className={`${classes.bottomMargin} ${classes.text}`} variant="subtitle2">
        Thanks for choosing MVP! We’re excited to show you all the great, personalized features of your new online account.
      </DsText>
      <DsText variant="subtitle2" className={classes.subtitle}>
        Let’s start by choosing a Primary Care Provider (PCP).
      </DsText>
    </ContentTemplate>
  );
};

export default Welcome;
