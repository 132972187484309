import { Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { DsPrimaryButton, size, DsIcon, DsSecondaryButton } from '@mvp/design-system';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  divider: {
    borderTop: '0.063rem solid',
    borderTopColor: theme.palette.grey[100],
    justifySelf: 'flex-end',
  },
  textContainer: {
    flexGrow: 1,
  },
  nextStepButton: {
    padding: `${size.xxs(theme)} ${size.l(theme)}`,
    margin: `${size.m(theme)} 0 0`,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  icon: {
    fontSize: '2rem',
    color: theme.palette.primary.main,
    marginRight: size.s(theme),
    fontWeight: 600,
  },
  nextStepSecondaryButton: {
    padding: `${size.xxs(theme)} ${size.l(theme)}`,
    margin: `${size.m(theme)} 0 0`,
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export interface ContentTemplateProps {
  advanceStep?: { function: () => void; text: string; id: string };
  goBack?: { function: () => void; text?: string; id: string };
}

const ContentTemplate: React.FC<ContentTemplateProps> = ({ advanceStep, goBack, children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Grid item lg={12} className={classes.textContainer}>
        {children}
      </Grid>
      <Grid
        className={classes.divider}
        justify={advanceStep && goBack ? 'space-between' : advanceStep ? 'flex-end' : 'flex-start'}
        item
        container
        direction={isMobile ? 'column-reverse' : 'row'}
      >
        {goBack && (
          <DsSecondaryButton
            startIcon={<DsIcon title="go back" icon="angle-left" className={classes.icon} />}
            text={goBack.text || 'Back'}
            onClick={goBack.function}
            className={classes.nextStepSecondaryButton}
            id={goBack.id}
          ></DsSecondaryButton>
        )}
        {advanceStep && (
          <DsPrimaryButton text={advanceStep.text} className={classes.nextStepButton} onClick={advanceStep.function} id={advanceStep.id} />
        )}
      </Grid>
    </>
  );
};

export default ContentTemplate;
