import { makeStyles } from '@material-ui/core';
import { DsSnackbar, DsSnackbarProps } from '@mvp/design-system';
import React, { FC } from 'react';

const useStyles = makeStyles((theme) => ({
  iconOverride: {
    marginRight: '1.5rem',
    fontSize: '2rem',
  },
  baseIconColor: {
    color: 'black',
  },
  closeIconColorNoError: {
    color: 'white'
  },
  learnMoreSpacing: {
    marginLeft: '1.75rem',
  },
  malwareText: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  actionColor: {
    color: '#AF0E2E',
  },
}));

interface SnackbarProps extends DsSnackbarProps {
  isError?: boolean;
  hideIcon?: boolean;
}

const Snackbar: FC<SnackbarProps> = ({ isError = false, hideIcon = false, autoHideDuration = 5000, ...props }) => {
  const classes = useStyles();

  return (
    <DsSnackbar
      {...props}
      snackbarBackgroundColor={isError ? '#F2C44D' : undefined}
      messageColor={isError ? 'black' : undefined}
      faIconName={isError && !hideIcon ? 'fal fa-exclamation-circle' : undefined}
      closeIconStyleOverride={`isError ? ${classes.baseIconColor}: ${classes.closeIconColorNoError}`}
      isClearable={isError || props.isClearable}
      iconStyleOverride={`${classes.iconOverride} ${classes.baseIconColor}`}
      autoHideDuration={autoHideDuration}
    />
  );
};

export default Snackbar;
