import { createContext, useContext, useState } from 'react';
import ProfileAndSettingsText from '../pages/CommunicationPreferences/ProfileAndSettingsText';

export interface ShowSnackbarDetails {
  showSnackbar: boolean;
  errorMessage?: string;
  successMessage?: string;
}

interface SaveStatus extends ShowSnackbarDetails {
  updateSnackbar: (showSnackbarDetails: ShowSnackbarDetails) => void;
  makeApiCall: <T extends object>(
    apiCall: (arg0: T) => Promise<boolean | null>,
    data: T,
    error: string,
    toggleSnackbar?: boolean
  ) => Promise<boolean | null>;
}

export const OnSaveStatusContext = createContext<SaveStatus>({
  showSnackbar: false,
  errorMessage: '',
  successMessage: ProfileAndSettingsText.savedSuccessfulMessage,
  updateSnackbar: (showSnackbarDetails: ShowSnackbarDetails) => {},
  makeApiCall: <T extends object>(apiCall: (arg0: T) => Promise<boolean | null>, data: T, error: string, toggleSnackbar?: boolean) =>
    new Promise(() => false),
});

export const useOnSaveContext = () => {
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>(ProfileAndSettingsText.savedSuccessfulMessage);

  const updateSnackbar = (showSnackbarDetails: ShowSnackbarDetails) => {
    setShowSnackbar(false);
    setTimeout(() => {
      setSuccessMessage(showSnackbarDetails.successMessage || ProfileAndSettingsText.savedSuccessfulMessage);
      setErrorMessage(showSnackbarDetails.errorMessage || '');
      setShowSnackbar(showSnackbarDetails.showSnackbar);
    }, 500);
  };

  const makeApiCall = async <T extends object>(
    apiCall: (arg0: T) => Promise<boolean | null>,
    data: T,
    error: string,
    toggleSnackbar?: boolean
  ) => {
    const shouldToggleSnackbar = typeof toggleSnackbar === 'undefined' ? true : toggleSnackbar;
    const response = await apiCall(data);
    const errorMessage = !response ? error : '';
    if (shouldToggleSnackbar) {
      updateSnackbar({ showSnackbar: true, errorMessage });
    }
    return response;
  };

  const baseData: SaveStatus = {
    showSnackbar,
    errorMessage,
    successMessage,
    updateSnackbar,
    makeApiCall,
  };

  return { context: OnSaveStatusContext, useContext: useContext(OnSaveStatusContext), data: baseData };
};
