import * as yup from 'yup';
import { isZipRegex } from '../../../../utils/string';

export const modifyAddressSchema = yup.object().shape({
  homeAddress: yup.object().shape({
    line1: yup.string().required(),
    line2: yup.string(),
    city: yup.string().required(),
    state: yup.string().required(),
    zipCode: yup.string().matches(isZipRegex).required(),
  }),
  mailingAddress: yup.object().shape({
    line1: yup.string().required(),
    line2: yup.string(),
    city: yup.string().required(),
    state: yup.string().required(),
    zipCode: yup.string().matches(isZipRegex).required(),
  }),
});
