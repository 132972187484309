import React, { useEffect, useState } from 'react';

interface WithComponentToggleDisplayProps<T, K> {
  booleanState: boolean;
  primaryComponentProps?: T;
  secondaryComponentProps?: K;
}

export const ComponentToggleDisplayWrapper = <T extends object, K extends object>(
  PrimaryComponent: React.FC<T>,
  SecondaryComponent: React.FC<K>
): React.FC<WithComponentToggleDisplayProps<T, K>> => {
  const Wrapper = ({ booleanState, primaryComponentProps, secondaryComponentProps }: WithComponentToggleDisplayProps<T, K>) => {
    const [boolean, setBoolean] = useState(booleanState);

    useEffect(() => {
      setBoolean(booleanState);
    }, [booleanState]);

    return (
      <>{boolean ? <SecondaryComponent {...(secondaryComponentProps as K)} /> : <PrimaryComponent {...(primaryComponentProps as T)} />}</>
    );
  };

  return Wrapper;
};
