import { CategorizedNavigationLinks } from '../../models';
import { StoreonModule } from 'storeon';
import { StorageLocation, StorageProvider } from '../../utils/storage';

export interface NavLinksState {
  navLinksStore: {
    data: CategorizedNavigationLinks[];
    isLoading: boolean;
    errorState: boolean;
  };
}

export enum NavLinksReducerActions {
  updateNavlinks = 'UPDATE_NAV_LINKS',
  updateIsLoading = 'UPDATE_IS_LOADING',
  updateErrorStatus = 'UPDATE_ERROR_STATUS',
}

export interface NavLinksEvents {
  UPDATE_NAV_LINKS: CategorizedNavigationLinks[];
  UPDATE_IS_LOADING: boolean;
  UPDATE_ERROR_STATUS: boolean;
}

const data: CategorizedNavigationLinks[] = [];
const storeItem = StorageProvider.getItem(StorageLocation.sessionStorage, 'storeon');
const navLinksState = storeItem ? (JSON.parse(storeItem) as NavLinksState) : null;
const isLoading = navLinksState ? !!navLinksState.navLinksStore?.isLoading : false;
const initState: NavLinksState = { navLinksStore: { data, isLoading, errorState: false } };

export const navLinksReducer: StoreonModule<NavLinksState, NavLinksEvents> = (store) => {
  store.on('@init', () => initState);
  store.on(NavLinksReducerActions.updateNavlinks, (state, data) => ({ navLinksStore: { ...state.navLinksStore, data } }));
  store.on(NavLinksReducerActions.updateIsLoading, (state, isLoading) => ({ navLinksStore: { ...state.navLinksStore, isLoading } }));
  store.on(NavLinksReducerActions.updateErrorStatus, (state, errorState) => ({ navLinksStore: { ...state.navLinksStore, errorState } }));
};
