import { Grid, makeStyles } from '@material-ui/core';
import { DsDivider, size } from '@mvp/design-system';
import React, { useEffect, useRef, useState } from 'react';
import { CommunicationPreferencesAddress, UpdateAccountMessageCode } from '../../../models/communicationPreferences.model';
import { PhraseDetails } from '../utils/updateInfoText';
import { ModifyAddressAndPhone } from './address/ModifyAddressAndPhone';
import { TaskStep } from '../../../models';
import ToggleEditButton from './address/ToggleEditButton';
import { useUpdateAddress } from '../../../hooks/commPref/useUpdateAddress';
import { useUpdatePhone } from '../../../hooks/commPref/useUpdatePhone';
import { AddressesDisplay } from './address/AddressesDisplay';
import { PhonesDisplay } from './phone/PhonesDisplay';
import { QueryKey, useMutateData } from '../../../ReactQueryClientProvider';
import { communicationPreferencesProvider } from '../../../api/communicationPreferencesProvider';
import { useOnSaveContext } from '../../../hooks/useOnSaveContext';
import ProfileAndSettingsText from '../ProfileAndSettingsText';

export interface AddressInfo {
  address: CommunicationPreferencesAddress;
  phoneNumber: string;
}
interface CommPrefAddressAndPhoneProps {
  homeAddressInfo: AddressInfo;
  mailingAddressInfo: AddressInfo;
  canUpdate: boolean;
  statusCode: UpdateAccountMessageCode;
  updateMessage: PhraseDetails | undefined;
  onBoardingUpdate?: (step: TaskStep) => void;
  contactInfoRefLocation?: number | undefined;
  disableUpdates?: boolean;
}

const mailingNotSameAsHomeAddressOffset = 300;

const useStyles = makeStyles((theme) => ({
  updateContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '2.5rem',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      width: 'auto',
    },
  },
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  divider: {
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      marginLeft: `-${size.m(theme)}`,
    },
  },
  infoContainer: {
    width: '75%',
  },
}));

export const CommPrefAddressAndPhone: React.FC<CommPrefAddressAndPhoneProps> = (props) => {
  const { homeAddressInfo, mailingAddressInfo, canUpdate, updateMessage, onBoardingUpdate, contactInfoRefLocation, disableUpdates } = props;

  const [updateAddress, setUpdateAddress] = useState<boolean>(false);
  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(true);

  const editPhoneAndAddressRef = useRef<HTMLDivElement>(null);

  const { mutateAsync, isLoading } = useMutateData({
    api: communicationPreferencesProvider.updateAddressAndPhone,
    invalidateKeys: [QueryKey.NewCommunicationPreference],
  });

  const showCantUpdateIcon = !canUpdate && !!updateMessage;
  const classes = useStyles();

  const {
    onFormChange,
    setAddressesAreSame,
    reset,
    addressesAreSame,
    formProps,
    setMailAddressToHome,
    getAddresses,
    disableButton,
    touchedFields,
    homeAddressStateErrors,
  } = useUpdateAddress(homeAddressInfo, mailingAddressInfo);

  const {
    formProps: phoneFormProps,
    homePhoneErrorMessage,
    mailingPhoneErrorMessage,
    reset: resetPhone,
    disableButton: disablePhoneButton,
    getPhoneNumbers,
    checkIfInputsAreEmpty,
  } = useUpdatePhone(homeAddressInfo.phoneNumber, mailingAddressInfo.phoneNumber);

  const { useContext } = useOnSaveContext();

  useEffect(() => {
    setAddressesAreSame(JSON.stringify(homeAddressInfo.address) === JSON.stringify(mailingAddressInfo.address));

    if (!updateAddress) {
      resetForm();
    } else {
      let editPhoneAndAddressScrollLocation = editPhoneAndAddressRef.current?.offsetHeight;
      if (!addressesAreSame && typeof editPhoneAndAddressScrollLocation === 'number') {
        editPhoneAndAddressScrollLocation -= mailingNotSameAsHomeAddressOffset;
      }

      window.scrollTo({ behavior: 'smooth', top: editPhoneAndAddressScrollLocation });
    }
  }, [updateAddress]);

  useEffect(() => {
    if (!disablePhoneButton) {
      if (disableButton && !homeAddressStateErrors && Object.keys(touchedFields).length === 0) {
        const inputsAreEmpty = checkIfInputsAreEmpty();

        setDisableSaveButton(inputsAreEmpty);
      } else {
        setDisableSaveButton(disablePhoneButton || disableButton);
      }
    } else {
      setDisableSaveButton(true);
    }
  }, [disablePhoneButton, disableButton, homeAddressStateErrors]);

  const handleModifyAddress = () => {
    if (onBoardingUpdate) {
      onBoardingUpdate(TaskStep.Welcome);
    } else {
      setUpdateAddress(!updateAddress);
    }
  };

  const resetForm = () => {
    reset();
    resetPhone();
    setDisableSaveButton(true);
  };

  const onSave = async () => {
    const addresses = getAddresses();
    const phoneNumbers = getPhoneNumbers();
    const updateSuccessful = await mutateAsync({
      ...addresses,
      homePhone: phoneNumbers.homePhoneNumber,
      mailingPhone: phoneNumbers.mailingPhoneNumber,
    });

    const errorMessage = !updateSuccessful ? ProfileAndSettingsText.contactInformation.errorMessage : '';
    useContext.updateSnackbar({ showSnackbar: true, errorMessage });

    if (updateSuccessful) {
      resetForm();
      setUpdateAddress(false);
      window.scrollTo({ behavior: 'smooth', top: contactInfoRefLocation });
    }
  };

  return (
    <>
      <Grid container alignItems="center" wrap="nowrap" className={classes.container}>
        <Grid className={classes.infoContainer}>
          <AddressesDisplay homeAddress={homeAddressInfo.address} mailingAddress={mailingAddressInfo.address} />
          <PhonesDisplay homePhone={homeAddressInfo.phoneNumber} mailingPhone={mailingAddressInfo.phoneNumber} />
        </Grid>
        <Grid className={classes.updateContainer}>
          <ToggleEditButton
            booleanState={showCantUpdateIcon}
            primaryComponentProps={{
              updateAddress: !!updateAddress,
              handleModifyAddress,
              disableUpdates: disableUpdates,
            }}
            secondaryComponentProps={{
              updateMessage,
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <DsDivider className={classes.divider} />
      </Grid>
      {updateAddress && (
        <div ref={editPhoneAndAddressRef}>
          <ModifyAddressAndPhone
            addresses={{
              home: homeAddressInfo.address,
              mailing: mailingAddressInfo.address,
            }}
            phoneNumbers={{
              home: homeAddressInfo.phoneNumber,
              mailing: mailingAddressInfo.phoneNumber,
            }}
            formProps={{
              address: formProps,
              phone: phoneFormProps,
            }}
            handleOnSave={onSave}
            disableButton={disableSaveButton || disableUpdates === true}
            setMailAddressToHome={setMailAddressToHome}
            onFormChange={onFormChange}
            addressesAreSame={addressesAreSame}
            setAddressesAreSame={setAddressesAreSame}
            onStateSelectChange={onFormChange}
            phoneErrorMessages={{
              homeErrorMessage: homePhoneErrorMessage,
              mailingErrorMessage: mailingPhoneErrorMessage,
            }}
            isLoading={isLoading}
            disableUpdates={disableUpdates}
          />
        </div>
      )}
    </>
  );
};
