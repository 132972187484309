import { TagManagerArgs } from 'react-gtm-module';
import { FeatureToggleKeys } from './hooks/useFeature';

interface Configuration {
  SELF_SERVICE_URL: string;
  MVP_WWW_ROOT: string;
  PORTAL_ROOT: string;
  SELF_SERVICE_PUBLIC_SRC: string;
  BE_WELL_REWARDS_FLYER_URL: string;
  SSO_WELLNESS_REWARDS: string;
  SSO_WEALTHCARE: string;
  SSO_BOA: string;
  SSO_HEALTH_SPARQ_URL: string;
  SSO_TRUVEN: string;
  SSO_LIBERTY_DENTAL_URL: string;
  HEALTH_SPARQ_URL_COST_URL_ADDON: string;
  SSO_PRESCRIPTIONS_URL: string;
  MESSAGE_BOARD_FEEDBACK: string;
  ESSENTIAL_PLAN_REIMBURSEMENT_FORM: string;
  APP_INSIGHTS_CONNECTION_STRING: string;
  API_URL: string;
  API_APP_ID: string;
  AUTH_REDIRECT_URI: string;
  B2C_TENANT: string;
  B2C_POLICY: string;
  B2C_GHOST_USER_POLICY: string;
  B2C_MAGIC_LINK_POLICY: string;
  B2C_BASE_URL: string;
  FEATURE_TOGGLE: { [key in FeatureToggleKeys]: string };
  TAG_MANAGER_ARGS: TagManagerArgs;
  API_SUBSCRIPTION_KEY: string;
  SESSION_TIMEOUT: number;
  SESSION_TIMEOUT_CHECK: number;
  B2C_ACCOUNT_SIGN_IN: string;
  MEMBER_GUIDE_BANNER_SHOW_DATE: string;
  MEMBER_GUIDE_BANNER_HIDE_DATE: string;
  TAX_FORM_BANNER_SHOW_DATE: string;
  TAX_FORM_BANNER_HIDE_DATE: string;
  SECURE_MESSAGE_CACHE_IN_MINUTES: number;
  GENESYS_DEPLOY_ID: string;
  GENESYS_ENVIRONMENT: string;
  PAYMENT_CENTER_URL: string;
}

const release: Configuration = {
  SELF_SERVICE_URL: '#{SELF_SERVICE_URL}#',
  MVP_WWW_ROOT: '#{MVP_WWW_ROOT}#',
  PORTAL_ROOT: '#{PORTAL_ROOT}#',
  SELF_SERVICE_PUBLIC_SRC: '#{SELF_SERVICE_PUBLIC_SRC}#',
  BE_WELL_REWARDS_FLYER_URL: '#{BE_WELL_REWARDS_FLYER_URL}#',
  SSO_WELLNESS_REWARDS: '#{SSO_WELLNESS_REWARDS}#',
  SSO_WEALTHCARE: '#{SSO_WEALTHCARE}#',
  SSO_BOA: '#{SSO_BOA}#',
  SSO_HEALTH_SPARQ_URL: '#{SSO_HEALTH_SPARQ_URL}#',
  SSO_TRUVEN: '#{SSO_TRUVEN}#',
  SSO_LIBERTY_DENTAL_URL: '#{SSO_LIBERTY_DENTAL_URL}#',
  HEALTH_SPARQ_URL_COST_URL_ADDON: '#{HEALTH_SPARQ_URL_COST_URL_ADDON}#',
  SSO_PRESCRIPTIONS_URL: '#{SSO_PRESCRIPTIONS_URL}#',
  MESSAGE_BOARD_FEEDBACK: '#{MESSAGE_BOARD_FEEDBACK}#',
  ESSENTIAL_PLAN_REIMBURSEMENT_FORM: '#{ESSENTIAL_PLAN_REIMBURSEMENT_FORM}#',
  APP_INSIGHTS_CONNECTION_STRING: '#{APP_INSIGHTS_CONNECTION_STRING}#',
  API_URL: '#{API_URL}#',
  API_APP_ID: '#{API_APP_ID}#',
  AUTH_REDIRECT_URI: '#{AUTH_REDIRECT_URI}#',
  B2C_TENANT: '#{B2C_TENANT}#',
  B2C_POLICY: '#{B2C_POLICY}#',
  B2C_GHOST_USER_POLICY: '#{B2C_GHOST_USER_POLICY}#',
  B2C_MAGIC_LINK_POLICY: '#{B2C_MAGIC_LINK_POLICY}#',
  B2C_BASE_URL: '#{B2C_BASE_URL}#',
  FEATURE_TOGGLE: {
    ONBOARDING: '#{FEATURE_ONBOARDING}#',
    ONBOARDING_V2: '#{FEATURE_ONBOARDING_V2}#',
    WELCOME_PCP_ONBOARDING: '#{FEATURE_WELCOME_PCP_ONBOARDING}#',
    CLAIMS_CSV_EXPORT: '#{FEATURE_CLAIMS_CSV_EXPORT}#',
    ID_CARDS_PAGE: '#{FEATURE_ID_CARDS_PAGE}#',
    BENEFITS_V2: '#{FEATURE_BENEFITS_V2}#',
    PREVENTIVE_CARE_REMINDER: '#{FEATURE_PREVENTIVE_CARE_REMINDER}#',
    DISABLE_PROFILE_SETTINGS: '#{FEATURE_DISABLE_PROFILE_SETTINGS}#',
    HIDE_PREFERRED_CONTACT_METHOD: '#{FEATURE_HIDE_PREFERRED_CONTACT_METHOD}#',
    SHOW_INMOMENT_SURVEY: '#{FEATURE_SHOW_INMOMENT_SURVEY}#',
    WELLBEING_REIMBURSEMENT: '#{FEATURE_WELLBEING_REIMBURSEMENT}#',
    BE_WELL_REWARDS: '#{FEATURE_BE_WELL_REWARDS}#',
    PAYMENTS: '#{FEATURE_PAYMENTS}#',
    SECURE_MESSAGING: '#{FEATURE_SECURE_MESSAGING}#',
    DOCUMENT_CENTER: '#{FEATURE_DOCUMENT_CENTER}#',
    ENABLE_SECURE_MESSAGING_DRAFT: '#{FEATURE_ENABLE_SECURE_MESSAGING_DRAFT}#',
    FEATURE_ENABLE_BE_WELL_REWARDS_BANNER: '#{FEATURE_ENABLE_BE_WELL_REWARDS_BANNER}#',
    CONTACT_US_V2: '#{FEATURE_CONTACT_US_V2}#',
    PAYMENTS_BANNER: '#{FEATURE_PAYMENTS_BANNER}#',
    WELLBEING_REIMBURSEMENT_V2: '#{FEATURE_WELLBEING_REIMBURSEMENT_V2}#',
    WELLBEING_REIMBURSEMENT_CARD_V2: '#{FEATURE_WELLBEING_REIMBURSEMENT_CARD_V2}#',
  },
  TAG_MANAGER_ARGS: {
    gtmId: '#{GTM_ID}#',
    auth: '#{GTM_AUTH}#',
    preview: '#{GTM_PREVIEW}#',
  },
  API_SUBSCRIPTION_KEY: '#{API_SUBSCRIPTION_KEY}#',
  SESSION_TIMEOUT: parseInt('#{SESSION_TIMEOUT}#'),
  SESSION_TIMEOUT_CHECK: parseInt('#{SESSION_TIMEOUT_CHECK}#'),
  B2C_ACCOUNT_SIGN_IN: '#{B2C_ACCOUNT_SIGN_IN}#',
  MEMBER_GUIDE_BANNER_SHOW_DATE: '#{MEMBER_GUIDE_BANNER_SHOW_DATE}#',
  MEMBER_GUIDE_BANNER_HIDE_DATE: '#{MEMBER_GUIDE_BANNER_HIDE_DATE}#',
  TAX_FORM_BANNER_SHOW_DATE: '#{TAX_FORM_BANNER_SHOW_DATE}#',
  TAX_FORM_BANNER_HIDE_DATE: '#{TAX_FORM_BANNER_HIDE_DATE}#',
  SECURE_MESSAGE_CACHE_IN_MINUTES: parseInt('#{SECURE_MESSAGE_CACHE_IN_MINUTES}#'),
  GENESYS_DEPLOY_ID: '#{GENESYS_DEPLOY_ID}#',
  GENESYS_ENVIRONMENT: '#{GENESYS_ENVIRONMENT}#',
  PAYMENT_CENTER_URL: '#{PAYMENT_CENTER_URL}#',
};

const local: Configuration = {
  SELF_SERVICE_URL: 'http://localhost:59830/MemberSelfService',
  MVP_WWW_ROOT: 'https://www.mvphealthcare.com',
  PORTAL_ROOT: `http://localhost:${process.env.REACT_APP_PORT ?? 3000}`,
  SELF_SERVICE_PUBLIC_SRC: 'https://al4extweb01.its.mvphealthcare.com:444/pub',
  BE_WELL_REWARDS_FLYER_URL: 'https://wwwacc.mvphealthcare.com/-/media/project/mvp/healthcare/post-login/be-well-rewards-member-flyer.pdf',
  SSO_WELLNESS_REWARDS:
    'https://acc-myaccount.mvphealthcare.com/mvpb2cacc.onmicrosoft.com/B2C_1A_SignInAsh/generic/login?EntityId=https://preprod.sso.ashcompanies.com',
  SSO_WEALTHCARE:
    'https://acc-myaccount.mvphealthcare.com/mvpb2cacc.onmicrosoft.com/B2C_1A_SignInAlegeus/generic/login?EntityId=http://auth.beta.wealthcareadmin.com/adfs/services/trust&RelayState=RPID=https://mvp-beta.wealthcareportal.com',
  SSO_BOA:
    'https://acc-myaccount.mvphealthcare.com/mvpb2cacc.onmicrosoft.com/B2C_1A_SignInBOA/generic/login?EntityId=https://stagingsis.lh1ondemand.com/acs',
  SSO_HEALTH_SPARQ_URL:
    'https://acc-myaccount.mvphealthcare.com/mvpb2cacc.onmicrosoft.com/B2C_1A_SignInHealthsparq/generic/login?EntityId=https://mvptest.healthsparq.com/auth/realms/MVP-HealthCare',
  SSO_TRUVEN:
    'https://acc-myaccount.mvphealthcare.com/mvpb2cacc.onmicrosoft.com/B2C_1A_SignInTruven/generic/login?EntityId=uat.thconsumeradvantage.com.mvphealthcare.com.acc&RelayState=https://uat.thconsumeradvantage.com/tcc2/librarian/PFActionId/pf.LoginSSO',
  HEALTH_SPARQ_URL_COST_URL_ADDON:
    '&RelayState=https%3A%2f%2fmvptest.healthsparq.com%2fhealthsparq%2f%23%2fone%2fauth%3Fcategory%3DENCOUNTER%26editCategorySearch%3Dtrue',
  SSO_PRESCRIPTIONS_URL:
    'https://acc-myaccount.mvphealthcare.com/mvpb2cacc.onmicrosoft.com/B2C_1A_SignInCVS/generic/login?EntityId=SP_CVS_MVP2',
  SSO_LIBERTY_DENTAL_URL:
    'https://acc-myaccount.mvphealthcare.com/mvpb2cacc.onmicrosoft.com/B2C_1A_SignInLibertyDental/generic/login?EntityId=saml.Client.com:Liberty&RelayState=https://DeviTransact.libertydentalplan.com/pt/iTransactSSO/default.aspx&spid=mvp&debug=y',
  MESSAGE_BOARD_FEEDBACK: 'https://mvphealthcare.allegiancetech.com/cgi-bin/qwebcorporate.dll?idx=SJS6KH',
  ESSENTIAL_PLAN_REIMBURSEMENT_FORM:
    'https://wwwits.mvphealthcare.com/-/media/project/mvp/healthcare/post-login/mvp-well-being-reward-reimbursement-request-for-essential-plan.pdf',
  APP_INSIGHTS_CONNECTION_STRING:
    'InstrumentationKey=af527b9c-4722-4314-8ccb-e009a0086022;IngestionEndpoint=https://eastus-2.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/',
  API_URL: `http://localhost:${process.env.REACT_APP_MEMBER_PORTAL_API_PORT ?? 7071}/api`,
  API_APP_ID: 'a5f23d05-3cb7-4ed5-adb4-76db2ff4a021',
  AUTH_REDIRECT_URI: `http://localhost:${process.env.REACT_APP_PORT ?? 3000}`,
  B2C_TENANT: 'mvpb2cdev',
  B2C_POLICY: 'b2c_1a_signinmemberoidc',
  B2C_GHOST_USER_POLICY: 'B2C_1A_SIGNINGHOSTING',
  B2C_MAGIC_LINK_POLICY: 'B2C_1A_SignInMagicLink',
  B2C_BASE_URL: 'https://its-myaccount.mvphealthcare.com',
  FEATURE_TOGGLE: {
    ONBOARDING: 'true',
    ONBOARDING_V2: 'false',
    WELCOME_PCP_ONBOARDING: 'true',
    CLAIMS_CSV_EXPORT: 'true',
    ID_CARDS_PAGE: 'true',
    BENEFITS_V2: 'true',
    PREVENTIVE_CARE_REMINDER: 'true',
    DISABLE_PROFILE_SETTINGS: 'false',
    HIDE_PREFERRED_CONTACT_METHOD: 'true',
    SHOW_INMOMENT_SURVEY: 'false',
    WELLBEING_REIMBURSEMENT: 'true',
    BE_WELL_REWARDS: 'true',
    PAYMENTS: 'true',
    SECURE_MESSAGING: 'true',
    DOCUMENT_CENTER: 'true',
    ENABLE_SECURE_MESSAGING_DRAFT: 'false',
    FEATURE_ENABLE_BE_WELL_REWARDS_BANNER: 'true',
    CONTACT_US_V2: 'true',
    PAYMENTS_BANNER: 'true',
    WELLBEING_REIMBURSEMENT_V2: 'true',
    WELLBEING_REIMBURSEMENT_CARD_V2: 'true',
  },
  TAG_MANAGER_ARGS: {
    gtmId: 'GTM-KQWP8BJ',
    auth: 'snRia-x9sRxWnr62tomLPw',
    preview: 'env-6',
  },
  API_SUBSCRIPTION_KEY: 'local-unused',
  SESSION_TIMEOUT: 60 * 60 * 24,
  SESSION_TIMEOUT_CHECK: 5,
  B2C_ACCOUNT_SIGN_IN:
    'https://its-myaccount.mvphealthcare.com/mvpb2cdev.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_ProfileEditMember&client_id=c643f748-2f26-466f-9daf-bb57b052c4c6&nonce=defaultNonce&redirect_uri=https://its-my.mvphealthcare.com&scope=openid&response_type=id_token',
  MEMBER_GUIDE_BANNER_SHOW_DATE: '12-16',
  MEMBER_GUIDE_BANNER_HIDE_DATE: '12-16',
  TAX_FORM_BANNER_SHOW_DATE: '01-01',
  TAX_FORM_BANNER_HIDE_DATE: '04-13',
  SECURE_MESSAGE_CACHE_IN_MINUTES: 5,
  GENESYS_DEPLOY_ID: 'a9d42cdb-3317-490e-9ed7-ac859d7678eb',
  GENESYS_ENVIRONMENT: 'prod',
  PAYMENT_CENTER_URL: 'https://its-api.mvphealthcare.com/paymentcenterapi/v1',
};

export default process.env.NODE_ENV === 'production' ? release : local;
