import { Grid, makeStyles } from '@material-ui/core';
import { DsPrimaryButton, DsPrimaryLoadingButton, size } from '@mvp/design-system';
import React from 'react';
import { OnboardingText } from '../OnboardingText';

const useStyles = makeStyles((theme) => ({
  divider: {
    borderTop: '0.063rem solid',
    borderTopColor: theme.palette.grey[100],
    justifySelf: 'flex-end',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  textContainer: {
    flexGrow: 1,
  },
  actionButton: {
    padding: `${size.xxs(theme)} ${size.l(theme)}`,
    margin: `${size.m(theme)} 0 0`,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  cancelButton: {
    marginRight: size.m(theme),
  },
  actionButtonContainer: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
}));

export interface ContentTemplateProps {
  advanceStep?: { function: () => void; text: string; id: string; disabled?: boolean; loading?: boolean };
  goBack?: { function: () => void; text?: string; id: string; disabled?: boolean };
}

const ModifyContentTemplate: React.FC<ContentTemplateProps> = ({ advanceStep, goBack, children }) => {
  const classes = useStyles();

  return (
    <>
      <Grid item lg={12} className={classes.textContainer}>
        {children}
      </Grid>
      <Grid className={classes.divider} wrap="nowrap" justify="flex-end" item container>
        <Grid className={classes.actionButtonContainer}>
          <DsPrimaryButton
            onClick={goBack?.function}
            variant="text"
            text={goBack?.text}
            className={`${classes.actionButton} ${classes.cancelButton}`}
            disabled={goBack?.disabled}
            id={goBack?.id}
          />
          <DsPrimaryLoadingButton
            id={advanceStep?.id}
            actionInProgress={!!advanceStep?.loading}
            actionText={OnboardingText.submitting}
            disabled={advanceStep?.disabled}
            onClick={advanceStep?.function}
            text={advanceStep?.text}
            className={classes.actionButton}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ModifyContentTemplate;
