import { makeStyles } from '@material-ui/core';
import { DsText, size } from '@mvp/design-system';
import React from 'react';
import { OnboardingStepProps } from '../../../models';
import { getHeapIdent, HeapLocation, Target } from '../../../models/heapIdentifiers.model';
import ContentTemplate from './ContentTemplate';

const useStyles = makeStyles((theme) => ({
  bottomMargin: {
    marginBottom: size.xs(theme),
  },
  text: {
    [theme.breakpoints.up('md')]: {
      paddingRight: '10rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: '1.75rem',
    },
  },
  subtitle: {
    fontWeight: 600,
  },
}));

const SelectPcp: React.FC<OnboardingStepProps> = ({ advanceStep, goBack }) => {
  const classes = useStyles();
  return (
    <ContentTemplate
      goBack={{ function: goBack, id: getHeapIdent(HeapLocation.onboarding_modal, Target.gobackbutton) }}
      advanceStep={{
        function: advanceStep,
        text: 'Add my primary care doctor',
        id: getHeapIdent(HeapLocation.onboarding_modal, Target.addprimarydoctorbutton),
      }}
    >
      <DsText className={classes.bottomMargin} variant="h1">
        Find Your Doctor in Our Network
      </DsText>
      <DsText className={`${classes.bottomMargin} ${classes.text}`} variant="subtitle2">
        To get the most out of your plan, we need to add your primary care provider. Please continue by pressing the button below to add
        your doctor now in our Change PCP experience.
      </DsText>
      <DsText variant="subtitle2" className={classes.subtitle}>
        Let’s start by choosing a Primary Care Provider (PCP).
      </DsText>
    </ContentTemplate>
  );
};

export default SelectPcp;
