export enum ClaimsSummaryTableItem {
  ProviderCharges = 'Provider Charges',
  YourDiscount = 'Your MVP Discount',
  MvpPays = 'MVP Payments',
  OtherInsurance = 'Other Insurance*',
  CoPay = 'Co-pay',
  Deductible = 'Deductible',
  CoInsurance = 'Co-insurance',
  NonCoveredExpenses = 'Non-covered Expenses',
}
