import config from './config';

const legacyPortalDomain = config.SELF_SERVICE_URL;

export const sharedUrls = {
  legacyPortalDomain,
  wwwRoot: config.MVP_WWW_ROOT,
  legacyIdCards: `${legacyPortalDomain}/IdCards`,
  legacyGapsInCare: `${legacyPortalDomain}/GapsInCare`,
  legacyCommunicationPreferences: `${legacyPortalDomain}/CommunicationPreferences`,
  legacySessionClear: `${legacyPortalDomain}/Home/ClearUserSessionData`,
  content: `https://content.mvphealthcare.com`,
  providerSearchUrl: config.SSO_HEALTH_SPARQ_URL,
  portalUrl: config.PORTAL_ROOT,
  ssoWellnessRewards: config.SSO_WELLNESS_REWARDS,
  ssoWealthcare: config.SSO_WEALTHCARE,
  ssoBoa: config.SSO_BOA,
  ssoTruven: config.SSO_TRUVEN,
  ssoHealthSparqCost: `${config.SSO_HEALTH_SPARQ_URL}${config.HEALTH_SPARQ_URL_COST_URL_ADDON}`,
  ssoPrescriptions: config.SSO_PRESCRIPTIONS_URL,
  chooseHealthyURL: `https://www.choosehealthy.com/?hp=MVPPC1`,
  deltaDentalURL: `https://www.deltadental.com`,
  healthplexURL: `https://www.healthplex.com`,
  messageBoardLink: `${config.MVP_WWW_ROOT}/welcome/gia-by-mvp?utm_source=member_portal_banner&utm_medium=in_app&utm_campaign=int_gia_launch&utm_content=digital`,
  sharedComponentsLogoutUrl: `${config.PORTAL_ROOT}/shared-logout`,
  messageBoardImageSmall: `${config.MVP_WWW_ROOT}/wp-content/uploads/download-manager-files/portal-img-size-3.png`,
  messageBoardImageMed: `${config.MVP_WWW_ROOT}/wp-content/uploads/download-manager-files/portal-img-size-2.png`,
  messageBoardImageLarge: `${config.MVP_WWW_ROOT}/wp-content/uploads/download-manager-files/portal-img-size-1.png`,
  wellBeingMessageBoardImageLarge: `${config.MVP_WWW_ROOT}/-/media/project/mvp/healthcare/post-login/images/well-being-reimbursement-banner-870x240.png`,
  wellBeingMessageBoardImageMed: `${config.MVP_WWW_ROOT}/-/media/project/mvp/healthcare/post-login/images/well-being-reimbursement-banner-610x240.png`,
  wellBeingMessageBoardImageSmall: `${config.MVP_WWW_ROOT}/-/media/project/mvp/healthcare/post-login/images/well-being-reimbursement-banner-340x160.png`,
  wellBeingFlyer: `${config.MVP_WWW_ROOT}/-/media/project/mvp/healthcare/post-login/well-being-your-way-reimbursement-flyer.pdf`,
  beWellRewardsFlyer: config.BE_WELL_REWARDS_FLYER_URL,
  submitClaimsEmail: 'submitclaims@mvphealthcare.com',
  authorityBaseUrl: config.B2C_BASE_URL,
};
