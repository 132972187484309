export interface Statement {
  subgroupId: string;
  paymentIdentifier: string;
  paymentType: string;
  invoiceNumber: string;
  statementAmount: number;
  statementDueDate: string;
  currentBalanceDue: number | null;
  lastPaymentAmount: number | null;
  lastPaymentDate: string | null;
  groupId: string;
  subscriberId: string;
  autoPay: AutoPay;
  planName: string;
  memberId: string;
  isChpHoh: boolean;
  groupName: string;
  displayText: string;
}

export interface BinderStatement {
  currentBalanceDue: number | null;
  facetsGroupId: number;
  invoiceDate: string;
  invoiceNumber: string;
  paymentDueDate: string;
  paymentIdentifier: string;
  paymentType: 'Binder';
  sourceSystemInvoiceId: string;
  totalInvoiceAmount: number;
  totalPaidAmount: number;
}

export interface BinderInfo {
  statement: BinderStatement | null;
  hasMultipleStatements: boolean;
  paymentIdentifier: string;
}

export interface PaymentHistory {
  paymentDate: string;
  receievedDate: string;
  type: string;
  amount: number;
  balance: number;
  paymentIdentifier: string;
  authNumber: string;
  pdfUrl: string;
}

export type History = PaymentHistory | BillingHistory;

export interface BillingHistory {
  invoiceNumber: string;
  creationDate: string;
  invoiceAmount: number;
  balance: number;
  dueDate: string;
  rebillStatus: RebillStatus;
}

export interface AutoPay {
  status: string;
  nextSetUpAutoPayMonth: number;
  nextSetUpAutoPayDay: number;
  nextSetUpAutoPayYear: number;
}

export enum AutoPayStatus {
  disabled = 'disabled',
  enabled = 'enabled',
  enrolled = 'enrolled',
}

export enum RebillStatus {
  None = 'None',
  RebilledInvoice = 'RebilledInvoice',
  PriorInvoice = 'PriorInvoice',
}

export enum TransactionType {
  Payment = 'Payment',
  InsufficientFunds = 'Insufficient Funds',
  PaymentRefund = 'Payment Refund',
  PaymentRefundReversal = 'Payment Refund (Reversal)',
  TransferIn = 'Transfer In',
  TransferOut = 'Transfer Out',
}
