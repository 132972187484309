import React from 'react';
import { GuardProvider } from 'react-router-guards';
import PageNotFound from '../pages/ErrorPages/PageNotFound';
import { useAuthGuard } from '../hooks/useAuthGuard';
import { LoadingContainer } from './LoadingContainer';
import { ErrorCard } from './ErrorCard';

export const RouteGuardContainer: React.FC = ({ children }) => {
  const { requireAuth, isLoading: authorizedLinksPending, isErrorState } = useAuthGuard();

  if (isErrorState) {
    return <ErrorCard text1="Unable to load Dashboard" />;
  }

  return authorizedLinksPending ? (
    <LoadingContainer isLoading={authorizedLinksPending} />
  ) : (
    <GuardProvider guards={[requireAuth]} error={PageNotFound}>
      {children}
    </GuardProvider>
  );
};
