import { lazy } from 'react';
import { RouteProps } from 'react-router-dom';
import { GuardedRouteProps } from 'react-router-guards';
import ProfileAndSettingsText from './pages/CommunicationPreferences/ProfileAndSettingsText';
import { FrontChannelLogout } from './pages/FrontChannelLogout/FrontChannelLogout';
import { Redirect } from './pages/Redirect/Redirect';
import { SignedInRedirect } from './pages/SignedInRedirect';

const Dashboard = lazy(() => import('./pages//Dashboard/Dashboard'));
const DeductiblesAndLimits = lazy(() => import('./pages/DeductiblesAndLimits/DeductiblesAndLimits'));
const SpendingAccounts = lazy(() => import('./pages/SpendingAccounts/SpendingAccounts'));
const TreatmentCostProvider = lazy(() => import('./pages/TreatmentCostProvider/TreatmentCostProvider'));
const ClaimsStatusHistory = lazy(() => import('./pages/ClaimsStatusHistory'));
const PageNotFound = lazy(() => import('./pages/ErrorPages/PageNotFound'));
const Unauthorized = lazy(() => import('./pages/ErrorPages/Unauthorized'));
const BenefitsToggle = lazy(() => import('./pages/Benefits/Benefits'));
const NurseAdviceLine = lazy(() => import('./pages/NurseAdviceLine/NurseAdviceLine'));
const NurseAdviceLineSuccess = lazy(() => import('./pages/NurseAdviceLine/NurseAdviceLineSuccess'));
const PriorAuthorizations = lazy(() => import('./pages/PriorAuthorizations/PriorAuthorizations'));
const SelectDoctor = lazy(() => import('./pages/SelectDoctor/SelectDoctor'));
const SubmitClaim = lazy(() => import('./pages/SubmitClaim/SubmitClaim'));
const Payments = lazy(() => import('./pages/Payments'));
const ProfileAndSettings = lazy(() => import('./pages/CommunicationPreferences/ProfileAndSettings'));
const IDCardsPageToggle = lazy(() => import('./pages/IDCards/IDCards'));
const ContactUs = lazy(() => import('./pages/ContactUs/ContactUs'));
const PreventiveCareReminderToggle = lazy(() => import('./pages/PreventiveCareReminder/PreventiveCareReminders'));
const TaxDocuments = lazy(() => import('./pages/TaxDocuments/TaxDocuments'));
const WellbeingReimburesement = lazy(() => import('./pages/WellbeingReimbursements'));
const BeWellRewards = lazy(() => import('./pages/BeWellRewards/BeWellRewards'));
const SharedLogout = lazy(() => import('./pages/SharedComponentsLogout/SharedComponentsLogout'));
const SecureMessaging = lazy(() => import('./pages/SecureMessaging'));
const MessagesByThreadToggle = lazy(() => import('./pages/SecureMessaging/MessageThread'));
const DocumentCenter = lazy(() => import('./pages/DocumentCenter/DocumentCenter'));
const SecureMessagingNewMessage = lazy(() => import('./pages/SecureMessaging/NewMessage'));

export interface RouteConfig {
  title: string;
  routeProps: GuardedRouteProps | RouteProps;
  authGuard: boolean;
  PageToRender: React.FC;
  hideFeedbackWidget?: boolean;
}

export const MessageThreadBase = '/messages/thread/';

export const routes = {
  dashboard: '/',
  logout: '/logout',
  sharedLogout: '/shared-logout',
  ghost: '/ghost',
  redirect: '/redirect',
  signedIn: '/signed-in',
  myProfile: '/my-profile',
  benefits: '/my-benefits',
  deductiblesAndLimits: '/deductibles-and-limits',
  communicationPreferences: '/profile-and-settings',
  spendingAccounts: '/spending-accounts',
  submitClaim: '/submit-a-claim',
  selectPrimaryCareProvider: '/select-primary-care-provider',
  nurseAdviceLine: '/nurse-advice-line',
  nurseAdviceLineSuccess: '/nurse-advice-line/success',
  priorAuthorizations: '/prior-authorizations',
  estimateTreatmentCosts: '/estimate-treatment-costs',
  claimsStatusHistory: '/claims-status-and-history',
  idCards: '/id-cards',
  contactUsAuth: '/contact-us',
  payments: '/payments',
  preventiveCareReminder: '/preventive-care-reminders',
  taxDocuments: '/tax-documents',
  wellbeingReimbursement: '/well-being-reimbursement',
  beWellRewards: '/be-well-rewards',
  secureMessaging: {
    base: '/messages',
    newMessage: '/new-message',
    messageThread: `${MessageThreadBase}:threadId`,
  },
  documentCenter: '/document-center',
  unauthorized: '/unauthorized',
  signinBlocked: '/signin-blocked',
  error: '*',
};

export const routesConfig: RouteConfig[] = [
  {
    routeProps: {
      exact: true,
      path: routes.dashboard,
    },
    title: 'Dashboard',
    authGuard: false,
    PageToRender: Dashboard,
  },
  {
    routeProps: {
      exact: true,
      path: routes.payments,
    },
    title: 'Payment',
    authGuard: false,
    PageToRender: Payments,
  },
  {
    routeProps: {
      exact: true,
      path: routes.logout,
    },
    title: 'Logout',
    authGuard: false,
    PageToRender: FrontChannelLogout,
    hideFeedbackWidget: true,
  },
  {
    routeProps: {
      exact: true,
      path: routes.sharedLogout,
    },
    title: 'Logging Out',
    authGuard: false,
    PageToRender: SharedLogout,
    hideFeedbackWidget: true,
  },
  {
    routeProps: {
      exact: true,
      path: routes.ghost,
    },
    title: 'Dashboard',
    authGuard: false,
    PageToRender: Dashboard,
  },
  {
    routeProps: {
      exact: true,
      path: routes.redirect,
    },
    title: 'Loading',
    authGuard: false,
    PageToRender: Redirect,
    hideFeedbackWidget: true,
  },
  {
    routeProps: {
      exact: true,
      path: routes.signedIn,
    },
    title: 'Signed In',
    authGuard: false,
    PageToRender: SignedInRedirect,
    hideFeedbackWidget: true,
  },
  {
    routeProps: {
      exact: true,
      path: routes.benefits,
    },
    title: 'My Benefits',
    authGuard: false,
    PageToRender: BenefitsToggle,
  },
  {
    routeProps: {
      exact: true,
      path: routes.deductiblesAndLimits,
    },
    title: 'Deductibles & Limits',
    authGuard: true,
    PageToRender: DeductiblesAndLimits,
  },
  {
    routeProps: {
      exact: true,
      path: routes.idCards,
    },
    title: 'ID Cards',
    authGuard: false,
    PageToRender: IDCardsPageToggle,
  },
  {
    routeProps: {
      exact: true,
      path: routes.communicationPreferences,
    },
    title: ProfileAndSettingsText.pageTitle,
    authGuard: true,
    PageToRender: ProfileAndSettings,
  },
  {
    routeProps: {
      exact: true,
      path: routes.spendingAccounts,
    },
    title: 'Spending Accounts',
    authGuard: true,
    PageToRender: SpendingAccounts,
  },
  {
    routeProps: {
      exact: true,
      path: routes.submitClaim,
    },
    title: 'Submit a Claim',
    authGuard: true,
    PageToRender: SubmitClaim,
  },
  {
    routeProps: {
      exact: true,
      path: routes.selectPrimaryCareProvider,
    },
    title: 'Select Primary Care Provider',
    authGuard: true,
    PageToRender: SelectDoctor,
  },
  {
    routeProps: {
      exact: true,
      path: routes.nurseAdviceLine,
    },
    title: '24/7 Nurse Advice Line',
    authGuard: true,
    PageToRender: NurseAdviceLine,
  },
  {
    routeProps: {
      exact: true,
      path: routes.nurseAdviceLineSuccess,
    },
    title: 'Success! 24/7 Nurse Advice Line',
    authGuard: true,
    PageToRender: NurseAdviceLineSuccess,
  },
  {
    routeProps: {
      exact: true,
      path: routes.priorAuthorizations,
    },
    title: 'Prior Authorizations',
    authGuard: true,
    PageToRender: PriorAuthorizations,
  },
  {
    routeProps: {
      exact: true,
      path: routes.estimateTreatmentCosts,
    },
    title: 'Estimate Treatment Costs',
    authGuard: true,
    PageToRender: TreatmentCostProvider,
  },
  {
    routeProps: {
      exact: true,
      path: routes.claimsStatusHistory,
    },
    title: 'Claims Status and History',
    authGuard: true,
    PageToRender: ClaimsStatusHistory,
  },
  {
    routeProps: {
      exact: true,
      path: routes.contactUsAuth,
    },
    title: 'ContactUs',
    authGuard: false,
    PageToRender: ContactUs,
  },
  {
    routeProps: {
      exact: true,
      path: routes.preventiveCareReminder,
    },
    title: 'Preventive Care Reminders',
    authGuard: false,
    PageToRender: PreventiveCareReminderToggle,
  },
  {
    routeProps: {
      exact: true,
      path: routes.taxDocuments,
    },
    title: 'Tax Documents',
    authGuard: true,
    PageToRender: TaxDocuments,
  },
  {
    routeProps: {
      exact: true,
      path: routes.wellbeingReimbursement,
    },
    title: 'Well-Being Reimbursement',
    authGuard: true,
    PageToRender: WellbeingReimburesement,
  },
  {
    routeProps: {
      exact: true,
      path: routes.beWellRewards,
    },
    title: 'Be Well Rewards',
    authGuard: true,
    PageToRender: BeWellRewards,
  },
  {
    routeProps: {
      exact: true,
      path: routes.secureMessaging.base,
    },
    title: 'Messages',
    authGuard: false,
    PageToRender: SecureMessaging,
  },
  {
    routeProps: {
      exact: true,
      path: routes.secureMessaging.messageThread,
    },
    title: 'Message Thread',
    authGuard: false,
    PageToRender: MessagesByThreadToggle,
  },
  {
    routeProps: {
      exact: true,
      path: routes.documentCenter,
    },
    title: 'Document Center',
    authGuard: false,
    PageToRender: DocumentCenter,
  },
  {
    routeProps: {
      exact: true,
      path: routes.secureMessaging.newMessage,
    },
    title: 'New Message',
    authGuard: false,
    PageToRender: SecureMessagingNewMessage,
  },
  {
    routeProps: {
      exact: true,
      path: routes.unauthorized,
    },
    title: 'Unauthorized',
    authGuard: false,
    PageToRender: Unauthorized,
  },
  {
    routeProps: {
      exact: true,
      path: routes.error,
    },
    title: '404',
    authGuard: false,
    PageToRender: PageNotFound,
  },
];
