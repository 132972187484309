import { RecertCategoryEnum, RecertCategoryGroupEnum, RecertDisplayText } from '../../models/InitInfoResult.model';

export const GetRecertMessage = (category: RecertCategoryEnum, displayDate: string): RecertDisplayText => {
  var titleText = '';
  var prependBodyText = '';
  var midBodyText = '';
  var appendBodyText = '';
  var healthNyGovLink = '';
  var healthNyGovLinkAppendText = '';
  var phoneNumber = '1-844-687-1235';

  switch (category) {
    case RecertCategoryEnum.MedicaidHarpEp60:
      titleText = 'It’s time to renew your health insurance!';
      prependBodyText = `Your coverage is scheduled to end ${displayDate}. An MVP Representative is available to help you renew. Call `;
      midBodyText = ' to renew or visit our ';
      appendBodyText = ' to see what you may need to complete your renewal.';
      break;

    case RecertCategoryEnum.CHPHOH60:
      titleText = 'It’s time to renew your child’s health insurance!';
      prependBodyText = `Your child’s coverage is scheduled to end ${displayDate}. An MVP Representative is available to help you renew your child’s eligibility or to explore plan options. Call `;
      midBodyText = ' to renew or visit our ';
      appendBodyText = ' to see what you may need to complete your child’s renewal.';
      break;

    case RecertCategoryEnum.CHPDependent60:
      titleText = 'It’s time to renew your health insurance!';
      prependBodyText = `Let your parent or guardian know that your health insurance is scheduled to end ${displayDate}. Visit our `;
      phoneNumber = '';
      appendBodyText = ' to learn more.';
      break;

    case RecertCategoryEnum.LDSS60:
      titleText = 'It’s time to renew your health insurance!';
      prependBodyText = `Your coverage is scheduled to end ${displayDate}. Visit your Local Department of Social Services (LDSS) to renew your coverage. Find your nearest LDSS at `;
      healthNyGovLink = 'health.ny.gov';
      healthNyGovLinkAppendText = ' or visit our ';
      appendBodyText = ' to learn more.';
      phoneNumber = '';
      break;

    case RecertCategoryEnum.MedicaidHarpEp90:
      titleText = 'Your health insurance renewal deadline was missed.';
      prependBodyText = `It’s not too late to stay covered! An MVP Representative is available to help. Call `;
      midBodyText = ' to renew or visit our ';
      appendBodyText = ' to see what you may need to complete your renewal.';
      break;

    case RecertCategoryEnum.CHPHOH90:
      titleText = 'Your child’s health insurance renewal deadline was missed.';
      prependBodyText = `It’s not too late to keep your child covered! An MVP Representative is available now to help. Call `;
      midBodyText = ' to renew or visit our ';
      appendBodyText = ' to see what you may need to keep your child’s coverage.';
      break;

    case RecertCategoryEnum.CHPDependent90:
      titleText = 'Your health insurance renewal deadline was missed.';
      prependBodyText = `It’s not too late to stay covered! Have your parent or guardian call `;
      midBodyText = ' to renew or visit our ';
      appendBodyText = ' to learn more.';
      break;

    case RecertCategoryEnum.LDSS90:
      titleText = 'Your health insurance renewal deadline was missed.';
      prependBodyText = `It’s not too late to stay covered. Visit your Local Department of Social Services (LDSS) to keep your coverage. Find your nearest LDSS at `;
      healthNyGovLink = 'health.ny.gov';
      healthNyGovLinkAppendText = '. Visit our ';
      appendBodyText = ' to learn more.';
      phoneNumber = '';
      break;
  }

  return {
    Title: titleText,
    PrependBody: prependBodyText,
    MidBody: midBodyText,
    AppendBody: appendBodyText,
    PhoneNumber: phoneNumber,
    healthNyGovLink: healthNyGovLink,
    healthNyGovLinkAppendText: healthNyGovLinkAppendText,
  };
};

export const GetRecertMessageForBanner = (category: RecertCategoryEnum, displayDate: string): RecertDisplayText => {
  var titleText = '';
  var prependBodyText = '';
  var midBodyText = '';
  var appendBodyText = '';
  var healthNyGovLink = '';
  var healthNyGovLinkAppendText = '';
  var phoneNumber = '1-844-687-1235';

  switch (category) {
    case RecertCategoryEnum.MedicaidHarpEp60:
      prependBodyText = `Your health insurance coverage is scheduled to end ${displayDate}. Call `;
      midBodyText = ' to renew or visit our ';
      appendBodyText = ' to see what you may need to complete your renewal.';
      break;

    case RecertCategoryEnum.CHPHOH60:
      prependBodyText = `Your child’s health insurance coverage is scheduled to end ${displayDate}. Call `;
      midBodyText = ' to renew or visit our ';
      appendBodyText = ' to see what you may need to complete your child’s renewal.';
      break;

    case RecertCategoryEnum.CHPDependent60:
      prependBodyText = `It’s time to renew your health insurance! Let your parent or guardian know that your health insurance is scheduled to end ${displayDate}. Visit our `;
      phoneNumber = '';
      appendBodyText = ' to learn more.';
      break;

    case RecertCategoryEnum.LDSS60:
      prependBodyText = `It’s time to renew your health insurance! Your coverage is scheduled to end ${displayDate}. Visit your Local Department of Social Services (LDSS) to renew your coverage. Find your nearest LDSS at `;
      healthNyGovLink = 'health.ny.gov';
      healthNyGovLinkAppendText = '. Visit our ';
      appendBodyText = ' to learn more.';
      phoneNumber = '';
      break;

    case RecertCategoryEnum.MedicaidHarpEp90:
      titleText = 'Your health insurance renewal deadline was missed.';
      prependBodyText = ` It’s not too late to stay covered! Call `;
      midBodyText = ' to renew or visit our ';
      appendBodyText = ' to see what you may need to complete your renewal.';
      break;

    case RecertCategoryEnum.CHPHOH90:
      titleText = 'Your child’s health insurance renewal deadline was missed.';
      prependBodyText = ` It’s not too late to keep your child covered! Call `;
      midBodyText = ' to renew or visit our ';
      appendBodyText = ' to see what you may need to keep your child’s coverage.';
      break;

    case RecertCategoryEnum.CHPDependent90:
      titleText = 'Your health insurance renewal deadline was missed.';
      prependBodyText = ` It’s not too late to stay covered! Have your parent or guardian call `;
      midBodyText = ' to renew. Visit our ';
      appendBodyText = ' to learn more.';
      break;

    case RecertCategoryEnum.LDSS90:
      titleText = 'Your health insurance renewal deadline was missed.';
      prependBodyText = ` It’s not too late to stay covered! Visit your Local Department of Social Services (LDSS) to keep your coverage. Find your nearest LDSS at `;
      healthNyGovLink = 'health.ny.gov';
      healthNyGovLinkAppendText = '. Visit our ';
      appendBodyText = ' to learn more.';
      phoneNumber = '';
      break;
  }

  return {
    Title: titleText,
    PrependBody: prependBodyText,
    MidBody: midBodyText,
    AppendBody: appendBodyText,
    PhoneNumber: phoneNumber,
    healthNyGovLink: healthNyGovLink,
    healthNyGovLinkAppendText: healthNyGovLinkAppendText,
  };
};

export const GetRecertCategoryGroup = (category: RecertCategoryEnum): RecertCategoryGroupEnum => {
  return category === RecertCategoryEnum.MedicaidHarpEp60 ||
    category === RecertCategoryEnum.CHPHOH60 ||
    category === RecertCategoryEnum.CHPDependent60 ||
    category === RecertCategoryEnum.LDSS60
    ? RecertCategoryGroupEnum.Group60
    : RecertCategoryGroupEnum.Group90;
};
