import moment from 'moment-timezone';

moment.tz.setDefault('America/New_York');

export const isValidDate = (date: Date): boolean => date instanceof Date && !isNaN(date.getTime());

export const formatDate = (inDate: Date | string, fullYear: boolean = true): string => {
  if (inDate === undefined) return '';
  return moment(inDate).format(`MM/DD/${fullYear ? 'YYYY' : 'YY'}`);
};

export const formatTime = (date: moment.MomentInput) => {
  if (date === undefined) return '';
  return moment(date).format('h:mm A');
};

export const isCorrectDateFormat = (date: string) => {
  // returns true for mm/dd/yyyy format, fix for incorrect dates display
  const regexExp = /(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)[0-9]{2}/g;
  return regexExp.test(date);
};

export interface EuropeanDateFormat {
  day: string | number;
  month: string | number;
  year: string | number;
}

export const createNewDate = (date?: EuropeanDateFormat): Date => {
  if (!!date) {
    const twoDigitDay = parseInt(`${date.day}`) < 10 ? `0${date.day}` : date.day;
    const twoDigitMonth = parseInt(`${date.month}`) < 10 ? `0${date.month}` : date.month;
    //date needs to be in yyyy-mm-dd format for Firefox and Safari
    const newDate = new Date(`${date.year}-${twoDigitMonth}-${twoDigitDay}T00:00:00`);

    return newDate;
  }
  const newDate = new Date();
  newDate.setHours(0, 0, 0, 0);
  return newDate;
};

export const createCurrentDateWithAmountYearsPast = (yearsBack: number): Date => {
  const date = createNewDate();
  date.setFullYear(date.getFullYear() - yearsBack);
  return date;
};

export const twoYearsAgoDate = createCurrentDateWithAmountYearsPast(2);
export const threeYearsAgoDate = createCurrentDateWithAmountYearsPast(3);

const today = new Date();
export const minus90Days = new Date(new Date().setDate(today.getDate() - 90));
export const minus30Days = new Date(new Date().setDate(today.getDate() - 30));
export const prevYear = new Date(new Date().setFullYear(today.getFullYear() - 1));
export const minus3Years = new Date(new Date().setFullYear(today.getFullYear() - 3));
export const currentYearStart = new Date(today.getFullYear(), 0, 1);
export const currentYearEnd = new Date(today.getFullYear(), 11, 31);
export const previousYearStart = new Date(prevYear.getFullYear(), 0, 1);
export const previousYearEnd = new Date(prevYear.getFullYear(), 11, 31);

export const getYearAgo = (date: Date): Date => new Date(new Date(date.getTime()).setFullYear(date.getFullYear() - 1));

export const getMonthString = (date: string) => {
  const d = new Date(date);
  return d.toLocaleString('default', { month: 'long' });
};

export const isDateToday = (date: string) => {
  var messageDate = new Date(date);
  var today = new Date();
  return messageDate.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0);
};
