import * as SignalR from '@microsoft/signalr';
import { HttpClient, HttpRequest, HttpResponse, IHttpConnectionOptions } from '@microsoft/signalr';
import config from './config';
import { QueryKey, queryClient } from './ReactQueryClientProvider';
import { paymentsProvider } from './api/paymentsProvider';

export const Configure = (clientId: string) => {
  let options: IHttpConnectionOptions = {
    httpClient: {
      post: async (url: string, request: HttpRequest) => {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            ...request.headers,
            clientId,
          },
        });

        let data = await response.json();
        return new HttpResponse(response.status, response.statusText, JSON.stringify(data));
      },
      getCookieString: (url: string) => {}, // do not delete needed (not sure why)
    } as HttpClient,
    accessTokenFactory: async () => {
      const getTokensResponse: string | undefined = await queryClient.getQueryData([QueryKey.PaymentCenterToken]);

      if (getTokensResponse) {
        return getTokensResponse;
      }
      const response: string = await queryClient.fetchQuery([QueryKey.PaymentCenterToken], paymentsProvider.getPaymentCenterToken);
      return response ?? '';
    },
  };

  return new SignalR.HubConnectionBuilder()
    .withUrl(config.PAYMENT_CENTER_URL, options)
    .withAutomaticReconnect()
    .configureLogging(process.env.NODE_ENV === 'production' ? SignalR.LogLevel.Error : SignalR.LogLevel.Information)
    .build();
};
