import axiosSingleton from '../components/axios';
import config from '../config';
import { CategorizedNavigationLinks } from '../models';
import { PortalLink } from '../models/portalLink.model';

export const navigationLinksProvider = {
  async getAllNavigationLinks(): Promise<CategorizedNavigationLinks[]> {
    const response = await axiosSingleton.get<CategorizedNavigationLinks[]>(`${config.API_URL}/v1/portal-navigation-links`, {
      headers: {
        accept: 'application/json',
      },
    });
    return response.data;
  },
  async getDashboardQuickLinks(): Promise<PortalLink[]> {
    const response = await axiosSingleton.get<PortalLink[]>(`${config.API_URL}/v1/dashboard-quick-links`, {
      headers: {
        accept: 'application/json',
      },
    });
    return response.data;
  },
};
