import React from 'react';
import { useHistory } from 'react-router-dom';
import { OnboardingStepProps, TaskStep } from '../../../models';
import UpdateEmail from './commPref/UpdateEmail';
import SelectPcp from './SelectPcp';
import UpdateCommPref from './UpdateCommPref';
import Welcome from './Welcome';
import WelcomeAndPcp from './WelcomeAndPcp';

interface StepSelectorProps extends OnboardingStepProps {
  step: TaskStep;
  closeModal: () => void;
  onlyShowWelcomePcp: boolean;
  goToStep: (step: TaskStep) => void;
}

const StepSelector: React.FC<StepSelectorProps> = (props) => {
  const { step, onlyShowWelcomePcp, goBack, advanceStep, closeModal, goToStep } = props;
  const history = useHistory();

  const goToPage = (route: string) => {
    closeModal();
    history.push(route);
  };

  if (onlyShowWelcomePcp) {
    return (
      <WelcomeAndPcp
        advanceStep={() => {
          goToPage('/select-primary-care-provider');
        }}
      />
    );
  }

  return {
    [TaskStep.Welcome]: <Welcome advanceStep={advanceStep} />,
    [TaskStep.Update_Comm_Pref]: <UpdateCommPref advanceStep={advanceStep} goBack={goBack} goToStep={goToStep} />,
    [TaskStep.Update_Comm_Pref_Email]: <UpdateEmail advanceStep={advanceStep} goBack={goBack} goToStep={goToStep} />,
    [TaskStep.Select_PCP]: (
      <SelectPcp
        goBack={goBack}
        advanceStep={() => {
          goToPage('/select-primary-care-provider');
        }}
      />
    ),
  }[step];
};

export default StepSelector;
