import React from 'react';
import { createStoreon, StoreonStore } from 'storeon';
import { persistState } from '@storeon/localstorage';
import { navLinksReducer, NavLinksEvents, NavLinksState } from './reducers';
import { StoreContext } from 'storeon/react';

const safeCreateStoreon = (): StoreonStore<NavLinksState, NavLinksEvents> | undefined => {
  try {
    return createStoreon([navLinksReducer, persistState(['navLinksStore'], { storage: sessionStorage })]);
  } catch {
    //no need to process, Index.tsx will redirect to signin-disabled where there's no use for storeon
  }
};

export const globalStore = safeCreateStoreon();

export const GlobalStoreProvider: React.FC<{ store?: StoreonStore }> = ({ children, store }) => {
  return <StoreContext.Provider value={store ? store : globalStore}>{children}</StoreContext.Provider>;
};
